import { VNodeProps } from '../vnode';
export * from './animation';
export * from './classes';
export * from './controls';
export * from './size';
export * from './styles';
export * from './layout';

export const isSheetActive = (props: VNodeProps): boolean => {
  if (!props.sheet) return false;

  if ('value' in props.sheet && !props.sheet.value) return false;

  return true;
};
