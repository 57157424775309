import { DataId, ModuleListItem } from '@fillip/api';
import { useData } from './useData';

export function useDataTreeView() {
  const { getData } = useData();

  const dataToTreeView = (id: DataId): any => {
    const data = getData(id);
    if (!data) {
      console.warn(`dataToTreeView: Document ${id} not found`);
      return null;
    }
    return {
      ...data,
      name: data.info?.title,
      children: (
        data.list?.items
          ?.filter((item: ModuleListItem) => {
            return item.id != id;
          })
          .map((item: ModuleListItem) => {
            return item.id;
          }) || []
      ).map((c) => dataToTreeView(c)),
    };
  };

  const templatesInTreeview = (id: DataId) => {
    const data = dataToTreeView(id);
    if (!data) return null;

    const templateFilter = (docArray): any => {
      return docArray.filter((element) => {
        if (element.tag?.tag == 'template') return true;
        if (!element.children || element.children.length <= 0) return false;
        return templateFilter(element.children).length > 0;
      });
    };

    return {
      ...data,
      name: data.info?.title,
      children: templateFilter(data.children).map((c) =>
        templatesInTreeview(c.id),
      ),
    };
  };

  return {
    dataToTreeView,
    templatesInTreeview,
  };
}
