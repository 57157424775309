import { deepEqual, shallowEqual } from 'fast-equals';
import { TemplateId, DataId, ObjectId, rootTemplateId } from '../common';
import clone from 'rfdc/default';
import { TSceneDefinition } from '..';
import { hasOwnProperty } from '..';

export interface Route {
  path: RouteSegment[];
}

export interface ExplicitSceneDefinition {
  explicit: true;
  templateId: TemplateId;
  dataId?: DataId;
}

export interface NamedSceneDefinition {
  explicit: false;
  name: string;
}

export type SceneDefinition = ExplicitSceneDefinition | NamedSceneDefinition;

export interface SceneParams {
  focus?: ObjectId;
  [key: string]: any;
}

export interface RouteSegment {
  scene: SceneDefinition;
  params: SceneParams;
}

export enum NavigationReason {
  BROWSER = 'browser',
  FILLIP = 'fillip',
}

export const RootRoute: Route = {
  path: [{ scene: { explicit: true, templateId: rootTemplateId }, params: {} }],
};

export const getActiveSegmentFromRoute = (route: Route): RouteSegment => {
  return route.path[route.path.length - 1];
};

export const withFocus = (
  routeOrSegment: Route | RouteSegment,
  objectToFocus: ObjectId,
) => {
  if (hasOwnProperty(routeOrSegment, 'path')) {
    // routeOrSegment is Route
    const route = routeOrSegment;
    const currentPathSegment = route.path[route.path.length - 1];
    const newPathSegment = {
      ...currentPathSegment,
      params: {
        ...currentPathSegment.params,
        focus: objectToFocus,
      },
    };
    return {
      ...route,
      path: [...route.path.slice(0, route.path.length - 1), newPathSegment],
    };
  } else {
    const segment = routeOrSegment;
    return {
      ...segment,
      params: {
        ...segment.params,
        focus: objectToFocus,
      },
    };
  }
};

export const withoutFocus = (route: Route) => {
  const currentPathSegment = route.path[route.path.length - 1];
  const newPathSegment = {
    ...currentPathSegment,
    params: {
      ...currentPathSegment.params,
    },
  };
  delete newPathSegment.params.focus;
  return {
    ...route,
    path: [...route.path.slice(0, route.path.length - 1), newPathSegment],
  };
};

export const resolveSceneDefinition = (
  scene: SceneDefinition,
  registeredScenes: TSceneDefinition[] = [],
): { templateId: string; dataId?: string } => {
  let templateId, dataId;
  if (scene.explicit) {
    ({ templateId, dataId } = scene);
  } else {
    if (!registeredScenes) {
      console.warn('No registered scenes provided');
      registeredScenes = [];
    }
    const resolvedSceneDefinition: TSceneDefinition = registeredScenes.filter(
      (sceneDefinition) => {
        return sceneDefinition.slug == (scene as NamedSceneDefinition).name;
      },
    )[0];
    if (!resolvedSceneDefinition) {
      templateId = '';
      dataId = '';
    } else {
      ({ template: templateId, data: dataId } = resolvedSceneDefinition);
    }
  }
  return { templateId, dataId };
};

export const getActiveSceneObjectId = (route: Route): ObjectId => {
  const segment = route.path[route.path.length - 1];
  if (segment.scene.explicit) {
    const { templateId, dataId } = segment.scene;
    return `${templateId}${dataId ? ':' + dataId : ''}`;
  } else {
    const name = segment.scene;
    return `${name}`;
  }
};

export const getFocus = (route: Route): ObjectId => {
  if (!route.path) return null;
  return route.path[route.path.length - 1]?.params?.focus;
};

export const getScene = (route: Route): SceneDefinition => {
  if (!route.path) return null;
  return route.path[route.path.length - 1]?.scene;
};

export const getScenePath = (route: Route): SceneDefinition[] => {
  if (!route.path) return null;
  return route.path.map((r: RouteSegment) => r.scene);
};

export const addPathSegment = (route: Route, segment: RouteSegment): Route => {
  if (!route || !route.path) return route;
  const target = clone(route);
  target.path.push(segment);
  return target;
};

export const removeLastPathSegments = (route: Route, steps = 1): Route => {
  if (!route || !route.path || route.path.length < 2) return RootRoute;
  const target = clone(route);
  target.path.splice(-steps);
  return target;
};

// Route Filters

export type RouteArgument = SceneDefinition | Route;

// Tests if the passed in routes are identical, meaning they have the same RouteSegments AND the params.
// Example use case: All 'spectators' focusing the same object from the same path
export const isSameView = (route1: Route, route2: Route) =>
  deepEqual(route1, route2);

// Tests if the passed in routes have the same path, meaning they have the same RouteSegments, but cand have different params.
// Example use case: All participants looking at the same user story in a sprint board,
// excluding users who look at the same story on the "all stories" board
export const isSamePath = (route1: Route, route2: Route) => {
  return deepEqual(getScenePath(route1), getScenePath(route2));
};

// Tests if the two passed in routes AND/OR scenes look at the same document in the last RouteSegment
// Example use case: "Also reading: ..."
export const isSameDocument = (
  scene1: SceneDefinition,
  scene2: SceneDefinition,
) => {
  if (!scene1.explicit || !scene2.explicit || !scene1.dataId || !scene2.dataId)
    return false;
  return scene1.dataId == scene2.dataId;
};

// Example use case: All participants in a same room that might be referenced from different paths,
// e.g. a stage that's referenced from the room and the directors view
export const isSameScene = (scene1: SceneDefinition, scene2: SceneDefinition) =>
  shallowEqual(scene1, scene2);

// TODO: resolve named scene definitions first
export const isSameBaseRoute = (baseRoute: Route, route: Route) => {
  return deepEqual(
    getScenePath(baseRoute),
    getScenePath(route).slice(0, baseRoute.path.length),
  );
};

export const isSceneInPath = (scene: SceneDefinition, route: Route) =>
  getScenePath(route).some((s) => shallowEqual(s, scene));

export const createRoute = (templateId: string, dataId?: string) => ({
  path: [
    { scene: { explicit: true, templateId: '0' }, params: {} },
    {
      scene: {
        explicit: true,
        templateId,
        dataId,
      },
      params: {},
    },
  ],
});

export const createRouteSegment = (
  templateId: string,
  dataId?: string,
): RouteSegment => ({
  scene: {
    explicit: true,
    templateId,
    dataId,
  },
  params: {},
});

export const createNamedRouteSegment = (name: string): RouteSegment => ({
  scene: {
    explicit: false,
    name,
  },
  params: {},
});

export const stringifyRouteSegment = (segment: RouteSegment): string => {
  let result = '';
  if (segment.scene.explicit) {
    const { templateId, dataId } = segment.scene;
    result = templateId + ':' + (dataId ? dataId : '');
  } else {
    result = (segment.scene as NamedSceneDefinition).name;
  }
  if (segment.params && Object.keys(segment.params).length > 0) {
    result += '__' + encodeURIComponent(JSON.stringify(segment.params));
  }
  return result;
};

export const stringifyRoute = (route: Route): string => {
  if (!route.path || route.path.length < 2) return '';
  let result = '';
  route.path.slice(1).forEach((segment: RouteSegment) => {
    result += '/' + stringifyRouteSegment(segment);
  });
  return result;
};

export const parseRouteSegment = (segmentString: string): RouteSegment => {
  const [scene, params] = segmentString.split('__');
  let parsedSegments = scene.split(':');
  let segment;
  if (parsedSegments.length > 1 && parsedSegments[0] == 'function') {
    // TODO: This is ugly
    const newParsedSegments = [parsedSegments[0] + ':' + parsedSegments[1]];
    if (parsedSegments[2]) {
      newParsedSegments.push(parsedSegments[2]);
    }
    parsedSegments = newParsedSegments;
  }
  if (parsedSegments.length > 1) {
    // ExplicitSceneDefinition
    segment = createRouteSegment(parsedSegments[0], parsedSegments[1]);
  } else {
    // NamedSceneDefinition
    segment = createNamedRouteSegment(parsedSegments[0]);
  }
  if (params) {
    const decodedParams = JSON.parse(decodeURIComponent(params));
    segment = {
      ...segment,
      params: decodedParams,
    };
  }
  return segment;
};

export const parseRoute = (routeString: string): Route => {
  let route = clone(RootRoute);
  if (routeString) {
    const segmentStrings = routeString.split('/');
    segmentStrings.forEach((segmentString: string) => {
      route = addPathSegment(route, parseRouteSegment(segmentString));
    });
  }
  return route;
};

export function extractFromParamsAndMeta(route) {
  return {
    ...route.params,
    ...route.meta,
    items: route.children,
    route: route,
  };
}

export function createExplicitSegmentFromObject(object): RouteSegment {
  return {
    scene: {
      explicit: true,
      templateId: object.templateId || rootTemplateId,
      dataId: object.dataId,
    } as ExplicitSceneDefinition,
    params: {} as SceneParams,
  };
}

export const routeUtils = {
  withFocus,
  withoutFocus,
  getFocus,
  getScene,
  getScenePath,
  getActiveSegment: getActiveSegmentFromRoute,
  addSegment: addPathSegment,
  removeLastSegments: removeLastPathSegments,
  createRoute,
  createRouteSegment,
  createNamedRouteSegment,
  RootRoute,
};
