import { SizeConstraints, DefaultSizeConstraints } from './size.types';

export const applySizeConstraints = (
  el: HTMLElement,
  constraints: SizeConstraints,
) => {
  if (constraints.minWidth == 0) {
    el.style.minWidth = '';
  } else {
    el.style.minWidth = constraints.minWidth + 'px';
  }
  if (constraints.minHeight == 0) {
    el.style.minHeight = '';
  } else {
    el.style.minHeight = constraints.minHeight + 'px';
  }

  if (Number.isFinite(constraints.maxWidth)) {
    el.style.maxWidth = constraints.maxWidth + 'px';
  } else {
    el.style.maxWidth = '';
  }
  if (Number.isFinite(constraints.maxHeight)) {
    el.style.maxHeight = constraints.maxHeight + 'px';
  } else {
    el.style.maxHeight = '';
  }

  if (el.style.maxHeight && el.style.maxHeight == el.style.minHeight) {
    el.style.height = el.style.minHeight;
  } else {
    el.style.height = '';
  }

  if (el.style.maxWidth && el.style.maxWidth == el.style.minWidth) {
    el.style.width = el.style.minWidth;
  } else {
    el.style.width = '';
  }
};

function parseSizeValue(
  input: string,
  defaultValue,
  viewport: { width: number; height: number },
) {
  const { unit = 'px', number = defaultValue } = extractSizeAndUnit(input);
  if (unit == 'vw') {
    return viewport.width * number * 0.01;
  }
  if (unit == 'vh') {
    return viewport.height * number * 0.01;
  }
  return number;
}

function extractSizeAndUnit(input: unknown): {
  unit?: string;
  number?: number;
} {
  if (typeof input === 'number') return { unit: 'px', number: input };
  if (typeof input === 'string') {
    const inputString = input.trim();
    if (!inputString.length) return {};

    const parsed = inputString.match(/^\d+(\.\d+)?/);
    if (!parsed?.length) return {};
    const numberString = parsed[0];
    if (!numberString) return null;

    const number = Number(numberString);
    if (Number.isNaN(number)) return {};

    const unit = inputString.slice(numberString.length)?.trim();
    return { number, unit };
  }

  return {};
}

export function parseSizeConstraints(
  { minWidth, minHeight, minDepth, maxWidth, maxHeight, maxDepth },
  viewport,
) {
  return {
    minWidth: parseSizeValue(minWidth, 0, viewport),
    minHeight: parseSizeValue(minHeight, 0, viewport),
    minDepth: parseSizeValue(minDepth, 0, viewport),
    maxWidth: parseSizeValue(maxWidth, Infinity, viewport),
    maxHeight: parseSizeValue(maxHeight, Infinity, viewport),
    maxDepth: parseSizeValue(maxDepth, Infinity, viewport),
  };
}

export function consolidateSizeConstraints(
  a?: SizeConstraints,
  b?: SizeConstraints,
) {
  // TODO maybe use b as a fallback and let a overflow b
  if (!b) {
    if (!a) return DefaultSizeConstraints;
    return a;
  }
  return {
    minWidth: Math.max(a.minWidth, b.minWidth),
    minHeight: Math.max(a.minHeight, b.minHeight),
    minDepth: Math.max(a.minDepth, b.minDepth),
    maxWidth: Math.min(a.maxWidth, b.maxWidth),
    maxHeight: Math.min(a.maxHeight, b.maxHeight),
    maxDepth: Math.min(a.maxDepth, b.maxDepth),
  };
}

export const applyConstraints = (size, constraints) => {
  if (!constraints) return size;

  const apply = (value, min, max) => {
    if (min >= value && min != Infinity) return min;
    if (max < value) return max;
    return value;
  };
  return {
    width: apply(size.width, constraints.minWidth, constraints.maxWidth),
    height: apply(size.height, constraints.minHeight, constraints.maxHeight),
    depth: apply(size.depth, constraints.minDepth, constraints.maxDepth),
  };
};

export const hasFixedConstraints = (constraints) => {
  if (!constraints) return false;

  const { minWidth, maxWidth, minHeight, maxHeight, minDepth, maxDepth } =
    constraints;
  return minWidth == maxWidth && minHeight == maxHeight && minDepth == maxDepth;
};

export const ExactSizeConstraints = ({ width, height, depth }) => ({
  minWidth: width,
  minHeight: height,
  minDepth: depth,
  maxWidth: width,
  maxHeight: height,
  maxDepth: depth,
});
