import { Location3DType } from '@fillip/api';
import { Object3D, Matrix4, Vector3, Quaternion } from 'three';
import gsap from 'gsap';
import {
  eulerRotationFromQuaternion,
  quaternionRotationFromEuler,
} from '@/utils/location3D';

const withinEpsilon = (a: number, b: number, epsilon: number = 0.0001) =>
  Math.abs(a - b) < epsilon;

// Intermediary to allow gsap to tween an Object3D (gsap expects a flat object)
export class Object3DAnimator {
  px: number;
  py: number;
  pz: number;
  qx: number;
  qy: number;
  qz: number;
  qw: number;
  sx: number;
  sy: number;
  sz: number;

  constructor(public object3D: Object3D) {
    this.px = this.object3D.position.x;
    this.py = this.object3D.position.y;
    this.pz = this.object3D.position.z;
    this.qx = this.object3D.quaternion.x;
    this.qy = this.object3D.quaternion.y;
    this.qz = this.object3D.quaternion.z;
    this.qw = this.object3D.quaternion.w;
    this.sx = this.object3D.scale.x;
    this.sy = this.object3D.scale.y;
    this.sz = this.object3D.scale.z;
  }

  update() {
    this.object3D.position.set(this.px, this.py, this.pz);
    this.object3D.quaternion.set(this.qx, this.qy, this.qz, this.qw);
    this.object3D.scale.set(this.sx, this.sy, this.sz);
  }

  sync() {
    gsap.set(this, {
      px: this.object3D.position.x,
      py: this.object3D.position.y,
      pz: this.object3D.position.z,
      qx: this.object3D.quaternion.x,
      qy: this.object3D.quaternion.y,
      qz: this.object3D.quaternion.z,
      qw: this.object3D.quaternion.w,
      sx: this.object3D.scale.x,
      sy: this.object3D.scale.y,
      sz: this.object3D.scale.z,
      overwrite: true,
    });
  }

  to(target3D: Object3D) {
    return {
      px: target3D.position.x,
      py: target3D.position.y,
      pz: target3D.position.z,
      qx: target3D.quaternion.x,
      qy: target3D.quaternion.y,
      qz: target3D.quaternion.z,
      qw: target3D.quaternion.w,
      sx: target3D.scale.x,
      sy: target3D.scale.y,
      sz: target3D.scale.z,
      overwrite: true,
      duration: 2,
      onUpdate: () => this.update(),
    };
  }

  get location() {
    return {
      position: {
        x: this.px,
        y: this.py,
        z: this.pz,
      },
      scale: {
        x: this.sx,
        y: this.sy,
        z: this.sz,
      },
      rotation: eulerRotationFromQuaternion({
        x: this.qx,
        y: this.qy,
        z: this.qz,
        w: this.qw,
      }),
    };
  }

  get animationTarget() {
    return {
      px: this.px,
      py: this.py,
      pz: this.pz,
      qx: this.qx,
      qy: this.qy,
      qz: this.qz,
      qw: this.qw,
      sx: this.sx,
      sy: this.sy,
      sz: this.sz,
    };
  }

  static withinEpsilon(
    { px, py, pz, qx, qy, qz, qw, sx, sy, sz },
    { position, scale, quaternion },
  ) {
    return (
      withinEpsilon(px, position.x) &&
      withinEpsilon(py, position.y) &&
      withinEpsilon(pz, position.z) &&
      withinEpsilon(qx, quaternion.x) &&
      withinEpsilon(qy, quaternion.y) &&
      withinEpsilon(qz, quaternion.z) &&
      withinEpsilon(qw, quaternion.w) &&
      withinEpsilon(sx, scale.x) &&
      withinEpsilon(sy, scale.y) &&
      withinEpsilon(sz, scale.z)
    );
  }

  getTargetFromMatrix4(matrix: Matrix4) {
    const position = new Vector3();
    const quaternion = new Quaternion();
    const scale = new Vector3();
    matrix.decompose(position, quaternion, scale);
    return { position, scale, quaternion };
  }

  isTweeningTo(target) {
    if (!gsap.isTweening(this)) return false;

    const tween = gsap.getTweensOf(this)[0];
    return Object3DAnimator.withinEpsilon(tween.vars as any, target);
  }

  isTweening() {
    return gsap.isTweening(this);
  }

  isAtTarget(target) {
    return !this.isTweening() && Object3DAnimator.withinEpsilon(this, target);
  }

  static fromObject3D(target3D) {
    return {
      px: target3D.position.x,
      py: target3D.position.y,
      pz: target3D.position.z,
      qx: target3D.quaternion.x,
      qy: target3D.quaternion.y,
      qz: target3D.quaternion.z,
      qw: target3D.quaternion.w,
      sx: target3D.scale.x,
      sy: target3D.scale.y,
      sz: target3D.scale.z,
    };
  }

  static fromMatrix4(matrix: Matrix4) {
    const position = new Vector3();
    const quaternion = new Quaternion();
    const scale = new Vector3();
    matrix.decompose(position, quaternion, scale);
    return {
      px: position.x,
      py: position.y,
      pz: position.z,
      qx: quaternion.x,
      qy: quaternion.y,
      qz: quaternion.z,
      qw: quaternion.w,
      sx: scale.x,
      sy: scale.y,
      sz: scale.z,
    };
  }

  static fromLocation(location: Location3DType) {
    const { position, rotation, scale } = location;
    const quaternion = quaternionRotationFromEuler(rotation);
    return {
      px: position.x,
      py: position.y,
      pz: position.z,
      qx: quaternion.x,
      qy: quaternion.y,
      qz: quaternion.z,
      qw: quaternion.w,
      sx: scale.x,
      sy: scale.y,
      sz: scale.z,
    };
  }

  static fromAny(source: any) {
    return {
      px: source.px,
      py: source.py,
      pz: source.pz,
      qx: source.qx,
      qy: source.qy,
      qz: source.qz,
      qw: source.qw,
      sx: source.sx,
      sy: source.sy,
      sz: source.sz,
    };
  }
}
