





















import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'InputId',
  components: {
    PrimitiveDataSelector,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array,
      default: () => [],
    },
  },
});
