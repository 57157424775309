export * from './ElementButton';
export * from './ElementEmbed';
export * from './ElementHtml';
export * from './ElementIcon';
export * from './ElementImage';
export * from './ElementMarkdown';
export * from './ElementParticipant';
export * from './ElementRectangle';
export * from './ElementScreenshare';
export * from './ElementText';
export * from './ElementChart';
export * from './ElementTable';
export * from './ElementReveal';
export * from './ElementAudio';
export * from './ElementStyledText';
export * from './ElementSvg';
export * from './ElementInlineEditing';
export * from './ElementLine';

import { ElementButton } from './ElementButton';
import { ElementEmbed } from './ElementEmbed';
import { ElementHtml } from './ElementHtml';
import { ElementIcon } from './ElementIcon';
import { ElementImage } from './ElementImage';
import { ElementMarkdown } from './ElementMarkdown';
import { ElementParticipant } from './ElementParticipant';
import { ElementRectangle } from './ElementRectangle';
import { ElementScreenshare } from './ElementScreenshare';
import { ElementText } from './ElementText';
import { ElementChart } from './ElementChart';
import { ElementTable } from './ElementTable';
import { ElementReveal } from './ElementReveal';
import { ElementAudio } from './ElementAudio';
import { ElementStyledText } from './ElementStyledText';
import { ElementSvg } from './ElementSvg';
import { ElementInlineEditing } from './ElementInlineEditing';
import { ElementLine } from './ElementLine';

export const ElementComponents = {
  'element.text': ElementText,
  'element.participants': ElementParticipant,
  'element.embed': ElementEmbed,
  'element.html': ElementHtml,
  'element.markdown': ElementMarkdown,
  'element.image': ElementImage,
  'element.icon': ElementIcon,
  'element.screenshare': ElementScreenshare,
  'element.button': ElementButton,
  'element.rectangle': ElementRectangle,
  'element.participant': ElementParticipant,
  'element.chart': ElementChart,
  'element.table': ElementTable,
  'element.reveal': ElementReveal,
  'element.audio': ElementAudio,
  'element.styledText': ElementStyledText,
  'element.svg': ElementSvg,
  'element.inlineEditing': ElementInlineEditing,
  'element.line': ElementLine,
};
