import {
  FunctionTemplateRenderMode,
  RenderResult,
} from './../function-templates/types';
import { ComponentInstance } from '@/features/main/core';
import {
  ModuleCamera,
  evaluateObject,
  frontendPrefixMarker,
  DataDocument,
  Route,
  rootTemplateId,
  Modules,
  DocumentRole,
  hasRole,
  EvaluatedModuleVideoConferencing,
  resolveSceneDefinition,
  evaluate,
  objectPush,
  getScene,
  getFocus,
  useLogger,
  LogLevels,
  useTrackPerformance,
  Id,
  ChildTemplate,
  PropMappings,
  ConsolidatedPropMappings,
} from '@fillip/api';
import { f, VNode } from '../engine';
import { Size2D } from '../engine/systems';
import { template as LodashTemplate } from 'lodash/fp';
import { mergeWith, uniq } from 'lodash';
import { functionTemplates } from '@/features/main/function-templates';

const logger = useLogger(LogLevels.DEBUG, LogLevels.NONE, 'render');
const trackPerformance = useTrackPerformance(false, 'render');

export interface RenderOutput {
  globalProps: Record<string, any>;
  focusedVNode: VNode | null;
}

export interface RenderEnvironment {
  computedTemplates: Record<string, string>;
  pathSegment: number;
  isWithinFocus: boolean;
  hasFocusWithin: boolean;
  isActiveScene: boolean;
  isFocused: boolean;
  $station: string;
  $route: Route;
  $id: string;
  templateId: string;
  $me: DataDocument;
  $viewport: Size2D;
  $roles?: Record<string, DocumentRole>;
  $index?: number;
  $props?: Record<string, any>;
  $computeds?: Record<string, any>;
  $vars?: Record<string, any>;
}

export interface RenderContext {
  vueInstance: ComponentInstance;
  route: Route;
  out: RenderOutput;
  env: RenderEnvironment;
}

const DefaultTemplate: DataDocument = {
  id: 'DefaultTemplate',
  info: {
    title: 'Default Template',
    icon: '',
  },
  tag: {
    tag: 'template',
  },
};

const computeTemplates = (
  parentTemplates: Record<string, string>,
  template: Modules,
) => {
  if (!template.templates) {
    return parentTemplates;
  } else {
    return {
      ...parentTemplates,
      ...template.templates.mapping,
    };
  }
};

const registerActions = (context: RenderContext, template: Modules) => {
  if (context.env.$vars.$$isCamera) {
    return;
  }
  if (!template.actions?.actions || template.actions.actions.length < 1) return;

  template.actions.actions.forEach((action) => {
    if (
      action.focused == 'station' &&
      !context.env.isWithinFocus &&
      !context.env.isFocused
    ) {
      return;
    }
    if (action.focused == 'focused' && !context.env.isFocused) {
      return;
    }
    if (action.roles && action.roles.length > 0) {
      let shouldDisplay = false;
      for (const role of action.roles) {
        if (hasRole(context.env.$roles)(role)(context.env.$me)) {
          shouldDisplay = true;
        }
      }
      if (!shouldDisplay) return;
    }
    if (action.condition === false) {
      return;
    }
    objectPush(context.out.globalProps, 'actions.canvas', {
      ...action,
      context: { ...action.context, ...context.env },
    });
  });
};

const registerNavigationLinks = (context: RenderContext, template: Modules) => {
  if (!template.navigation?.links || template.navigation.links.length < 1)
    return;

  template.navigation.links.forEach((link) => {
    objectPush(context.out.globalProps, 'actions.sidebar', {
      type: 'button',
      script: `router.goto(route)`,
      name: link.title,
      context: { route: link.route, ...context.env },
      displaySlots: link.displaySlots,
      sortingIndex: link.sortingIndex,
      icon: link.icon,
    });
  });
};

const roleMerger = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return uniq(objValue.concat(srcValue));
  }
};

const registerRoles = (
  context: RenderContext,
  template: DataDocument,
  data?: Modules,
) => {
  if (!template.roles && !data?.roles) return;
  if (template.roles?.roles) {
    mergeWith(context.env.$roles, template.roles?.roles, roleMerger);
  }
  if (data?.roles?.roles) {
    mergeWith(context.env.$roles, data?.roles?.roles, roleMerger);
  }
  objectPush(context.out.globalProps, 'roles', context.env.$roles);
};

export const evaluateTemplate = (
  context: RenderContext,
  template: DataDocument,
  data?: DataDocument,
  propMappings?: ConsolidatedPropMappings,
) => {
  return evaluateObject(
    template,
    data || null,
    frontendPrefixMarker,
    { ...context.env },
    context.vueInstance,
    context.vueInstance.environment,
    { ...context.env, data, template, self: { data, template } },
    propMappings,
  );
};

export const generateId = (templateId: string, dataId?: string): string => {
  return dataId ? templateId + ':' + dataId : templateId;
};

export const getTitle = (template: Modules, context: RenderContext) => {
  if (template.data?.info?.title) return template.data.info.title;
  if (template.info?.title) return template.info?.title;
  return context.vueInstance.$t('general.untitled');
};

export const registerAsStation = (
  context: RenderContext,
  id: string,
  template: Modules,
  templateId: string,
  dataId: string,
) => {
  objectPush(context.out.globalProps, 'stations', {
    id,
    title: getTitle(template, context),
    templateId: templateId,
    dataId: dataId,
  });
};

export const registerSceneCamera = (
  context: RenderContext,
  id: Id,
  template: Modules,
): void => {
  const camera = template.camera;
  if (!camera) return;
  objectPush(context.out.globalProps, 'sceneCamera', { id, ...camera });
};

const registerVideoConferencing = (
  context: RenderContext,
  template: Modules,
) => {
  const vcSettings =
    template.videoConferencing as EvaluatedModuleVideoConferencing;
  if (!vcSettings) return;

  Object.assign(context.out.globalProps.videoConferencing, vcSettings);
};

const registerAsBreadcrumb = (
  context: RenderContext,
  template: Modules,
  templateId: string,
  dataId: string,
) => {
  const id = generateId(templateId, dataId);
  objectPush(context.out.globalProps, 'breadcrumbs', {
    id,
    title: getTitle(template, context),
    templateId: templateId,
    dataId: dataId,
  });
};

const renderBuiltinTemplate = (
  context: RenderContext,
  templateId: string,
  mode: FunctionTemplateRenderMode,
  data?: DataDocument,
): RenderResult => {
  const functionName = templateId.split(':')[1];

  const functionTemplate = functionTemplates[functionName];
  if (functionTemplate) {
    return functionTemplate.template(context, mode, data);
  } else {
    return {
      variables: context.env.$vars,
      props: context.env.$props,
      computeds: context.env.$computeds,
      template: f({}, []),
    };
  }
};

const isBuiltinFunctionTemplateId = (id: string) => {
  return id.startsWith('function:');
};

const generateIdentity = (
  parentId: string,
  index: number,
  templateId: string,
  data?: DataDocument,
  identity?: string,
  env: RenderEnvironment = null,
) => {
  const defaultId = generateId(templateId, data?.id);
  if (identity) {
    try {
      return LodashTemplate(identity)({
        templateId,
        data,
        index,
        parentId,
        env: env || {},
        defaultId,
      });
    } catch (error) {
      logger.warn('Error in identity function: ', error.message);
    }
  }
  return defaultId;
};

const registerSceneDefinitions = (context: RenderContext) => {
  const sceneDefinitions =
    context.vueInstance.getData(rootTemplateId).sceneDefinitions
      ?.sceneDefinitions;

  if (sceneDefinitions) {
    for (const sceneDefinition of sceneDefinitions) {
      if (sceneDefinition.isDynamic) {
        const sceneContext = {
          environment: context.vueInstance.environment,
          data: {},
          variables: {},
          vm: context.vueInstance,
          local: {},
        };
        const queryResult = evaluate(
          sceneContext,
          ':' + sceneDefinition.data,
          ':',
        );
        for (const dataDoc of queryResult) {
          const childContext = {
            ...sceneContext,
            data: dataDoc,
          };
          const evaluatedScene = {
            ...sceneDefinition,
            data: dataDoc.id,
            title: dataDoc.info.title,
            slug: evaluate(childContext, ':' + sceneDefinition.slug, ':'),
          };
          objectPush(
            context.out.globalProps,
            'sceneDefinitions',
            evaluatedScene,
          );
        }
      } else {
        objectPush(
          context.out.globalProps,
          'sceneDefinitions',
          sceneDefinition,
        );
      }
    }
  }
};

const renderChildren = (
  childTemplate: ChildTemplate,
  parentId: string,
  childEnv: RenderEnvironment,
  context: RenderContext,
): VNode[] => {
  let {
    query,
    templateId: overrideTemplateId, // eslint-disable-line prefer-const
    condition, // eslint-disable-line prefer-const
    identity, // eslint-disable-line prefer-const
    propMappings, // eslint-disable-line prefer-const
  } = childTemplate;

  // At this point, the condition has already been evaluated
  if (condition === false) {
    return [];
  }

  if (query === '') {
    if (!overrideTemplateId) {
      return [];
    } else {
      query = [{ id: overrideTemplateId }];
    }
  }

  if (!Array.isArray(query)) {
    return [];
  }

  const loadChild = (data, index): VNode => {
    const tag = data?.tag?.tag || 'page';
    const templateId = overrideTemplateId || childEnv.computedTemplates[tag];

    const childContext = {
      ...context,
      env: { ...childEnv },
    };
    childContext.env.$index = index;
    const childId = generateIdentity(
      parentId,
      index,
      templateId,
      data,
      identity,
      childContext.env,
    );

    return renderTemplate(
      childId,
      childContext,
      templateId,
      data,
      propMappings,
    );
  };

  return query.map(loadChild);
};

export const renderTemplate = (
  id: string,
  context: RenderContext,
  templateId: string = '',
  data?: DataDocument,
  propMappings: PropMappings = {},
) => {
  if (isBuiltinFunctionTemplateId(templateId)) {
    trackPerformance.start('functionTemplate');

    const { variables, props, computeds, template } = renderBuiltinTemplate(
      context,
      templateId,
      'foreground',
      data || null,
    );
    context.env.$vars = { ...variables };
    context.env.$computeds = { ...computeds };
    context.env.$props = { ...props };
    trackPerformance.stop('functionTemplate');

    return template;
  }
  trackPerformance.start('renderTemplate1');
  const template = context.vueInstance.getData(templateId) || DefaultTemplate;

  context.env.$id = id;
  context.env.templateId = templateId;

  const focusedId = getFocus(context.route);

  const { templateId: activeTemplateId } = resolveSceneDefinition(
    getScene(context.route),
    context.out.globalProps.sceneDefinitions,
  );
  const isActiveScene = template.id == activeTemplateId;
  let isWithinFocus = context.env.isWithinFocus;

  const isFocused = focusedId ? id == focusedId : isActiveScene;
  const hasFocusWithin = isActiveScene && !isFocused;

  context.env.isFocused = isFocused;

  if (isActiveScene || template.station) {
    context.env.$station = id;
    context.env.isActiveScene = isActiveScene;
    context.env.hasFocusWithin = hasFocusWithin;
    isWithinFocus = false;
  }
  if (isFocused) {
    registerRoles(context, template, data);
  }
  trackPerformance.stop('renderTemplate1');
  trackPerformance.start(`renderTemplate2::${context.env.$id}`);

  const consolidatedPropMappings = [
    ...(template.interfaceDefinition?.props || []),
    ...(template.interfaceDefinition?.inheritFrom
      ? context.vueInstance.getData(template.interfaceDefinition.inheritFrom)
          ?.interfaceDefinition?.props || []
      : []),
  ].map((propDefinition) => {
    const { key } = propDefinition;
    return {
      key,
      expression:
        propMappings[propDefinition.key] || propDefinition.defaultValue,
    };
  });

  const { evaluatedTemplate, variables, props, computeds } = evaluateTemplate(
    context,
    template,
    data,
    consolidatedPropMappings,
  );
  trackPerformance.stop(`renderTemplate2::${context.env.$id}`);
  trackPerformance.start('renderTemplate3');

  context.env.$computeds = { ...computeds };
  context.env.$props = { ...props };
  context.env.$vars = { ...variables };

  if (isActiveScene || evaluatedTemplate.station) {
    // ? Has this to be set again to capture cases where only the evaluated template has the station module active?
    context.env.$station = id;
    context.env.isActiveScene = isActiveScene;
    context.env.hasFocusWithin = hasFocusWithin;

    registerAsStation(context, id, evaluatedTemplate, template.id, data?.id);
  }

  registerVideoConferencing(context, evaluatedTemplate);
  if (isActiveScene || isFocused || evaluatedTemplate.camera?.controlIfLoaded) {
    registerSceneCamera(context, id, evaluatedTemplate);
  }

  registerActions(context, evaluatedTemplate);
  registerNavigationLinks(context, evaluatedTemplate);

  const childEnv: RenderEnvironment = {
    ...context.env,
    computedTemplates: computeTemplates(
      context.env.computedTemplates,
      evaluatedTemplate,
    ),
    isWithinFocus: isFocused || isWithinFocus,
    isActiveScene: false,
    hasFocusWithin: false,
  };
  trackPerformance.stop('renderTemplate3');

  trackPerformance.start('handleChildren');
  const children = (evaluatedTemplate.children?.default || []).flatMap(
    (childTemplate) => renderChildren(childTemplate, id, childEnv, context),
  );
  trackPerformance.stop('handleChildren');

  const vnode = f({ ...evaluatedTemplate, id, context: context.env }, children);

  if (isFocused) {
    context.out.focusedVNode = vnode;
  }

  return vnode;
};

export const renderScene = (
  context: RenderContext,
): { vnode: VNode; globalProps: Record<string, Array<any>> } => {
  trackPerformance.start('renderScene');

  registerSceneDefinitions(context);

  const vnode = renderRoute(context, rootTemplateId, null);
  // Add Camera
  const focusedVNode = context.out.focusedVNode || vnode;
  focusedVNode.push(
    f({
      id: 'camera',
      placement: {
        type: 'placement.absolute',
        absoluteLocation: {
          position: { x: 0, y: 0, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
          scale: { x: 1, y: 1, z: 1 },
        },
      },
      camera: focusedVNode.props.camera,
    }),
  );
  trackPerformance.stop('renderScene');
  trackPerformance.log();
  trackPerformance.reset();

  return { vnode, globalProps: context.out.globalProps };
};

export const renderRoute = (
  context: RenderContext,
  templateId: string,
  data?: DataDocument,
): VNode => {
  if (context.route.path.length - 1 == context.env.pathSegment) {
    const id = generateId(templateId, data?.id);
    return renderTemplate(id, context, templateId, data);
  }
  trackPerformance.start('renderRouteSegment');

  let evaluatedTemplate,
    variables = null,
    props = null,
    computeds = null;

  if (isBuiltinFunctionTemplateId(templateId)) {
    ({
      template: evaluatedTemplate,
      variables,
      props,
      computeds,
    } = renderBuiltinTemplate(context, templateId, 'background', data));
  } else {
    const template = context.vueInstance.getData(templateId) || DefaultTemplate;

    ({ evaluatedTemplate, variables, props, computeds } = evaluateTemplate(
      context,
      template,
      data,
    ));
  }

  context.env.$computeds = { ...computeds };
  context.env.$props = { ...props };
  context.env.$vars = { ...variables };

  registerRoles(context, evaluatedTemplate, data);

  registerVideoConferencing(context, evaluatedTemplate);

  registerAsBreadcrumb(context, evaluatedTemplate, templateId, data?.id);

  context.env.computedTemplates = computeTemplates(
    context.env.computedTemplates,
    evaluatedTemplate,
  );

  registerActions(context, evaluatedTemplate);
  registerNavigationLinks(context, evaluatedTemplate);

  // Routing
  const segment = context.route.path[context.env.pathSegment + 1];

  const { templateId: sceneTemplateId, dataId: sceneDataId } =
    resolveSceneDefinition(
      segment.scene,
      context.out.globalProps.sceneDefinitions,
    );

  context.env.pathSegment = context.env.pathSegment + 1;
  trackPerformance.stop('renderRouteSegment');

  return renderRoute(
    context,
    sceneTemplateId,
    context.vueInstance.getData(sceneDataId),
  );
};
