import Vue from 'vue';
import { Size2D } from './../systems/size';
import { VNode, f } from '../vnode';
import { Scene, PerspectiveCamera } from 'three';
import { EventEmitter } from 'eventemitter3';
import { Entity } from './entity';
import { CameraEntity } from './camera';
import { GlobalPropsPlugin } from '@/plugins/global-props';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import {
  Id,
  sleep,
  useTrackPerformance,
  useLogger,
  LogLevels,
} from '@fillip/api';

// TODO:
// Make CSS3D Object invisible until it is attached
// Turn Off matrix Auto Update and instead call updateMatrix manually
// Check if target position has changed before starting transition
// Make camera pan and zoomable

export interface VueComponentFactory {
  create: (
    key: string,
    tag: any,
    props: Record<string, any>,
    on: any,
  ) => Promise<Vue>;
  remove: (key: string) => void;
}

export interface EngineInitParams {
  scene: Scene;
  render: () => void;
  // TODO: Move renderManager to typescript, fix type
  renderManager: any;
  viewport: Size2D;
  factory: VueComponentFactory;
  cameraElement: HTMLElement;
  globalPropsPlugin: GlobalPropsPlugin;
  canvasElement: HTMLElement;
}

export interface EngineState {
  vnode: VNode;
  globalProps: Record<string, Array<any>>;
}

export enum PatchStep {
  WAITING_FOR_NEXT = 0,
  NEXT_VNODE_COMITTED = 1,
  NEW_ENTITIES_ARE_LOADING = 2,
  NEW_ENTITIES_LOADED = 3,
  PATCH_COMPLETED = 0,
}

const getScene = (vnode: VNode) => {
  if (vnode.children.length != 1) return null;
  return vnode.children[0];
};

interface PatchDescription {
  vnode: VNode;
  globalProps: Record<string, Array<any>>;
  hasSceneSwitch: boolean;
  lastScene: VNode;
  nextScene: VNode;
}

const logger = useLogger(LogLevels.WARN, LogLevels.NONE, 'engine');
const trackPerformance = useTrackPerformance(false, 'engine');

export class Engine extends EventEmitter {
  public numPendingLoadingTasksForNext = 0;
  public state: PatchStep = PatchStep.WAITING_FOR_NEXT;

  public render: () => void;
  public renderManager: any;
  public factory: VueComponentFactory;
  public scene: Scene;
  public viewport: Size2D;
  public cameraElement: HTMLElement; // For CSS3D Renderer
  public canvasElement: HTMLElement; // For controls

  public root: Entity;
  public camera: CameraEntity;
  public entities: Map<Id, Entity>;
  public obsoleteEntities: Map<Id, Entity> = new Map<Id, Entity>();
  public leavingEntities: Map<Id, Entity> = new Map<Id, Entity>(); // Entities besides root that are therefore about to leave
  public detachedEntities: Set<Entity> = new Set<Entity>(); // Entities whose carrier is not connected to parent content

  public next: EngineState = null;
  public currentPatch: PatchDescription = null;
  // public onPatchCompleted: Array<() => void> = [];

  public resizeObserver: ResizeObserver;
  public needsRelayout: boolean = false;
  public appIsVisible: boolean = true;

  public globalPropsPlugin: GlobalPropsPlugin;
  public globalProps = Vue.observable({} as Record<string, any>);
  public isCameraMoving: boolean = false;

  public dragged: VNode = null;

  constructor(params: EngineInitParams) {
    super();
    (window as any).engine = this;
    const {
      render,
      renderManager,
      factory,
      scene,
      viewport,
      cameraElement,
      canvasElement,
    } = params;

    this.cameraElement = cameraElement;
    this.render = render;
    this.renderManager = renderManager;
    this.factory = factory;
    this.scene = scene;
    this.canvasElement = canvasElement;
    this.entities = new Map<Id, Entity>();

    this.root = new Entity(this, 'root');
    this.camera = new CameraEntity(this, 'camera', new PerspectiveCamera());
    this.camera.vnode = f({
      id: 'camera',
    });
    this.camera.vnode.entity = this.camera;

    const start = f(
      {
        id: 'root',
        sheet: {
          orientTowardsCamera: false,
        },
      },
      [this.camera.vnode],
    );

    const ResizeObserver =
      (window as any).ResizeObserver || ResizeObserverPolyfill;
    this.resizeObserver = new ResizeObserver(() => {
      this.needsRelayout = true;
    });

    this.root.vnode = start;
    this.scene.add(this.root.target);
    this.scene.add(this.root.carrier);

    this.root.mountElementAs3DSheet();

    this.globalPropsPlugin = params.globalPropsPlugin;
    this.globalPropsPlugin.registerNode('engine');

    this.setViewportSize(viewport);
  }

  async shutdown() {
    while (this.state != PatchStep.WAITING_FOR_NEXT) {
      this.tick();
      await sleep();
    }
    if (this.resizeObserver) this.resizeObserver.disconnect();
    this.globalPropsPlugin.unregisterNode('engine');
    this.scene.remove(this.root.target);
    this.scene.remove(this.root.carrier);

    this.root.delete();
    this.leavingEntities.forEach((entity) => {
      entity.delete();
    });
  }

  setViewportSize(size: Size2D) {
    this.viewport = size;
    this.needsRelayout = true;

    if (this.camera) {
      this.camera.setViewportSize(size);
    }
  }

  getPerspectiveCamera(): PerspectiveCamera {
    return this.camera?.getCamera();
  }

  cameraIsMoving(isMoving: boolean) {
    this.isCameraMoving = isMoving;
  }

  // called by entities to make engine wait before running patch
  startLoad() {
    this.numPendingLoadingTasksForNext += 1;
  }

  finishLoad() {
    this.numPendingLoadingTasksForNext -= 1;
    if (this.numPendingLoadingTasksForNext == 0) {
      this.state = PatchStep.NEW_ENTITIES_LOADED;
    }
  }

  setAppIsVisible(isVisible) {
    this.appIsVisible = isVisible;
  }

  async patch(next: EngineState, appIsVisible: boolean): Promise<void> {
    if (this.next || this.state != PatchStep.WAITING_FOR_NEXT)
      throw new Error('previous patch is still in progress');

    this.setAppIsVisible(appIsVisible);
    // We wrap whatever we get, so we can run diff algorithm recursively from root
    next.vnode = f(
      {
        id: 'root',
        sheet: {
          orientTowardsCamera: false,
        },
      },
      [next.vnode],
    );
    this.next = next;

    this.state = PatchStep.NEXT_VNODE_COMITTED;
    logger.debug('Patch started');
    trackPerformance.start('patch');
    return new Promise((resolve) => {
      this.once('patchcompleted', () => {
        logger.debug('Patch completed');
        resolve();
      });
    });
  }

  tick() {
    switch (this.state) {
      case PatchStep.WAITING_FOR_NEXT:
        // logger.debug('Tick - PatchStep WAITING_FOR_NEXT');
        if (this.needsRelayout) {
          logger.debug('Tick - needsRelayout');
          trackPerformance.start('tick1-needsRelayout');
          this.calculateNextLayout();
          this.startTransitionsAndCompletePatch();
          trackPerformance.stop('tick1-needsRelayout');
        }
        return;
      case PatchStep.NEXT_VNODE_COMITTED:
        logger.debug('Tick - PatchStep NEXT_VNODE_COMITTED');
        // 1. Figure out whether there has been a root switch
        // 2. If there was no root shift that requires a non overlapping transition, match the next Vnode to existing entities, creating new entities as needed and already patch the Vue Props
        // The tree stays still exactly as it was.
        trackPerformance.start('tick2');
        this.diffAndLoadNewEntities();
        trackPerformance.stop('tick2');
        return;
      case PatchStep.NEW_ENTITIES_ARE_LOADING:
        logger.debug('Tick - PatchStep NEW_ENTITIES_ARE_LOADING');
        return;
      case PatchStep.NEW_ENTITIES_LOADED:
        logger.debug('Tick - PatchStep NEW_ENTITIES_LOADED');
        trackPerformance.start('tick3');
        // Now all DOM nodes are created and they will be inserted into their parent if necessary
        // At the same time all entities will be patched to their new parent
        // children that switched their parent as well as those that are the root of a entering or leaving subtree will be detached.
        // This will also abort all running carrier transitions
        logger.debug('Before patchTree');
        this.patchTree();
        // Immediately after the tree is patched it's important to run a layout calculation which will setup the target transforms and world locations
        logger.debug('Before calculateNextLayout');
        this.calculateNextLayout();
        // After the layout has been calculated, we can compute the origin offset of the root element
        // Also immediately after every layout the transitions need to be started. They have access to all target world locations as well as the current patch context
        logger.debug('Before startTransitionsAndCompletePatch');
        this.startTransitionsAndCompletePatch();
        trackPerformance.stop('tick3');
        break;
      default:
        return;
    }
  }

  private diffAndLoadNewEntities() {
    logger.debug('diffAndLoadNewEntities');
    trackPerformance.start('diffAndLoadNewEntities');
    const currentScene = getScene(this.root.vnode);
    const nextScene = getScene(this.next.vnode);

    this.currentPatch = {
      ...this.next,
      hasSceneSwitch: false,
      lastScene: currentScene,
      nextScene: nextScene,
    };
    if (
      nextScene &&
      currentScene &&
      nextScene.props.id != currentScene.props.id &&
      currentScene.props.id != 'camera'
    ) {
      this.currentPatch.hasSceneSwitch = true;
    }
    this.patchGlobalProps(this.next.globalProps);

    trackPerformance.start('rootDiff');
    this.root.diff(this.next.vnode);
    trackPerformance.stop('rootDiff');

    if (this.numPendingLoadingTasksForNext != 0) {
      this.state = PatchStep.NEW_ENTITIES_ARE_LOADING;
    } else {
      this.state = PatchStep.NEW_ENTITIES_LOADED;
    }
    trackPerformance.stop('diffAndLoadNewEntities');
  }

  private patchGlobalProps(newGlobalProps: Record<string, Array<any>>) {
    trackPerformance.start('patchGlobalProps');
    Object.keys(this.globalProps).forEach((key) => {
      if (!newGlobalProps[key]) {
        this.globalPropsPlugin.stopBroadcast('engine', key);
        Vue.delete(this.globalProps, key);
      }
    });

    Object.keys(newGlobalProps).forEach((key) => {
      // const newValue = clone(newGlobalProps[key]);
      if (!this.globalProps[key]) {
        Vue.set(this.globalProps, key, newGlobalProps[key]);
        this.globalPropsPlugin.broadcast('engine', key, () => {
          return {
            value: this.globalProps[key],
          };
        });
      } else {
        this.globalProps[key] = newGlobalProps[key];
      }
    });
    trackPerformance.stop('patchGlobalProps');
  }

  private patchTree() {
    logger.debug('patchTree');
    trackPerformance.start('patchTree');
    this.root.applyPatch();
    trackPerformance.stop('patchTree');

    this.next = null;
  }

  private calculateNextLayout() {
    trackPerformance.start('rootLayout');
    this.root.layout();
    trackPerformance.stop('rootLayout');

    // Root Layout
    if (this.currentPatch && this.currentPatch.hasSceneSwitch) {
      const { nextScene, lastScene } = this.currentPatch;
      logger.debug('Scene Switch: Last Scene', lastScene.entity.id, lastScene);
      logger.debug('Scene Switch: Next Scene ', nextScene.entity.id, nextScene);
      // Here we can emulate a relative positioning associated with this root switch
    }
    this.needsRelayout = false;
  }

  private startTransitionsAndCompletePatch() {
    trackPerformance.start('startTransitions');
    this.root.startCarrierTransitions();
    this.state = PatchStep.PATCH_COMPLETED;

    this.obsoleteEntities.forEach((entity) => {
      entity.leave();
    });
    this.obsoleteEntities.clear();

    this.render();
    trackPerformance.stop('startTransitions');

    this.currentPatch = null;

    trackPerformance.stop('patch');
    trackPerformance.log();
    trackPerformance.reset();

    this.emit('patchcompleted');
  }

  public getEntity(id: string) {
    return this.entities.get(id) || this.leavingEntities.get(id);
  }

  public eventPositionToCanvasPosition(event: MouseEvent) {
    const { left, top, width, height } =
      this.canvasElement.getBoundingClientRect();

    const normalizedX = event.clientX - left;
    const normalizedY = event.clientY - top;

    const x = normalizedX - width / 2;
    const y = height / 2 - normalizedY;

    return {
      x,
      y,
    };
  }
}
