import { deepEqual, shallowEqual } from 'fast-equals';
import { Object3D, Box3, Vector3, Box3Helper, Color } from 'three';
import {
  CSS3DObject,
  CSS3DSprite,
} from 'three/examples/jsm/renderers/CSS3DRenderer';
import { nanoid } from 'nanoid';

import { Engine } from './engine';
import { VNode } from './../vnode';

import {
  Location3DType,
  EvaluatedModuleSize,
  useTrackPerformance,
  useLogger,
  Logger,
  LogLevels,
  ModuleDraggable,
  EvaluatedModuleArrangement,
  AnimationOptions,
  AnimationTarget,
  TransitionOptions,
  Id,
  Vector3Type,
} from '@fillip/api';
import * as Location3D from '@/utils/location3D';
import { Object3DAnimator, resolveTransition } from './../systems/animation';
import {
  applySizeConstraints,
  parseSizeConstraints,
  consolidateSizeConstraints,
  Size2D,
  Size3D,
  SizeConstraints,
  DefaultSizeConstraints,
} from './../systems/size';
import {
  groupChildrenByPlacement,
  layoutAbsoluteChildren,
  layoutArrangedChildren,
  layoutFixedChildren,
} from './../systems/layout';
import { isSheetActive } from '../systems';
import { addClasses, patchClasses, removeClasses } from './../systems/classes';
import { patchStyle } from './../systems/styles';
import { VueComponentInstance } from './../systems/components';

import gsap from 'gsap';
import { Draggable } from 'gsap/all';
import InertiaPlugin from 'public/assets/InertiaPlugin.js';
gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const trackPerformance = useTrackPerformance(false, 'entity');

export type EntityObject3D = Object3D & { entity: Entity };
export type EntityHTMLElement = HTMLElement & { entity: Entity };
interface DraggableInstance {
  draggable: Draggable;
  hits: Record<string, EntityHTMLElement>;
  isDragged: boolean;
  dragEnded: boolean;
  dropTargets: Element[];
  ghost?: Element;
}

const _SIZE_VECTOR3 = new Vector3();

export class Entity {
  public id: Id;
  public cssId: string;

  public engine: Engine;
  public vnode: VNode;

  public logger: Logger;

  public parent: Entity = null;
  public children: Entity[] = [];

  public target: EntityObject3D;
  public targetMountPoint: 'camera' | 'parent' | '' = '';

  public carrier: EntityObject3D;
  public carrierTransform: Object3DAnimator;
  public carrierIsAtTarget: boolean = false;
  public carrierIsDetached: boolean = true;

  public content: EntityObject3D; // Contains this.css3DObject + children[i].carrier for attached children
  public contentTransform: Object3DAnimator;
  public contentIsAtCarrier: boolean = true;

  public isLeaving: boolean = false;
  public hasSceneSwitch: boolean = false;

  public boundingBox: Box3 = new Box3();

  public el: EntityHTMLElement; // Contains vm.$el and children[i].el (if elMountPoint == 'parent')
  public elMountPoint: '3D' | 'parent' = 'parent';

  public css3DObject: CSS3DObject | CSS3DSprite | null = null; // contains this.el

  public element: VueComponentInstance;
  public model: VueComponentInstance;

  public draggable: DraggableInstance = null;

  // During patch
  public nextVnode: VNode | null;
  // Up to which child index the previous and new child array overlap
  // Initially, we don't know, so it's -1 to make sure we don't regard
  // the first element as overlapping by setting overlap to 0
  public overlap: number = -1;

  constructor(engine: Engine, id: Id, object3D: Object3D = new Object3D()) {
    this.engine = engine;
    this.id = id;
    this.cssId = id.replace(':', '_-_');

    this.logger = useLogger(LogLevels.WARN, LogLevels.NONE, `entity::${id}`);

    this.content = object3D as EntityObject3D;
    this.content.name = `content:${id}`;
    this.content.entity = this;
    this.contentTransform = new Object3DAnimator(this.content);

    this.carrier = new Object3D() as EntityObject3D;
    this.carrier.name = `carrier:${id}`;
    this.carrier.entity = this;
    this.carrier.add(this.content);
    this.carrierTransform = new Object3DAnimator(this.carrier);

    this.target = new Object3D() as EntityObject3D;
    this.target.name = `target:${id}`;
    this.target.entity = this;
    // TODO: Add toggle to dynamically activate/deactivate
    // this.target.add(new Box3Helper(this.boundingBox, new Color('green')));
    // this.carrier.add(new Box3Helper(this.boundingBox, new Color('blue')));
    // this.content.add(new Box3Helper(this.boundingBox, new Color('red')));

    this.el = document.createElement('div') as unknown as EntityHTMLElement;
    this.el.addEventListener('click', (event) => {
      if (!this.engine.isCameraMoving) {
        this.handleEvent('click', event);
      }
    });
    // this.el.addEventListener('dblclick', () => {
    //   if (!this.css3DObject) return;
    //   // TODO: WHY?
    //   // this.boundingBox;
    //   this.engine.camera.zoomToFit(this.boundingBox);
    // });
    this.el.addEventListener('mouseenter', (event) => {
      this.handleEvent('mouseenter', event);
    });
    this.el.addEventListener('mouseleave', (event) => {
      this.handleEvent('mouseleave', event);
    });

    this.el.id = 'container_-_' + this.cssId;

    this.el.entity = this;

    this.vnode = new VNode({}, []);
    engine.entities.set(id, this);
  }

  get isAtTarget() {
    return this.carrierIsAtTarget && this.contentIsAtCarrier;
  }

  getSize(): Size3D {
    const sizeVec = this.boundingBox.getSize(_SIZE_VECTOR3);
    return {
      width: sizeVec.x,
      height: sizeVec.y,
      depth: sizeVec.z,
    };
  }

  startCarrierTransitions() {
    this.logger.debug(
      this.id,
      'startCarrierTransitions',
      this.carrierIsAtTarget,
    );
    if (!this.carrierIsAtTarget) {
      if (this.carrierIsDetached) {
        this.logger.debug('Carrier is detached');

        this.target.updateWorldMatrix(true, false);
      }

      const target = this.carrierIsDetached
        ? this.carrierTransform.getTargetFromMatrix4(this.target.matrixWorld)
        : this.target;
      const animationTarget: AnimationTarget =
        Object3DAnimator.fromObject3D(target);

      if (!this.carrier.parent) {
        this.enter(animationTarget);
      } else {
        if (this.carrierTransform.isAtTarget(target)) {
          // Do nothing if we are already at the target
          this.logger.debug('Carrier already at target');
          this.logger.debug(this.carrierTransform, target);
          this.setCarrierReachedTarget();
        } else if (!this.carrierTransform.isTweeningTo(target)) {
          // check that there's not already a tween running
          this.transitionCarrier(animationTarget);
        }
      }
    }

    this.children.forEach((child) => child.startCarrierTransitions());
  }

  enter(to: AnimationOptions) {
    this.engine.scene.add(this.carrier);
    this.handleEvent('load', this.id);

    const transition = this.vnode?.props.transitions?.enter || 'scaleIn';
    const enterFrom = this.vnode?.props.transitions?.enterFrom || 'target';

    // Start the enter transition from the future world position
    // Will be partially overwritten with from options in selected transition

    const from =
      enterFrom === 'target'
        ? Object3DAnimator.fromAny(to)
        : Object3DAnimator.fromMatrix4(this.parent.target.matrixWorld);

    const onComplete = () => {
      this.setCarrierReachedTarget();
      this.handleEvent('ready', this.id);
    };

    const onInterrupt = () => {
      this.handleEvent('ready', this.id);
    };

    this.logger.debug('Enter', this.id, to, transition, from, onComplete);

    this.transitionObject(
      this.carrierTransform,
      to,
      transition,
      from,
      onComplete,
      null,
      onInterrupt,
    );
  }

  leave() {
    this.logger.debug('Leaving');
    this.handleEvent('beforeLeave', this.id);
    this.engine.entities.delete(this.id);
    this.engine.leavingEntities.set(this.id, this);
    this.isLeaving = true;

    const transition = this.vnode?.props.transitions?.leave || 'scaleOut';
    const leaveTo = this.vnode?.props.transitions?.leaveTo || 'target';
    // TODO: Often cannot find parent, so this actually behaves like 'target' most of times
    const from =
      leaveTo === 'target' || !this.parent?.target
        ? this.carrierTransform.animationTarget
        : Object3DAnimator.fromMatrix4(this.parent.target.matrixWorld);

    const to = this.carrierTransform.animationTarget;

    const onComplete = () => {
      this.delete();
    };

    this.transitionObject(
      this.carrierTransform,
      to,
      transition,
      from,
      onComplete,
    );

    this.traverseChildren((entity) => {
      entity.isLeaving = true;
      this.engine.entities.delete(entity.id);
      this.engine.leavingEntities.set(entity.id, entity);
    });
  }

  delete() {
    this.handleEvent('beforeUnload', this.id);
    while (this.children.length) {
      this.children[this.children.length - 1].delete();
    }
    if (this.parent) {
      this.parent.removeChild(this);
    } else {
      this.el.remove();
      // this.el = null;
    }

    if (this.element) this.element = this.element.unload();

    if (this.model) this.model = this.model.unload();

    if (this.css3DObject) {
      this.unmountElementAs3DSheet();
    }

    if (gsap.isTweening(this.contentTransform)) {
      gsap.killTweensOf(this.contentTransform);
    }
    this.content.removeFromParent();
    this.content = null;
    this.contentTransform = null;

    if (gsap.isTweening(this.carrierTransform)) {
      gsap.killTweensOf(this.carrierTransform);
    }
    this.carrier.removeFromParent();
    this.carrier = null;
    this.carrierTransform = null;

    this.target.removeFromParent();
    this.target = null;
    this.carrierTransform = null;
    this.contentTransform = null;
    if (this.isLeaving) {
      this.engine.leavingEntities.delete(this.id);
    } else {
      this.engine.entities.delete(this.id);
    }
    this.logger.debug('Scene after delete', this, this.engine.scene);
    this.handleEvent('unload', this.id);
  }

  transitionCarrier(
    to: AnimationOptions,
    transition?: TransitionOptions,
    from?: AnimationTarget,
    onComplete?: () => void,
    onUpdate?: () => void,
  ) {
    const onTransitionComplete = () => {
      if (onComplete && typeof onComplete === 'function') onComplete();
      this.setCarrierReachedTarget();
    };
    const onTransitionUpdate =
      onUpdate && typeof onUpdate === 'function' ? onUpdate : null;

    const transitionToUse =
      this.engine.dragged?.props.id == this.id
        ? false
        : transition || this.vnode?.props.transitions?.transform || 'default';

    // TODO: Do we want to look at the draggged id here or just do:
    // const transitionToUse = transition ?? this.vnode?.props.transitions?.transform ?? 'default';

    this.logger.debug('transitionCarrier', to, transitionToUse, from);
    this.transitionObject(
      this.carrierTransform,
      to,
      transitionToUse,
      from,
      onTransitionComplete,
      onTransitionUpdate,
    );
  }

  transitionObject(
    targetObject: Object3DAnimator,
    to: AnimationOptions,
    transition: TransitionOptions = 'default',
    from?: AnimationTarget,
    onComplete?: () => void,
    onUpdate?: () => void,
    onInterrupt?: () => void,
  ) {
    this.logger.debug('transitionObject', targetObject, to, transition, from);

    if (!this.engine.appIsVisible) transition = false;
    const transitionToUse = resolveTransition(transition);

    const onTransitionUpdate = () => {
      targetObject.update();
      if (onUpdate && typeof onUpdate === 'function') onUpdate();
    };

    const onTransitionComplete = () => {
      if (onComplete && typeof onComplete === 'function') onComplete();
      this.engine.render();
    };

    const onTransitionInterrupt = () => {
      if (onInterrupt && typeof onInterrupt === 'function') onInterrupt();
      this.engine.render();
    };

    if (!transitionToUse) {
      // No transition, immediately set `to`
      this.logger.debug('No transition', targetObject, to);
      gsap.set(targetObject, to).play();
      targetObject.update();
      onTransitionComplete();
      return;
    }

    const _to: AnimationOptions = {
      overwrite: true,
      onUpdate: onTransitionUpdate,
      onComplete: onTransitionComplete,
      onInterrupt: onTransitionInterrupt,
      ...to,
      ...(transitionToUse?.to || {}),
    };
    let _from: AnimationTarget = from || null;

    if ('from' in transitionToUse) {
      _from = {
        ...(_from || {}),
        ...transitionToUse.from,
      };
    }

    if (_from) {
      return gsap.fromTo(targetObject, _from, _to).play();
    }

    return gsap.to(targetObject, _to).play();
  }

  traverseChildren(callback: (entity: Entity) => void) {
    this.children.forEach((child) => {
      child.traverseChildren(callback);
      callback(child);
    });
  }

  mountElementAs3DSheet(newNode?: VNode) {
    const orientTowardsCamera = newNode?.props?.sheet?.orientTowardsCamera;
    const containerPointerNone =
      (newNode?.props?.placement?.type == 'placement.absolute' &&
        newNode.props.placement.containerPointerNone) ||
      newNode?.props?.sheet?.containerPointerNone;

    if (this.elMountPoint == '3D') {
      if (
        this.vnode?.props?.sheet?.orientTowardsCamera == orientTowardsCamera
      ) {
        return;
      } else {
        this.unmountElementAs3DSheet();
      }
    }

    this.logger.debug('mountElementAs3DSheet');

    const cssElement = document.createElement('div');
    cssElement.id = `carrier_-_${this.cssId}`;
    this.css3DObject = orientTowardsCamera
      ? new CSS3DSprite(cssElement)
      : new CSS3DObject(cssElement);

    this.css3DObject.name = `css3d_-_${this.cssId}`;
    // TODO: Does this work?
    // this.css3DObject.visible = false;
    this.css3DObject.element.appendChild(this.el);
    this.engine.resizeObserver.observe(this.css3DObject.element, {});
    // This gets automatically set to true on first render, because css3DObject.visible == true by default
    // this.css3DObject.element.style.visibility = 'hidden';
    if (containerPointerNone) {
      this.css3DObject.element.style.pointerEvents = 'none';
    }

    this.content.add(this.css3DObject);
    this.elMountPoint = '3D';
  }

  unmountElementAs3DSheet() {
    this.el.remove();
    this.engine.resizeObserver.unobserve(this.css3DObject.element);
    this.css3DObject.element.remove();
    this.css3DObject.removeFromParent();
    this.css3DObject = null;
  }

  mountElementInParent() {
    if (this.elMountPoint == 'parent') return;
    this.logger.debug('mountElementInParent');

    if (this.css3DObject) {
      this.unmountElementAs3DSheet();
    }

    if (this.parent) {
      const index = this.parent.children.indexOf(this);

      const nextEl = this.parent.findNextMountedChildElement(index);
      this.parent.el.insertBefore(this.el, nextEl);
    }

    this.elMountPoint = 'parent';
  }

  mountTargetInCamera() {
    this.logger.debug('mountTargetInCamera');
    this.engine.camera.cameraTarget.add(this.target);
    this.targetMountPoint = 'camera';
    this.carrierIsAtTarget = false;
  }

  unmountTarget() {
    this.logger.debug('unmountTarget');
    this.target.removeFromParent();
    this.targetMountPoint = '';
  }

  // Is called during patch process to remove a child completely from its parent
  removeChild(child: Entity) {
    this.logger.debug('removeChild', child);
    // Optimization: Check if we are poping the last child
    const index =
      this.children[this.children.length - 1] == child
        ? this.children.length - 1
        : this.children.indexOf(child);
    this.children.splice(index, 1);
    child.parent = null;

    child.unmountTarget();

    if (gsap.isTweening(this.carrierTransform)) {
      gsap.killTweensOf(this.carrierTransform); // Since the target has changed, it's better to stop all tweens
    }
    if (!child.carrierIsDetached) {
      this.engine.root.content.attach(child.carrier);
      child.carrierTransform.sync();
      // Maybe more efficient than attach:
      // const child3D = child.object3D;
      // child3D.updateWorldMatrix(true, false);
      // this.object3D.remove(child3D);
      // child3D.matrix.copy(this.object3D.matrixWorld);
      // child3D.matrix.decompose(
      //   child3D.position,
      //   child3D.quaternion,
      //   child3D.scale,
      // );
      // child3D.updateWorldMatrix(false, true);
      child.carrierIsDetached = true;
      child.carrierIsAtTarget = false; // Since target doesn't exist if parent == null
    }

    if (child.elMountPoint == 'parent') {
      child.el.remove();
    }
  }

  removeFromParent() {
    this.logger.debug('removeFromParent');
    if (this.parent) {
      this.parent.removeChild(this);
    }
  }

  appendChild(child: Entity) {
    this.logger.debug('appendChild', child);
    child.removeFromParent();

    this.target.add(child.target);
    child.targetMountPoint = 'parent';

    if (child.elMountPoint == 'parent') {
      this.el.appendChild(child.el);
    }
    this.children.push(child);
    child.parent = this;
  }

  setCarrierReachedTarget() {
    if (this.carrierIsAtTarget) return;
    this.logger.debug('carrierReachedTarget');
    this.carrierIsAtTarget = true;
    this.attachNowIfAtTarget();
  }

  setContentReachedCarrier() {
    if (this.contentIsAtCarrier) return;
    this.contentIsAtCarrier = true;
    this.attachNowIfAtTarget();
  }

  // To be called when an element reaches its target position, so it and its children can be attached
  attachNowIfAtTarget() {
    this.logger.debug(
      'attachNowIfAtTarget',
      this.targetMountPoint,
      this.carrierIsAtTarget,
      this.carrierIsDetached,
    );
    if (!this.carrierIsAtTarget) return;
    if (this.carrierIsDetached && this.parent && this.parent.isAtTarget) {
      if (this.targetMountPoint == 'parent') {
        this.parent.content.attach(this.carrier);
      } else if (this.targetMountPoint == 'camera') {
        this.engine.camera.content.attach(this.carrier);
      }
      this.carrierTransform.sync();
      this.carrierIsDetached = false;
    }

    // TODO: Optimize this with a flag
    this.children.forEach((child) => {
      child.attachNowIfAtTarget();
    });
  }

  private findNextMountedChildElement(index): EntityHTMLElement {
    for (let i = index; i < this.children.length; ++i) {
      if (this.children[i].elMountPoint == 'parent') {
        return this.children[i].el;
      }
    }
    return null;
  }

  public handleEvent(
    name: string,
    event: any,
    hops: number = 0,
    source?: string,
  ): void {
    const handler = this.vnode.props.listener?.listeners?.[name];
    const context = this.vnode.props.context;

    this.logger.debug('handleEvent', name, event, hops, source, handler);

    if (
      handler &&
      (!source ||
        (handler.listenToChildren &&
          (!handler.maxDepth || hops <= handler.maxDepth)))
    ) {
      if (typeof event.stopPropagation == 'function') {
        event.stopPropagation();
      }
      this.engine.emit('event', {
        name,
        event,
        script: handler.script,
        context: {
          ...handler.context,
          ...context,
          ...context.$vars,
          ...context.$props,
          ...context.$computeds,
        },
        entity: this,
      });
    }

    if (!handler?.stopPropagation && this.parent) {
      this.parent.handleEvent(name, event, hops + 1, this.id);
    }
  }

  private patchElement(newNode: VNode) {
    this.element = VueComponentInstance.patch(
      newNode.props.id,
      'element',
      this.vnode?.props?.element,
      newNode.props.element,
      this.element,
      this,
    );
    if (this.element)
      this.element.load({ classes: newNode.props.class?.elementClass });
  }

  private patchModel(newNode: VNode) {
    this.model = VueComponentInstance.patch(
      newNode.props.id,
      'model',
      this.vnode?.props?.model,
      newNode.props.model,
      this.model,
      this,
    );
    if (this.model) this.model.load();
  }

  applyPatch() {
    this.logger.debug(
      this.id,
      'applyPatch',
      this.nextVnode,
      this.targetMountPoint,
      this.overlap,
    );
    if (!this.nextVnode) {
      this.logger.warn('Duplicate VNode');
      return;
    }

    if (
      this.nextVnode.props?.placement?.type == 'placement.fixed' &&
      this.targetMountPoint != 'camera'
    ) {
      this.mountTargetInCamera();
    }

    // Only remove children if we had any
    if (this.children.length > 0) {
      // Remove all children after the overlap, accounting for overlap being a zero-based index
      while (this.children.length - 1 != this.overlap) {
        const child = this.children[this.children.length - 1];
        this.removeChild(child);
      }
    }

    // Only add children if we will have any in next
    if (this.nextVnode.children.length > 0) {
      // Add children in next starting after the last overlapping child
      for (let i = this.overlap + 1; i < this.nextVnode.children.length; ++i) {
        this.appendChild(this.nextVnode.children[i].entity);
      }
    }

    this.vnode = this.nextVnode;
    this.nextVnode = null;
    this.children.forEach((child) => child.applyPatch());
  }

  diff(newNode: VNode) {
    this.nextVnode = newNode;
    this.logger.debug('Start diff', newNode);

    this.patchElement(newNode);
    this.patchModel(newNode);

    this.patchSheet(newNode);

    this.patchStyle(newNode);
    this.patchClass(newNode);

    this.patchDroppable(newNode);
    this.patchDraggable(newNode);

    this.compareCurrentAndNextChildren();

    newNode.children.forEach((child) => {
      child.entity.diff(child);
    });
  }

  private compareCurrentAndNextChildren() {
    let foundMismatch = false;
    const maxNumChildren = Math.max(
      this.nextVnode.children.length,
      this.vnode.children.length,
    );
    for (let i = 0; i < maxNumChildren; ++i) {
      const next = this.nextVnode.children[i];
      const current = this.vnode.children[i];

      // match from start until first mismatch
      if (
        next &&
        current &&
        !foundMismatch &&
        next.props.id == current.props.id
      ) {
        // Children arrays overlap up to and including index i
        this.overlap = i;
        next.entity = current.entity;
      } else {
        if (!foundMismatch) {
          // If no mismatch occured until now,  the last overlapping index was i - 1
          this.overlap = i - 1;
          foundMismatch = true;
        }

        if (current && !current.entity.nextVnode) {
          this.engine.obsoleteEntities.set(current.entity.id, current.entity);
        }

        if (next) {
          // look for already existing node with same id
          if (next.props.id && this.engine.entities.has(next.props.id)) {
            this.engine.obsoleteEntities.delete(next.props.id);
            next.entity = this.engine.entities.get(next.props.id);
          } else {
            // create new node
            next.entity = new Entity(this.engine, next.props.id || nanoid());
          }

          next.entity.nextVnode = next;
        }
      }
    }
  }

  private patchClass(newNode: VNode) {
    if (this.vnode?.props.class) {
      patchClasses(
        this.el,
        this.vnode.props.class.class || '',
        newNode?.props?.class?.class || '',
      );
      if (this.element && this.element.vueInstance) {
        patchClasses(
          this.element.vueInstance.$el as HTMLElement,
          this.vnode.props.class.elementClass || '',
          newNode.props.class?.elementClass || '',
        );
      }
    } else if (newNode.props.class) {
      patchClasses(this.el, '', newNode.props.class.class || '');
      if (this.element && this.element.vueInstance) {
        patchClasses(
          this.element.vueInstance.$el as HTMLElement,
          '',
          newNode.props.class.elementClass || '',
        );
      }
    }
  }

  private patchStyle(newNode: VNode) {
    const oldProps = this.vnode?.props?.style;
    const newProps = newNode?.props?.style;

    if (!oldProps && !newProps) return;

    patchStyle(this.el, oldProps || {}, newProps || {});
  }

  private patchSheet(newNode: VNode) {
    if (isSheetActive(newNode.props)) {
      this.mountElementAs3DSheet(newNode);
    } else {
      this.mountElementInParent();
    }
  }

  setSizeConstraints(constraints?: SizeConstraints) {
    if (this.vnode.props.size) {
      constraints = consolidateSizeConstraints(
        parseSizeConstraints(
          this.vnode.props.size as EvaluatedModuleSize,
          this.engine.viewport,
        ),
        constraints,
      );
    }
    if (!constraints) constraints = DefaultSizeConstraints;
    applySizeConstraints(this.el, constraints);
    if (this.css3DObject?.element) {
      const el = this.css3DObject.element;
      applySizeConstraints(el, constraints);
    }
    if (this.element?.vueInstance?.$el) {
      applySizeConstraints(this.element.vueInstance.$el as any, constraints);
    }
    return constraints;
  }

  setTargetLocation(location: Location3DType) {
    const { position, rotation, scale } = location;

    this.target.position.set(position.x, position.y, position.z);
    this.target.rotation.set(rotation.x, rotation.y, rotation.z, 'YXZ');
    this.target.scale.set(scale.x, scale.y, scale.z);

    this.target.updateMatrix();
    this.carrierIsAtTarget = false;

    // let changed = false;
    // const {
    //   position: currentPosition,
    //   rotation: currentRotation,
    //   scale: currentScale,
    // } = this.target;

    // if (!shallowEqual(currentPosition, position)) {
    //   changed = true;
    //   this.target.position.set(position.x, position.y, position.z);
    // }
    // if (
    //   !(
    //     currentRotation.x == rotation.x &&
    //     currentRotation.y == rotation.y &&
    //     currentRotation.z == rotation.z
    //   )
    // ) {
    //   changed = true;
    //   this.target.rotation.set(rotation.x, rotation.y, rotation.z, 'YXZ');
    // }

    // if (!shallowEqual(currentScale, scale)) {
    //   changed = true;
    //   this.target.scale.set(scale.x, scale.y, scale.z);
    // }
    // if (changed) {
    // this.target.updateMatrix();
    // this.carrierIsAtTarget = false;
    // }
  }

  moveCarrierToLocation(
    location: Partial<Location3DType>,
    transition: TransitionOptions,
    onComplete?: () => void,
    onUpdate?: () => void,
  ) {
    const carrierLocation = this.getCarrierLocation();

    const target = Object3DAnimator.fromLocation({
      ...carrierLocation,
      ...location,
    });
    this.logger.debug(
      'moveCarrierToLocation',
      carrierLocation,
      location,
      target,
    );

    this.transitionCarrier(target, transition, null, onComplete, onUpdate);
  }

  getTargetLocation(): Location3DType {
    return Location3D.getObjectLocation(this.target);
  }

  getTargetWorldPosition(): Vector3Type {
    return this.target.getWorldPosition(new Vector3());
  }

  getCarrierLocation(): Location3DType {
    return Location3D.getObjectLocation(this.carrier);
  }

  getContentLocation(): Location3DType {
    return Location3D.getObjectLocation(this.content);
  }

  moveContentToLocation(
    location: Location3DType,
    transition: TransitionOptions,
  ) {
    const to = Object3DAnimator.fromLocation(location);
    this.logger.debug('moveContentToLocation', to);
    const onComplete = () => {
      this.logger.debug('Content Moved');
      // TODO: This isn't right
      this.setContentReachedCarrier();
    };
    this.transitionObject(
      this.contentTransform,
      to,
      transition,
      null,
      onComplete,
    );
  }

  moveContentToCarrier(transition: TransitionOptions) {
    const to = this.carrierTransform.animationTarget;
    const onComplete = () => {
      this.setContentReachedCarrier();
    };
    this.transitionObject(
      this.contentTransform,
      to,
      transition,
      null,
      onComplete,
    );
  }

  moveCarrierToContent(transition: TransitionOptions) {
    const to = this.contentTransform.animationTarget;
    const onComplete = () => {
      this.setContentReachedCarrier();
    };
    this.carrierIsAtTarget = false;
    this.transitionObject(
      this.carrierTransform,
      to,
      transition,
      null,
      onComplete,
    );
  }

  syncTargetWithContent() {
    this.contentTransform.sync();
    this.setTargetLocation(this.contentTransform.location);
  }

  layout(constraints: SizeConstraints = DefaultSizeConstraints) {
    this.logger.debug('Start layout', constraints);
    trackPerformance.start(`layout::${this.id}`);

    trackPerformance.start(`layout::setSizeConstraints::${this.id}`);
    this.boundingBox.makeEmpty();
    constraints = this.setSizeConstraints(constraints);
    trackPerformance.stop(`layout::setSizeConstraints::${this.id}`);

    if (this.css3DObject) {
      trackPerformance.start(`layout::setCss3DBoundingBox::${this.id}`);
      this.setCss3DBoundingBox();
      trackPerformance.stop(`layout::setCss3DBoundingBox::${this.id}`);
    }

    trackPerformance.start(`layout::sortChildren::${this.id}`);
    const { camera, absoluteChildren, arrangedChildren, fixedChildren } =
      groupChildrenByPlacement(this.vnode.children);
    trackPerformance.stop(`layout::sortChildren::${this.id}`);

    if (absoluteChildren.length) {
      trackPerformance.start(`layout::absoluteChildren::${this.id}`);
      layoutAbsoluteChildren(
        absoluteChildren,
        this.engine.viewport,
        constraints,
      );
      trackPerformance.stop(`layout::absoluteChildren::${this.id}`);
    }

    if (arrangedChildren.length) {
      trackPerformance.start(`layout::arrangedChildren::${this.id}`);
      layoutArrangedChildren(
        arrangedChildren,
        this.engine.viewport,
        constraints,
        this.vnode.props?.arrangement as EvaluatedModuleArrangement,
      );
      trackPerformance.stop(`layout::arrangedChildren::${this.id}`);
    }

    if (fixedChildren.length) {
      trackPerformance.start(`layout::fixedChildren::${this.id}`);
      layoutFixedChildren(fixedChildren, this.engine.viewport);
      trackPerformance.stop(`layout::fixedChildren::${this.id}`);
    }

    trackPerformance.start(`layout::computeBoundingBox::${this.id}`);
    this.computeBoundingBox();
    trackPerformance.stop(`layout::computeBoundingBox::${this.id}`);

    if (camera) {
      trackPerformance.start(`layout::updateSceneCamera::${this.id}`);
      this.updateSceneCamera(camera);
      trackPerformance.stop(`layout::updateSceneCamera::${this.id}`);
    }

    // TODO: Does this work?
    // if (this.css3DObject) this.css3DObject.visible = true;
    trackPerformance.stop(`layout::${this.id}`);
  }

  setCss3DBoundingBox() {
    const el = this.css3DObject.element;

    const width = el.offsetWidth;
    const height = el.offsetHeight;

    this.boundingBox.set(
      new Vector3(-width / 2, -height / 2, 0),
      new Vector3(width / 2, height / 2, 0),
    );
  }

  computeBoundingBox() {
    const childBounds = new Box3();
    this.vnode.children.forEach((child) => {
      if (child.entity.vnode?.props?.placement?.type !== 'placement.fixed')
        childBounds.copy(child.entity.boundingBox);

      // ! Removed because it leads to screwed up childBounds
      // Reimplement if needed
      // childBounds.applyMatrix4(child.entity.target.matrix);

      this.boundingBox.union(childBounds);
    });
  }

  updateSceneCamera(camera) {
    const sceneCamera = this.engine.globalProps.sceneCamera?.[
      this.engine.globalProps.sceneCamera.length - 1
    ] || {
      type: 'camera.fixed',
    };

    this.engine.camera.updateCamera(
      sceneCamera,
      this.engine.currentPatch?.hasSceneSwitch,
    );
  }

  getElSize(): Size2D {
    const { width, height } = this.el.getBoundingClientRect();
    return { width, height };
  }

  patchDraggable(newNode) {
    const oldProps: ModuleDraggable = this.vnode?.props?.draggable;
    const newProps: ModuleDraggable = newNode.props.draggable;

    if (!newProps && !this.draggable) return;
    if (newProps && this.draggable && shallowEqual(newProps, oldProps)) return;

    if (this.draggable) {
      removeClasses(this.el, newProps?.draggableClasses);
      removeClasses(this.el, oldProps?.draggableClasses);
      this.draggable.draggable.kill();
      this.draggable = null;
    }
    if (!newProps || !newProps.isActive) return;

    // TODO: Set cursor and activeCursor via Draggable
    // TODO: Make handler configurable
    // TODO: Add auto scroll
    // TODO: Make snap and liveSnap configurable

    // FIXME
    // TODO: Fix bounds: Element is not found because it is not initialized yet when we run document.querySelector!
    const bounds = newProps.bounds
      ? typeof newProps.bounds == 'string' &&
        (newProps.bounds.startsWith('.') || newProps.bounds.startsWith('#'))
        ? document.querySelector(newProps.bounds)
        : newProps.bounds
      : null;

    // console.log(
    //   'Bounds',
    //   newProps.bounds,
    //   bounds,
    //   document.querySelector(newProps.bounds),
    // );

    // this.css3DObject.element.style.backgroundColor = '#ff0ff0';
    // this.el.style.backgroundColor = '#ffff90';

    // TODO: Create draggable instance, but only initiate the Gsap draggable after entities have loaded
    const draggable = Draggable.create(this.el, {
      type: newProps.dragType,
      bounds,
      inertia: newProps.inertia ?? false,
      callbackScope: this,
      snap: {
        x: function (endValue) {
          return Math.round(endValue);
        },
        y: function (endValue) {
          return Math.round(endValue);
        },
      },
      liveSnap: true,
      onDragStart: this.onDragStart,
      onDragEnd: newProps.inertia ? null : this.onDragEnd,
      onThrowComplete: newProps.inertia ? this.onDragEnd : null,
      onDrag: this.onDrag,
      onPress: this.onPress,
      onRelease: this.onRelease,
    });

    this.draggable = {
      isDragged: false,
      dragEnded: false,
      dropTargets: [],
      hits: {},
      draggable: draggable[0],
    };
    addClasses(this.el, newProps.draggableClasses);
  }

  onPress(event) {
    // console.log('onPress', event);
    this.engine.camera.disableControls();
    event.preventDefault();
  }

  onRelease(event) {
    // console.log('onRelease', event);
    this.engine.camera.enableControls();
    event.preventDefault();
  }

  onDragStart(event) {
    const dragProps = this.vnode.props.draggable;
    if (!dragProps || !dragProps.isActive) return;
    this.logger.debug('make entity draggable', this, this.elMountPoint);
    // TODO: Turn dragged object into sheet
    // if (!isSheetActive(this.vnode.props)) {
    //   console.log('Drag Start', this.getElSize());
    //   const { width, height } = this.getElSize();
    //   this.setSizeConstraints(
    //     ExactSizeConstraints({ width, height, depth: 0 }),
    //   );
    //   this.mountElementAs3DSheet();
    //   // this.setSizeConstraints(
    //   //   ExactSizeConstraints({ width, height, depth: 0 }),
    //   // );
    //   console.log(
    //     'mounted',
    //     this,
    //     JSON.stringify(this.css3DObject),
    //     this.elMountPoint,
    //     JSON.stringify(this.getSize()),
    //     JSON.stringify(this.getElSize()),
    //   );
    //   console.log('this after dragstart', this);
    // }
    this.carrier.position.z += 50;
    this.engine.render();
    this.engine.dragged = this.vnode;
    addClasses(this.el, dragProps.draggedClasses);

    if (dragProps.onDragStart) {
      this.emitDragDropEvent('dragStart', dragProps.onDragEnd, event);
    }
    if (dragProps.droppableIdentifier) {
      this.draggable.dropTargets = Array.from(
        document.getElementsByClassName(dragProps.droppableIdentifier),
      );

      this.performHitTest(event);
    }
  }

  onDragEnd(event) {
    const dragProps = this.vnode.props.draggable;
    if (!dragProps || !dragProps.isActive) return;

    const { pointerX, pointerY, endX, endY, deltaX, deltaY } =
      this.draggable.draggable;

    const { position } = this.getTargetLocation();

    // console.log('Target Position', position);

    const targetPosition = {
      x: position.x + endX,
      y: position.y - endY,
      z: position.z,
    };

    if (dragProps.droppableIdentifier) {
      // TODO: Reuse exising hit targets
      // for (const target of Object.values(this.draggable.hits)) {
      //   target.entity.onDrop(event, this.draggable.draggable);
      // }
      const targets = this.performImmediateHitTest();
      for (const target of targets) {
        target.entity.onDrop(event, this.draggable.draggable);
      }
    }
    this.draggable.dragEnded = true;

    this.logger.debug(
      'onDragEnd',
      event,
      this.draggable,
      dragProps.droppableIdentifier,
    );

    removeClasses(this.el, dragProps.draggedClasses);
    // TODO: Reset changes made by gsap, i.e. z-index

    this.logger.debug('Dropped', this.draggable, event);

    this.emitDragDropEvent('dragEnd', dragProps.onDragEnd, event, {
      targetPosition,
      pointerX,
      pointerY,
      endX,
      endY,
      deltaX,
      deltaY,
      rotation: -(endX * Math.PI) / 180,
    });

    const onComplete = () => {
      this.logger.debug('Move carrier after dragEnd complete');
      gsap.set(this.el, { clearProps: 'transform' });

      this.engine.dragged = null;
      this.draggable.dragEnded = false;
      // if (!isSheetActive(this.vnode.props)) {
      //   this.mountElementInParent();
      //   this.setSizeConstraints();
      // }
    };

    const targetLocation = Location3D.addDefaults({
      position: targetPosition,
    });
    const carrierTarget = Object3DAnimator.fromLocation(targetLocation);
    this.logger.debug('Drag ended, carrierTarget: ', carrierTarget);
    this.transitionCarrier(carrierTarget, false, null, onComplete);
    if (dragProps.stayAtPosition) this.setTargetLocation(targetLocation);

    this.draggable.dropTargets = null;
    this.draggable.hits = {};
  }

  onDrag(event) {
    const dragProps = this.vnode.props.draggable;
    // console.log('Dragged', event);

    if (dragProps.droppableIdentifier) {
      this.performHitTest(event);
    }
  }

  performImmediateHitTest() {
    const testElement = this.vnode.props.draggable.hitTarget
      ? document.querySelector(this.vnode.props.draggable.hitTarget)
      : this.el;
    const hits = [];
    this.draggable.dropTargets.forEach((target: EntityHTMLElement) => {
      if (
        Draggable.hitTest(
          testElement,
          target,
          this.vnode.props?.draggable?.droppableOverlap ?? '50%',
        )
      ) {
        hits.push(target);
      }
    });
    return hits;
  }

  performHitTest($event) {
    const hits: Record<string, EntityHTMLElement> = {};
    const testElement = this.vnode.props.draggable.hitTarget
      ? document.querySelector(this.vnode.props.draggable.hitTarget)
      : this.el;
    this.draggable.dropTargets.forEach((target: EntityHTMLElement) => {
      if (
        Draggable.hitTest(
          testElement,
          target,
          this.vnode.props?.draggable?.droppableOverlap ?? '50%',
        )
      ) {
        const id = target.entity.vnode.props.id;
        this.logger.debug('target', target.entity);
        hits[id] = target;
      }
    });

    Object.values(hits).forEach((newTarget) => {
      const id = newTarget.entity.vnode.props.id;
      if (!(id in this.draggable.hits)) {
        this.draggable.hits[id] = newTarget;
        newTarget.entity.onDragEnter($event, this.draggable.draggable);
      }
    });

    Object.values(this.draggable.hits).forEach((oldTarget) => {
      const id = oldTarget.entity.vnode.props.id;
      if (!(id in hits)) {
        this.draggable.hits[id] = null;
        delete this.draggable.hits[id];
        oldTarget.entity.onDragLeave($event, this.draggable.draggable);
      }
    });
    return;
  }

  // Droppable

  patchDroppable(newNode) {
    const oldProps = this.vnode?.props?.droppable;
    const newProps = newNode.props.droppable;

    if (oldProps && newProps && !shallowEqual(oldProps, newProps)) {
      patchClasses(
        this.el,
        oldProps.droppableIdentifier || 'droppable',
        newProps.droppableIdentifier || 'droppable',
      );
    }
    if (newProps && !oldProps) {
      addClasses(this.el, newProps.droppableIdentifier);
    }
    if (oldProps && !newProps) {
      removeClasses(this.el, oldProps.droppableIdentifier);
    }
  }

  onDragEnter(event, draggable) {
    this.logger.debug('onDragenter', event, draggable, this.engine);
    const dropProps = this.vnode?.props?.droppable;
    if (!dropProps) return;
    // TODO: Fix conditional drop
    // const conditionResult = dropProps.condition
    //   ? evaluateLocally(
    //       dropProps.condition,
    //       { dragged: this.engine.dragged.props, target: this.vnode.props },
    //       this.vnode.props.context,
    //       this.vnode.props.context.$vars,
    //       this.vnode.props.context.$props,
    //       this.vnode.props.context.$computeds,
    //     )
    //   : true;
    // console.log('conditionResult', conditionResult);
    // if (!conditionResult) return;
    addClasses(this.el, this.vnode.props.droppable.droppableClasses);
    if (dropProps.onDragEnter) {
      this.emitDragDropEvent('dragEnter', dropProps.onDragEnter, event);
    }
  }

  onDragLeave(event, draggable) {
    this.logger.debug('onDragleave', event, draggable);
    const dropProps = this.vnode?.props?.droppable;
    if (!dropProps || !dropProps.condition) return;
    removeClasses(this.el, this.vnode.props.droppable.droppableClasses);
    if (dropProps.onDragLeave) {
      this.emitDragDropEvent('dragLeave', dropProps.onDragLeave, event);
    }
  }

  onDrop(event, draggable) {
    const dropProps = this.vnode?.props?.droppable;
    this.logger.debug(
      'Handle dropped',
      event,
      this.el,
      draggable,
      dropProps,
      dropProps.onDrop,
    );
    if (!dropProps || !dropProps.condition) return;

    // TODO: Calculate correct target position
    const targetPosition = {
      x: -(event.target.offsetWidth / 2) + event.offsetX,
      y: -(-(event.target.offsetHeight / 2) + event.offsetY),
      z: 0,
    };
    removeClasses(this.el, dropProps.droppableClasses);
    addClasses(this.el, dropProps.droppedClasses);
    setTimeout(() => removeClasses(this.el, dropProps.droppedClasses), 1000);

    if (dropProps.onDrop) {
      this.emitDragDropEvent('dropped', dropProps.onDrop, event, {
        targetPosition,
      });
    }
  }

  emitDragDropEvent(
    name: string,
    script: string,
    event: any,
    context: Record<string, any> = {},
  ) {
    this.engine.emit('event', {
      name,
      event,
      script,
      context: {
        dragged: this.engine.dragged.props,
        target: this.vnode.props,
        ...context,
        ...this.vnode.props.context,
        ...this.vnode.props.context.$vars,
        ...this.vnode.props.context.$props,
        ...this.vnode.props.context.$computeds,
      },
      entity: this,
    });
  }
}
