import Vue from 'vue';
import { GlobalPropsNode, Broadcaster } from '@/plugins/global-props';
import { useData, useEnvironment, useAi, useMessage } from '@/composables';

import {
  Route,
  execute,
  RootRoute,
  ObjectId,
  RouteSegment,
  ModuleListItem,
  TNavigationSlot,
  Id,
  DataDocument,
  localRootDataId,
} from '@fillip/api';
import { get, cloneDeep, merge } from 'lodash';
import { nanoid } from 'nanoid/non-secure';

interface CloneOptions {
  avoidDuplicates?: DataDocument[];
  cloneRecursively?: boolean;
  mergeData?: object;
}

const concatMixer = (inputs: Record<string, Broadcaster>) => {
  return {
    value: (Object.values(inputs) as any).flatMap(
      (broadcaster) => broadcaster.getter()?.value || [],
    ),
  };
};

const replacePath = (obj, item) => {
  if (typeof obj !== 'object') return;
  for (const key in obj) {
    if (typeof obj[key] === 'string' && obj[key].trim().startsWith('::')) {
      obj[key] = get(
        item,
        obj[key].replace(/[\s\uFEFF\xA0]+/g, '').slice(2),
        null,
      );
    } else {
      replacePath(obj[key], item);
    }
  }
  return obj;
};

export default Vue.extend({
  name: 'ActionsManager',
  mixins: [GlobalPropsNode],
  inject: ['router', 'videoConferencingManager', 'scene'],
  setup() {
    const {
      pushData,
      getData,
      updateTitle,
      importFlatDataTree,
      updateProperties,
      updatePath,
      updateRoles,
      removeData,
      clearChildren,
      getFlatDataTree,
      patchPaths,
      updatePaths,
      maybeId,
    } = useData();
    const { environment: queryEnvironment } = useEnvironment();
    const ai = useAi();
    const message = useMessage();

    return {
      getData,
      pushData,
      updateTitle,
      updatePath,
      importFlatDataTree,
      updateProperties,
      updateRoles,
      removeData,
      queryEnvironment,
      getFlatDataTree,
      patchPaths,
      updatePaths,
      ai,
      message,
      clearChildren,
      maybeId,
    };
  },
  computed: {
    environment() {
      return {
        ...this.queryEnvironment,
        ai: this.ai,
        router: {
          goto: (route: Route) => this.router.value.goto(route),
          focus: (objectId: ObjectId, participantId: Id = this.$me.id) =>
            this.router.value.focus(objectId, participantId),
          removeFocus: (participantId: Id = this.$me.id) =>
            this.router.value.removeFocus(participantId),
          focusNext: (
            moveToParent: boolean = false,
            participantId: Id = this.$me.id,
          ) => this.router.value.focusNext(moveToParent, participantId),
          focusPrevious: (
            returnToParent: boolean = false,
            participantId: Id = this.$me.id,
          ) => this.router.value.focusPrevious(returnToParent, participantId),
          focusFirst: (participantId: Id = this.$me.id) =>
            this.router.value.focusFirst(participantId),
          focusLast: (participantId: Id = this.$me.id) =>
            this.router.value.focusLast(participantId),
          gotoChild: (segment: RouteSegment, participantId: Id = this.$me.id) =>
            this.router.value.gotoChild(segment, participantId),
          gotoParent: (participantId: Id = this.$me.id) =>
            this.router.value.gotoParent(participantId),
          gotoSibling: (segment: RouteSegment) =>
            this.router.value.gotoSibling(segment),
          openDocumentEditor: (id: string) => {
            this.$dialog.push({ name: 'DocumentEditor', params: { id } });
          },
        },
        videoConferencing: {
          toggleMicrophone: () => {
            this.videoConferencingManager.value.toggleMicrophone();
          },
          toggleCamera: () => {
            this.videoConferencingManager.value.toggleCamera();
          },
        },
        breakoutRooms: {
          addBreakoutRoom: async (targetId: Id, index?: number) => {
            const title = index
              ? this.$t('action.breakoutRooms.room') + index
              : this.$t('action.breakoutRooms.room');
            await this.pushData(targetId, 'text', {
              info: {
                title,
              },
            });
          },
          removeBreakoutRoom: (dataId: Id) => {
            this.removeData(dataId);
          },
          updateBreakoutRoomTitle: (dataId, newTitle) => {
            this.updateTitle(dataId, newTitle);
          },
          participantGotoChild: (
            participantId: string,
            target: RouteSegment,
          ) => {
            this.router.value.gotoChild(target, participantId);
          },
        },
        documents: {
          importFlatDataTree: this.importFlatDataTree,
        },
        notify: (message: string, type = 'info') => {
          this.message(message, type);
        },
        createDocument: async (
          parentId: string,
          tag: string,
          message: string,
          data?: object,
          forceOverwrite: boolean = false,
        ) => {
          const body = {
            info: {
              title: message,
              createdAt: Date.now(),
              createdBy: this.$me.id,
            },
          };
          if (data) Object.assign(body, data);
          return await this.pushData(parentId, tag, body, forceOverwrite);
        },
        createMultipleDocuments: (
          parentId: string,
          tag: string,
          message: string,
          items: any,
          data?: { value: object; identifier: string },
        ) => {
          items = Array.isArray(items) ? items : [items];
          function replaceWithIdentifier(obj, identifier, replacement) {
            if (typeof obj !== 'object') return;
            for (const key in obj) {
              if (obj[key] == identifier) {
                obj[key] = replacement;
              } else {
                replaceWithIdentifier(obj[key], identifier, replacement);
              }
            }
            return obj;
          }
          const createdAt = Date.now();
          items.forEach(async (item) => {
            const body = {
              info: {
                title: get(item, message, null),
                createdAt,
                createdBy: this.$me.id,
              },
            };

            if (data) {
              if (data.identifier && data.value) {
                const newObj = replaceWithIdentifier(
                  cloneDeep(data.value),
                  data.identifier,
                  get(item, data.identifier, null),
                );
                Object.assign(body, newObj);
              } else if (!data.identifier && data.value) {
                Object.assign(body, data.value);
              }
            }
            return await this.pushData(parentId, tag, body);
          });
        },
        createDocuments: (
          parentId: string,
          tag: string,
          message: string,
          items: any,
          data?: object,
        ) => {
          items = Array.isArray(items) ? items : [items];
          const createdAt = Date.now();
          items.forEach(async (item) => {
            const body = {
              info: {
                title: get(item, message, null),
                createdAt,
                createdBy: this.$me.id,
              },
            };

            if (data) {
              const newObj = replacePath(cloneDeep(data), item);
              Object.assign(body, newObj);
            }
            return await this.pushData(parentId, tag, body);
          });
        },
        cloneDocuments: async (
          parentId: string,
          items: any,
          options: CloneOptions = {},
        ) => {
          const getItemIds = (
            itemDocsOrIds: string | DataDocument | (string | DataDocument)[],
          ): string[] => {
            itemDocsOrIds = Array.isArray(itemDocsOrIds)
              ? itemDocsOrIds
              : [itemDocsOrIds];
            return itemDocsOrIds
              .filter((itemDocOrId) => {
                return (
                  (typeof itemDocOrId === 'string' && itemDocOrId) ||
                  (typeof itemDocOrId === 'object' &&
                    typeof itemDocOrId.id === 'string' &&
                    itemDocOrId.id)
                );
              })
              .map((itemDocOrId) => {
                return typeof itemDocOrId === 'object'
                  ? itemDocOrId.id
                  : itemDocOrId;
              });
          };

          items = Array.isArray(items) ? items : [items];
          // console.log('items: ', items);
          const itemIds = getItemIds(items);
          // console.log('itemIds: ', itemIds);
          const createdAt = Date.now();
          let lastImportedDoc = {};

          for (const itemId of itemIds) {
            let itemTree = options.cloneRecursively
              ? this.getFlatDataTree(itemId)
              : [this.getData(itemId)];
            // console.log('itemTree: ', itemTree);

            if (options.mergeData)
              merge(
                itemTree[0],
                replacePath(cloneDeep(options.mergeData), itemTree[0]),
              );
            // console.log('itemTree (after mergeData): ', itemTree);

            if (Array.isArray(options.avoidDuplicates)) {
              options.avoidDuplicates
                .filter(
                  (avoidDoc) =>
                    avoidDoc.info?.clonedFrom.split('@')[0] === itemTree[0].id,
                )
                .forEach((avoidTreeDoc) =>
                  (options.cloneRecursively && itemTree.length > 1
                    ? this.getFlatDataTree(avoidTreeDoc.id)
                    : [avoidTreeDoc]
                  ).filter(
                    (avoidTreeDoc) =>
                      (itemTree = itemTree.filter(
                        (itemTreeDoc) =>
                          itemTreeDoc.id !==
                          avoidTreeDoc.info?.clonedFrom.split('@')[0],
                      )),
                  ),
                );
            }
            // console.log('itemTree (after avoidDuplicates): ', itemTree);

            itemTree = itemTree.map((itemDoc) =>
              merge(itemDoc, {
                info: {
                  createdAt,
                  createdBy: this.$me.id,
                  clonedFrom:
                    itemDoc.id + '@' + (itemDoc?.info?.version || '0.0.0'),
                },
              }),
            );
            if (itemTree?.length) {
              const { result } = await this.importFlatDataTree(
                parentId,
                itemTree,
              );
              lastImportedDoc = result.result[0].id;
            }
            // console.log('itemTree (after merge info object): ', itemTree);

            // console.log('importFlatDataTree: ', parentId, itemTree);
          }
          return lastImportedDoc;
        },
        local: {
          create: async (
            parentId: string,
            tag: string,
            title: string,
            data?: DataDocument,
          ) => {
            const doc = {
              id: 'local:' + (data?.id || nanoid(10)),
              parentId: localRootDataId,
              info: {
                title,
                originalParentId: parentId,
              },
              tag: {
                tag,
              },
              properties: {
                ...(data.properties || {}),
              },
            };
            await this.pushData(localRootDataId, doc.tag.tag, doc, true);
            return doc.id;
          },
          createCopy: async (id: Id) => {
            const doc = this.getData(id);
            const newDoc = cloneDeep(doc);
            newDoc.id = 'local:' + doc.id;
            newDoc.info.originalParentId = doc.parentId;
            newDoc.parentId = localRootDataId;
            await this.pushData(
              localRootDataId,
              newDoc.tag?.tag || 'document',
              newDoc,
              true,
            );
          },
          publish: async (
            value: string | DataDocument,
            options: {
              removeLocally?: boolean;
              targetParentId?: Id;
              createRandomId?: boolean;
            } = {
              removeLocally: true,
            },
          ) => {
            const doc = cloneDeep(
              typeof value === 'string' ? this.getData(value) : value,
            );
            const localId = doc.id;
            const id = options.createRandomId ? nanoid(12) : doc.id.slice(6);

            const parentId =
              options.targetParentId || doc.info.originalParentId;
            if (!parentId) {
              throw new Error('Invalid parent id');
            }

            if (doc.info.originalParentId) delete doc.info.originalParentId;
            doc.parentId = parentId;
            doc.id = id;

            const vmDoc = this.$fiStoreDispatcher.getDocument('data', id);
            if (vmDoc) {
              return this.$fiStoreDispatcher.patchPaths('data', id, doc);
            }

            await this.pushData(parentId, doc.tag?.tag || 'document', doc);
            if (options.removeLocally)
              await this.$fiStoreDispatcher.removeDocument('data', localId);
            this.queryEnvironment.state.requestUpdate();
            return id;
          },
        },
        maybeCreateCollection: async (
          toolDocId: string,
          collectionTemplateId: string,
          collectionParentId: string,
          propPath: string = 'collectionId',
        ) => {
          const toolDoc = this.getData(toolDocId);
          if (
            toolDoc.properties?.[propPath]?.value &&
            this.getData(toolDoc.properties[propPath].value)
          )
            return toolDoc.properties[propPath].value;
          const createdCollectionId = await this.environment.cloneDocuments(
            collectionParentId,
            collectionTemplateId,
            {
              cloneRecursively: true,
              mergeData: {
                info: {
                  title: `${toolDoc.info.title}`,
                },
                properties: {
                  createdFrom: {
                    type: 'id',
                    value: toolDoc.id,
                  },
                },
              },
            },
          );
          await this.updateProperties(toolDocId, {
            [propPath]: { type: 'id', value: createdCollectionId },
          });

          return createdCollectionId;
        },
        updateDocumentTitle: (dataId, newTitle) => {
          if (!dataId) return;
          this.updateTitle(dataId, newTitle);
        },
        updatePath: (
          dataId: string,
          path: string,
          value: Record<string, any>,
          removeIfNull: boolean = true,
        ) => {
          if (!dataId) return;
          return this.updatePath(dataId, path, value, removeIfNull);
        },
        updatePaths: (
          id: Id,
          updates: { path: string; value: Record<string, any> }[],
          removeIfNull: boolean = true,
        ) => {
          if (!id) return;
          return this.updatePaths(id, updates, removeIfNull);
        },
        patchPaths: (dataId: string, paths: Record<string, any>) => {
          if (!dataId) return;
          this.patchPaths(dataId, paths);
        },
        removeDocument: async (id: string) => {
          if (!id) return;
          this.removeData(id);
        },
        removeDocuments: async (docsOrIds: string[] | DataDocument[]) => {
          for (const docOrId of docsOrIds) {
            if (docOrId) {
              this.removeData(this.maybeId(docOrId));
            }
          }
        },
        clearChildren: async (id: string) => {
          this.clearChildren(id);
        },
        updateProperties: async (ids: string | string[], properties: any) => {
          if (!Array.isArray(ids)) ids = [ids];
          ids.forEach((id) => this.updateProperties(id, properties));
        },
        publish: async (targetId, value) => {
          const id = value.id.slice(6);

          const vmDoc = this.$fiStoreDispatcher.getDocument('data', id);
          if (vmDoc)
            return this.$fiStoreDispatcher.patchPaths('data', id, value);

          const newDoc = {
            ...value,
            id,
            parentId: targetId,
          };
          this.importFlatDataTree(targetId, [newDoc]);

          const parent = this.$fiStoreDispatcher.getDocument(
            'data',
            value.parentId,
          );
          const newParent = {
            ...parent,
            list: {
              ...parent.list,
              items: parent.list.items.filter(
                (item: ModuleListItem) => item.id != value.id,
              ),
            },
          };
          await this.$fiStoreDispatcher.setDocument(
            'data',
            parent.id,
            newParent,
          );
          await this.$fiStoreDispatcher.removeDocument('data', value.id);
        },
        toggleScreenshare: () => {
          this.videoConferencingManager.value.toggleScreenshare();
        },
        addToRole: (
          participantId: string,
          targetDocumentId: string,
          role: string,
        ) => {
          const documentRoles = cloneDeep(
            this.queryEnvironment.getRoles(targetDocumentId)(),
          );
          if (!documentRoles) return;

          const participantRoles =
            this.queryEnvironment.getParticipantRoles(participantId)(
              targetDocumentId,
            )();
          if (participantRoles.includes(role)) return;
          const targetRole = documentRoles[role];
          if (!targetRole) return;
          targetRole.documents.push(participantId);
          this.updateRoles(targetDocumentId, documentRoles);
        },
        removeFromRole: (
          participantId: string,
          targetDocumentId: string,
          role: string,
        ) => {
          const documentRoles = cloneDeep(
            this.queryEnvironment.getRoles(targetDocumentId)(),
          );
          if (!documentRoles) return;
          const targetRole = documentRoles[role];
          if (!targetRole) return;
          targetRole.documents = targetRole.documents.filter((id) => {
            return id != participantId;
          });
          this.updateRoles(targetDocumentId, documentRoles);
        },
        RootRoute,
        me: this.$me,
      };
    },
    globalStatusDictionary() {
      return this.$getGlobalProp('statusDictionary', concatMixer).reduce(
        (result, { key, value }) => {
          if (key) result[key] = value;
          return result;
        },
        {},
      );
    },
    canvas() {
      return this.$getGlobalProp('actions.canvas', concatMixer);
    },
    sidebar() {
      return this.$getGlobalProp('actions.sidebar', concatMixer);
    },
  },
  methods: {
    execute(script: string, data: Record<string, any> = {}) {
      execute(
        {
          environment: this.environment,
          data: data,
          vm: this,
          local: {},
          variables: data.$vars ? { ...data.$vars } : {},
          props: data.$props ? { ...data.$props } : {},
          computeds: data.$computeds ? { ...data.$computeds } : {},
        },
        script,
      );
    },
    getNavigationLinks(area: TNavigationSlot) {
      return this.sidebar
        .filter((action) => {
          return action.displaySlots.includes(area);
        })
        .sort((a, b) => {
          if (!b.sortingIndex || b.sortingIndex[area] === undefined) {
            return 1;
          }
          if (!a.sortingIndex || a.sortingIndex[area] === undefined) {
            return -1;
          }
          return a.sortingIndex[area] - b.sortingIndex[area];
        });
    },
  },
  render() {
    return null;
  },
});
