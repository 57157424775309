import { User } from './user';
import { IsString, IsEnum, IsNumber, IsOptional } from 'class-validator';

export enum UploadStatus {
  PENDING = 'pending',
  FINISHED = 'finished',
}

export class PresignedUploadUrlRequest {
  @IsString()
  filename: string;
  @IsString()
  @IsOptional()
  bucketName: string;
}

export class PresignedUploadUrlResponse {
  @IsString()
  uploadUrl: string;
  @IsNumber()
  userId: number;
  @IsNumber()
  fileId: number;
  @IsString()
  bucketName: string;
}

export class FinishedUploadResponse {
  @IsNumber()
  id: number;
  @IsString()
  filename: string;
  @IsString()
  url: string;
  @IsString()
  bucketName: string;
  @IsEnum(UploadStatus)
  uploadFinished: string;
  owner: User;
}
