import { ModuleTypeMeta } from '../../common';

const dragTypes = {
  'x,y': { title: "draggable.dragTypes['x,y']" },
  x: { title: 'draggable.dragTypes.x' },
  y: { title: 'draggable.dragTypes.y' },
  rotation: { title: 'draggable.dragTypes.rotation' },
};

export interface ModuleDraggable {
  isActive: boolean | string;
  dragType: keyof typeof dragTypes;
  inertia: boolean;
  bounds: string;
  droppableIdentifier: string;
  hitTarget: string;
  stayAtPosition?: boolean;
  droppableOverlap: string;
  draggableClasses: string;
  draggedClasses: string;
  // TODO: Add originalClasses when adding "duplicate" option?
  // originalClasses: 'opacity-50';
  onDragStart: string;
  onDragEnd: string;
}

export const ModuleDraggableMeta: ModuleTypeMeta<ModuleDraggable> = {
  icon: 'grip',
  schema: {
    dragTypes,
  },
  default: {
    isActive: ':true',
    dragType: 'x,y',
    inertia: false,
    bounds: '',
    droppableIdentifier: 'droppable',
    hitTarget: '',
    stayAtPosition: false,
    droppableOverlap: '50%',
    draggableClasses: 'cursor-move',
    draggedClasses: 'cursor-dragged',
    // originalClasses: 'opacity-50',
    onDragStart: 'notify(`Started dragging element ${dragged.id}`)',
    onDragEnd: 'notify(`Stopped dragging element ${dragged.id}`)',
  },
};
