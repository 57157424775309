import { DataDocument, DataId, ImportOptions } from '@fillip/api';
import { ref, Ref } from '@vue/composition-api';
import { cloneDeep } from 'lodash';

import { useData } from './useData';

const current = ref(null);
const targetId = ref(null);
const options: Ref<ImportOptions> = ref({});

export function useDataClipboard() {
  const { getData, getFlatDataTree, importFlatDataTree, removeData } =
    useData();

  const setCurrent = (data: DataDocument | DataDocument[]): void => {
    current.value = Array.isArray(data) ? cloneDeep(data) : [cloneDeep(data)];
  };

  const setTarget = (id: DataId | null): void => {
    targetId.value = id;
  };

  const setOptions = (newOptions: ImportOptions): void => {
    options.value = newOptions;
  };

  const copy = (data: DataDocument | DataDocument[]): void => {
    setOptions({ keepIds: false });
    setCurrent(data);
  };

  const copyFromId = (id: DataId, rootOnly: boolean = true): void => {
    setOptions({ keepIds: false });
    rootOnly ? setCurrent(getData(id)) : setCurrent(getFlatDataTree(id));
  };

  const copyFromJson = (json: string): void => {
    setCurrent(JSON.parse(json));
  };

  const cut = async (
    data: DataDocument,
    rootOnly: Boolean = false,
  ): Promise<void> => {
    rootOnly ? setCurrent(data) : setCurrent(getFlatDataTree(data.id));
    setOptions({ keepIds: true });
    return removeData(data.id);
  };

  const cutFromId = (id: DataId, rootOnly: boolean = false): Promise<void> => {
    rootOnly ? setCurrent(getData(id)) : setCurrent(getFlatDataTree(id));
    setOptions({ keepIds: true });
    return removeData(id);
  };

  const paste = async (targetId: DataId): Promise<void> => {
    if (!targetId || !current.value || current.value.length < 1) return null;
    await importFlatDataTree(targetId, current.value, options.value);
    clear();
  };

  const clear = (): void => {
    current.value = null;
    targetId.value = null;
    options.value = {};
  };

  const toJSON = (): string => {
    return JSON.stringify(current.value, null, 2);
  };

  return {
    current,
    targetId,
    options,
    copy,
    copyFromId,
    setTarget,
    setOptions,
    copyFromJson,
    cut,
    cutFromId,
    paste,
    clear,
    toJSON,
  };
}
