import {
  Location3DType,
  Vector3Type,
  TypeMeta,
  ModuleTypeMeta,
} from '../../common';

const defaultCameraPosition = {
  position: {
    x: 0,
    y: 0,
    z: 1000,
  },
  rotation: {
    x: 0,
    y: 0,
    z: 0,
  },
  scale: {
    x: 1,
    y: 1,
    z: 1,
  },
};

type CameraTypeKeys =
  | 'camera.fixed'
  | 'camera.auto-zoom'
  | 'camera.pan-and-zoom'
  | 'camera.orbit'
  | 'camera.panorama'
  | 'camera.first-person';

interface CameraType<Key extends CameraTypeKeys> {
  type: Key;
  controlIfLoaded?: boolean;
}

interface CameraTypeMeta<T extends CameraType<CameraTypeKeys>>
  extends TypeMeta<T> {
  type: T['type'];
  name?: string;
  icon?: string;
}
export interface CameraPanorama extends CameraType<'camera.panorama'> {
  azimuthMin?: number | string;
  azimuthDefault?: number;
  azimuthMax?: number | string;
  polarMin?: number;
  polarDefault?: number;
  polarMax?: number;
  panoramaZoomMin: number;
  panoramaZoomDefault: number;
  panoramaZoomMax: number;
}
export const CameraPanoramaMeta: CameraTypeMeta<CameraPanorama> = {
  type: 'camera.panorama',
  name: 'Panorama',
  default: {
    type: 'camera.panorama',
    // TODO: Fix when improving the camera editor
    // azimuthMin: -Infinity,
    azimuthMin: -180,
    azimuthDefault: 0,
    // azimuthMax: Infinity,
    azimuthMax: 180,
    polarMin: 0,
    polarDefault: 90,
    polarMax: 180,
    panoramaZoomMin: 1,
    panoramaZoomDefault: 1,
    panoramaZoomMax: 4,
  },
};

export interface CameraOrbit extends CameraType<'camera.orbit'> {
  orbitMinRadius: number;
  orbitMaxRadius: number;
  zoomMinDistance: number;
  zoomMaxDistance: number;
}

export const CameraOrbitMeta: CameraTypeMeta<CameraOrbit> = {
  type: 'camera.orbit',
  name: 'Orbit',
  default: {
    type: 'camera.orbit',
    orbitMinRadius: null,
    orbitMaxRadius: null,
    zoomMinDistance: null,
    zoomMaxDistance: null,
  },
};

export interface CameraPanAndZoom extends CameraType<'camera.pan-and-zoom'> {
  zoomMinDistance: number;
  zoomMaxDistance: number;
}

export const CameraPanAndZoomMeta: CameraTypeMeta<CameraPanAndZoom> = {
  type: 'camera.pan-and-zoom',
  name: 'Pan and Zoom',
  default: {
    type: 'camera.pan-and-zoom',
    zoomMinDistance: 500,
    zoomMaxDistance: 2000,
  },
};

export interface CameraAutoZoom extends CameraType<'camera.auto-zoom'> {
  zoomMinDistance: number;
  zoomMaxDistance: number;
  limitZoomForScreenSize?: boolean;
}

export const CameraAutoZoomMeta: CameraTypeMeta<CameraAutoZoom> = {
  type: 'camera.auto-zoom',
  name: 'Auto Zoom',
  default: {
    type: 'camera.auto-zoom',
    zoomMinDistance: 1000,
    zoomMaxDistance: 0,
    limitZoomForScreenSize: false,
  },
};

export interface CameraFirstPerson extends CameraType<'camera.first-person'> {
  // TODO: Add options
}

export const CameraFirstPersonMeta: CameraTypeMeta<CameraFirstPerson> = {
  type: 'camera.first-person',
  name: 'First Person',
  default: {
    type: 'camera.first-person',
  },
};

export interface CameraFixed extends CameraType<'camera.fixed'> {
  position?: Location3DType;
}

export const CameraFixedMeta: CameraTypeMeta<CameraFixed> = {
  type: 'camera.fixed',
  name: 'Fixed',
  default: {
    type: 'camera.fixed',
  },
};

export type ModuleCamera =
  | CameraOrbit
  | CameraPanAndZoom
  | CameraFixed
  | CameraAutoZoom
  | CameraPanorama
  | CameraFirstPerson;

export interface ModuleCameraTypeMeta extends ModuleTypeMeta<ModuleCamera> {
  types: {
    [key in CameraTypeKeys]: CameraTypeMeta<CameraType<key>>;
  };
}

export const ModuleCameraMeta: ModuleCameraTypeMeta = {
  types: {
    'camera.fixed': CameraFixedMeta,
    'camera.auto-zoom': CameraAutoZoomMeta,
    'camera.pan-and-zoom': CameraPanAndZoomMeta,
    'camera.orbit': CameraOrbitMeta,
    'camera.first-person': CameraFirstPersonMeta,
    'camera.panorama': CameraPanoramaMeta,
  },
  schema: {
    defaultCameraPosition,
  },
  default: CameraAutoZoomMeta.default,
  icon: 'joystick',
};
