import Vue from 'vue';
import { nanoid } from 'nanoid/non-secure';
import { ElementComponents } from '@/features/main/elements';
import { ModelComponents } from '@/features/main/models';
import { Entity } from '../../core/entity';
import { patchClasses } from '../classes';
import {
  Id,
  ModuleModel,
  ModuleElement,
  useLogger,
  Logger,
  LogLevels,
} from '@fillip/api';

export type ComponentType = 'element' | 'model';

const Components = {
  ...ElementComponents,
  ...ModelComponents,
};

export class VueComponentInstance {
  private loaded: boolean = false;
  public entityId: Id;
  public componentId: Id = null;
  public type: ComponentType;
  // public componentType: ModuleElement['type'] | ModuleModel['type'];

  public props: ModuleElement | ModuleModel;
  public vueInstance: Vue = null;
  private entity: Entity;
  private entityProps: Record<string, any>;

  private logger: Logger;

  constructor(
    entityId: Id,
    type: ComponentType,
    // componentType: ModuleElement['type'] | ModuleModel['type'],
    props: ModuleElement | ModuleModel,
    entity: Entity,
  ) {
    this.logger = useLogger(
      LogLevels.WARN,
      LogLevels.NONE,
      `ComponentInstance::${entityId}`,
    );

    this.entityId = entityId;
    this.type = type;
    this.props = props;
    this.entity = entity;
  }

  private get componentType() {
    return this.props.type;
  }

  private get activeComponent() {
    return Components[this.componentType];
  }

  private updateEntityProps(props) {
    this.entityProps.modules[this.type] = props;
    this.props = props;
  }

  public async load(settings: Record<string, any> = {}) {
    if (this.loaded) return;

    this.logger.debug('startLoad', settings);

    this.entity.engine.startLoad();

    if (this.componentId)
      throw new Error(
        `Assertion Error: Component for ${this.componentType} ${this.entityId} of  already loaded`,
      );

    this.componentId = nanoid();

    if (!this.activeComponent)
      throw new Error('Missing component for type ' + this.type);

    this.entityProps = {
      id: this.entityId,
      modules: { [this.type]: this.props },
    };
    this.logger.debug('beforeCreate', this.entityProps);
    this.vueInstance = await this.entity.engine.factory.create(
      this.componentId,
      this.activeComponent,
      this.entityProps,
      this.entity.handleEvent.bind(this.entity),
    );

    this.logger.debug('componentCreated', this.vueInstance);

    if (this.type == 'element') {
      this.vueInstance.$el.id = 'el_-_' + this.entityId.replace(':', '_-_');
      patchClasses(this.vueInstance.$el as HTMLElement, '', settings.classes);
      this.entity.el.appendChild(this.vueInstance.$el);
      if (this.entity.el.firstChild) {
        this.entity.el.insertBefore(
          this.vueInstance.$el,
          this.entity.el.firstChild,
        );
      } else {
        this.entity.el.appendChild(this.vueInstance.$el);
      }
    }

    if (this.type == 'model') {
      this.entity.content.add((this.vueInstance as any).$object3D);
      // (this.modelVueInstance as any).$object3D.computeBoundingBox();
    }

    this.loaded = true;
    this.entity.engine.finishLoad();
    this.logger.debug('finishLoad');
    return this;
  }

  public update(props: ModuleElement | ModuleModel) {
    this.logger.debug('update', props);
    this.entity.engine.startLoad();
    this.updateEntityProps(props);
    this.vueInstance.$nextTick().then(() => this.entity.engine.finishLoad());
    this.logger.debug('finishUpdate', props);
    return this;
  }

  public unload() {
    this.logger.debug('unload');

    if (this.type == 'element') {
      this.vueInstance.$el.remove();
    }
    if (this.type == 'model') {
      this.entity.content.remove((this.vueInstance as any).$object3D);
    }

    this.entity.engine.factory.remove(this.componentId);
    this.vueInstance = null;
    this.props = null;
    this.componentId = null;
    this.logger.debug('finishUnload');
    return null;
  }

  static patch(
    id: Id,
    componentType: ComponentType,
    oldProps: ModuleElement | ModuleModel,
    newProps: ModuleElement | ModuleModel,
    componentInstance: VueComponentInstance,
    entity: Entity,
  ) {
    // No component in sight, nothing to do here
    if (!newProps && !oldProps && !componentInstance) {
      return;
    }

    // TODO: Potential optimisation -> Benchmark
    // if (newProps && this.model && deepEqual(newProps, oldProps)) return;

    // Type didn't change, update the props
    if (componentInstance && oldProps?.type == newProps?.type) {
      return componentInstance.update(newProps);
    }

    // Anything else changed, so we need to unload before we load again
    if (componentInstance) {
      componentInstance.unload();
      return null;
    }

    // If we have a new model, load it
    if (newProps) {
      return new this(id, componentType, newProps, entity);
    }

    // Everything is unloaded, allow parent to remove reference
    return null;
  }
}
