import { computed } from '@vue/composition-api';
import { subject } from '@casl/ability';
import { useFillip } from '../globals/useFillip';

export function usePermissions() {
  const { $me, $user, $community, can } = useFillip();

  const isGlobalAdmin = computed(() => {
    if (!$me.value) return;
    return can('editCommunityAsGlobalAdmin', subject('User', $user.value));
  });

  const canEditCommunity = computed(() => {
    if (!$me.value) return;
    return (
      can('editCommunity', subject('Participant', $me.value)) ||
      can('editCommunityAsCreator', subject('Community', $community.value)) ||
      isGlobalAdmin.value
    );
  });

  const canSeePrototype = computed(() => {
    if (!$me.value) return;
    return can('seePrototype', subject('Participant', $me.value));
  });

  const canSwitchPrototype = computed(() => {
    if (!$me.value) return;
    return can('switchPrototype', subject('Participant', $me.value));
  });

  const canSwitchCommunity = computed(() => {
    if (!$me.value) return;
    return can('switchCommunity', subject('User', $user.value));
  });

  const canEditRoles = computed(() => {
    if (!$me.value) return;
    return (
      can('editCommunityRoles', subject('Community', $community.value)) ||
      can('editCommunityRolesAsAdmin', subject('Participant', $me.value)) ||
      isGlobalAdmin
    );
  });

  return {
    canEditCommunity,
    canSeePrototype,
    canSwitchPrototype,
    canSwitchCommunity,
    isGlobalAdmin,
    canEditRoles,
  };
}
