import { Location3DType, Vector3Type } from '@fillip/api';
// TODO: Check which helpers are still used / whcih are duplicated in Object3DAnimator
import { Vector3, Quaternion, Euler, Matrix4, Box3, Object3D } from 'three';
import { Size3D } from '@/features/main/engine/systems/size';
export function Location3D(
  x = 0,
  y = 0,
  z = 0,
  rx = 0,
  ry = 0,
  rz = 0,
  sx = 1,
  sy = 1,
  sz = 1,
) {
  return {
    position: {
      x,
      y,
      z,
    },
    rotation: {
      x: rx,
      y: ry,
      z: rz,
    },
    scale: {
      x: sx,
      y: sy,
      z: sz,
    },
  };
}

export const origin = Location3D(0, 0, 0, 0, 0, 0, 1, 1, 1);

export function addDefaults(location) {
  return {
    position: {
      ...origin.position,
      ...(location.position || {}),
    },
    rotation: {
      ...origin.rotation,
      ...(location.rotation || {}),
    },
    scale: {
      ...origin.scale,
      ...(location.scale || {}),
    },
  };
}

export function applyToObject3D(location, object3d) {
  // TODO: maybe check for equalities within epsilon
  object3d.position.set(
    location.position.x,
    location.position.y,
    location.position.z,
  );

  object3d.rotation.set(
    location.rotation.x,
    location.rotation.y,
    location.rotation.z,
    // 'YXZ',
  );

  object3d.scale.set(location.scale.x, location.scale.y, location.scale.z);
}

export function toFlatObject(location) {
  return {
    positionX: location.position.x,
    positionY: location.position.y,
    positionZ: location.position.z,
    rotationX: location.rotation.x,
    rotationY: location.rotation.y,
    rotationZ: location.rotation.z,
    scaleX: location.scale.x,
    scaleY: location.scale.y,
    scaleZ: location.scale.z,
  };
}

export function getWorldLocationFromObject3D(object3D) {
  const position = new Vector3();
  object3D.getWorldPosition(position);
  const worldQuaternion = new Quaternion();
  const euler = new Euler().setFromQuaternion(
    object3D.getWorldQuaternion(worldQuaternion),
    // 'YXZ',
  );
  const scale = object3D.getWorldScale(new Vector3());
  // euler.reorder('YXZ');
  return Location3D(
    position.x,
    position.y,
    position.z,
    euler.x,
    euler.y,
    euler.z,
    scale.x,
    scale.y,
    scale.z,
  );
}

export function simplifyRotation(target, source) {
  ['x', 'y', 'z'].forEach((axis) => {
    while (Math.abs(target.rotation[axis] - source.rotation[axis]) > Math.PI) {
      target.rotation[axis] +=
        Math.PI * 2 * (target.rotation[axis] > source.rotation[axis] ? -1 : 1);
    }
  });
}

export function toMatrix(location) {
  return new Matrix4()
    .makeTranslation(
      location.position.x,
      location.position.y,
      location.position.z,
    )
    .multiply(
      new Matrix4().makeScale(
        location.scale.x,
        location.scale.y,
        location.scale.z,
      ),
    )
    .multiply(
      new Matrix4().makeRotationFromEuler(
        new Euler(
          location.rotation.x,
          location.rotation.y,
          location.rotation.z,
          'XYZ',
        ),
      ),
    );
}

export function fromMatrix(matrix) {
  const position = new Vector3().setFromMatrixPosition(matrix);
  const euler = new Euler().setFromRotationMatrix(matrix);
  const scale = new Vector3().setFromMatrixScale(matrix);
  // euler.reorder('YXZ');

  return Location3D(
    position.x,
    position.y,
    position.z,
    euler.x,
    euler.y,
    euler.z,
    scale.x,
    scale.y,
    scale.z,
  );
}

export function sizeFromVector3({ x, y, z }: Vector3): Size3D {
  return {
    width: x,
    height: y,
    depth: z,
  };
}

export function pointFromVector3({ x, y, z }: Vector3): Vector3Type {
  return {
    x,
    y,
    z,
  };
}

export function quaternionRotationFromEuler({ x, y, z }): {
  x: number;
  y: number;
  z: number;
  w: number;
} {
  const euler = new Euler(x, y, z);
  const quaternion = new Quaternion().setFromEuler(euler);
  return {
    x: quaternion.x,
    y: quaternion.y,
    z: quaternion.z,
    w: quaternion.w,
  };
}

export function eulerRotationFromQuaternion({
  x,
  y,
  z,
  w,
}: {
  x: number;
  y: number;
  z: number;
  w: number;
}): {
  x: number;
  y: number;
  z: number;
} {
  const quaternion = new Quaternion(x, y, z, w);
  const euler = new Euler().setFromQuaternion(quaternion);
  return {
    x: euler.x,
    y: euler.y,
    z: euler.z,
  };
}

export const hasBox3Size = (box: Box3) => {
  if (!box) return false;
  const { min, max } = box;
  return min.x || min.y || min.z || max.x || max.y || max.z;
};

export const getObjectLocation = (object: Object3D): Location3DType => ({
  position: object.position,
  rotation: object.rotation,
  scale: object.scale,
});
