import ActivateToggleEditor from '../components/ActivateToggleEditor.vue';
import ConditionalToggleEditor from '../components/ConditionalToggleEditor.vue';
import ModuleHeader from '../components/ModuleHeader.vue';
import EditorOption from '../components/EditorOption.vue';
import EditorSection from '../components/EditorSection.vue';
import DimensionsEditor from '../components/DimensionsEditor.vue';
import DraggableArrayInput from '../components/DraggableArrayInput.vue';
import Location3dEditor from '../components/Location3dEditor.vue';
import EditorContainer from '../components/EditorContainer.vue';

import InputNumberSlider from '@/components/input/number/InputNumberSlider.vue';
import InputBoolean from '@/components/input/boolean/InputBoolean.vue';
import InputBooleanCheckbox from '@/components/input/boolean/InputBooleanCheckbox.vue';
import InputColor from '@/components/input/color/InputColor.vue';
import InputScript from '@/components/input/script/InputScript.vue';
import InputString from '@/components/input/string/InputString.vue';
import InputDocuments from '@/components/input/documents/InputDocuments.vue';
import InputSelect from '@/components/input/select/InputSelect.vue';
import InputIcon from '@/components/input/icon/InputIcon.vue';
import InputId from '@/components/input/id/InputId.vue';
import InputNumber from '@/components/input/number/InputNumber.vue';
import InputMedia from '@/components/input/media/InputMedia.vue';
import InputTag from '@/components/input/tag/InputTag.vue';

import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import PrimitiveTemplateSelector from '@/components/primitive-editor/types/PrimitiveTemplateSelector.vue';
import TreeTemplateSelector from '@/components/primitive-editor/types/TreeTemplateSelector.vue';

export const BaseModuleEditor = {
  components: {
    ActivateToggleEditor,
    ConditionalToggleEditor,
    ModuleHeader,
    EditorOption,
    EditorSection,
    DimensionsEditor,
    DraggableArrayInput,
    Location3dEditor,
    EditorContainer,
    InputId,
    InputColor,
    InputScript,
    InputString,
    InputDocuments,
    InputBoolean,
    InputBooleanCheckbox,
    InputNumberSlider,
    InputSelect,
    InputIcon,
    InputNumber,
    InputMedia,
    InputTag,
    PrimitiveDataSelector,
    PrimitiveTemplateSelector,
    TreeTemplateSelector,
  },
  props: {
    mountExpanded: {
      type: Boolean,
      default: false,
    },
    alwaysActive: {
      type: Boolean,
      default: false,
    },
    forbidEditing: {
      type: Boolean,
      default: false,
    },
  },
};
