import { Object3DAnimator } from './../systems/animation';
import { Engine } from './engine';
import { Size2D } from './../systems/size';
import { updateCameraAfterViewportResize } from './../systems/controls';
import { Entity, EntityObject3D } from './entity';
import { ModuleCamera, deepEqual, Location3DType, Id } from '@fillip/api';
import { VNode } from '../vnode';
import gsap from 'gsap';
import * as Location3D from '@/utils/location3D';

import {
  Clock,
  PerspectiveCamera,
  Object3D,
  Box3,
  Box3Helper,
  Color,
} from 'three';
import {
  IBaseControls,
  PanoramaControls,
  PanZoomControls,
  OrbitControls,
  FixedControls,
  AutoZoomControls,
} from '../systems/controls/index';

export class CameraEntity extends Entity {
  public sceneCamera: ModuleCamera;
  public controls: null | IBaseControls;
  public vnode: VNode;
  public clock: Clock = null;
  public camera: PerspectiveCamera;
  public focusedObject: Box3 | Object3D;
  public parentId: string;

  public cameraTarget: EntityObject3D;
  public cameraIsAtTarget: boolean = false;
  public cameraIsMoving: boolean = false;

  constructor(
    engine: Engine,
    id: Id,
    camera: PerspectiveCamera = new PerspectiveCamera(),
  ) {
    super(engine, id, camera);
    this.camera = camera;

    this.cameraTarget = new Object3D() as EntityObject3D;
    this.cameraTarget.name = `cameraTarget:${id}`;
    this.cameraTarget.entity = this;
    this.target.add(this.cameraTarget);
    // const helper = new Box3().setFromObject(this.cameraTarget);
    // this.cameraTarget.add(new Box3Helper(helper, new Color('green')));
    this.contentIsAtCarrier = false;
  }

  updateCamera(sceneCamera: ModuleCamera, hasSceneSwitch: boolean = false) {
    const hasNewCamera =
      !this.sceneCamera || this.sceneCamera.type != sceneCamera.type;
    if (!this.parent) return;
    const hasParentChanged = this.parent.id != this.parentId;
    this.parentId = this.parent.id;

    const haveSettingsChanged =
      hasNewCamera || !deepEqual(sceneCamera, this.sceneCamera);

    this.logger.debug(
      'updateCamera',
      hasNewCamera,
      haveSettingsChanged,
      hasParentChanged,
      this.parent.id,
      this.parentId,
      this,
    );
    this.sceneCamera = sceneCamera;
    if (hasNewCamera || hasSceneSwitch) {
      if (this.controls) {
        this.controls.dispose();
      }
      this.controls = this.createControls(sceneCamera.type, sceneCamera);
    }
    if (
      hasNewCamera ||
      hasSceneSwitch ||
      haveSettingsChanged ||
      hasParentChanged
    ) {
      // this.logger.debug('Update camera', this.sceneCamera);
      this.controls.updateCamera(
        this.sceneCamera,
        this.parent.boundingBox,
        hasParentChanged || hasSceneSwitch,
      );
    } else {
      this.controls.checkCameraPosition();
    }
  }

  public getCamera() {
    return this.camera as unknown as PerspectiveCamera;
  }

  setViewportSize(size: Size2D) {
    if (this.camera) {
      updateCameraAfterViewportResize(this.getCamera(), size);
    }
  }

  zoomToFit(focusedObject: Box3 | Object3D) {
    this.focusedObject = focusedObject;
    this.controls.zoomToFit(focusedObject);
  }

  enableControls() {
    this.controls.enable();
  }

  disableControls() {
    this.controls.disable();
  }

  startCarrierTransitions() {
    this.startCameraTransitions();
    super.startCarrierTransitions();
  }

  startCameraTransitions() {
    this.logger.debug(
      'startCameraTransitions',
      this.cameraIsAtTarget,
      this.cameraIsMoving,
      this.cameraTarget,
      Object3DAnimator.fromObject3D(this.cameraTarget),
      gsap.isTweening(this.contentTransform),
    );
    if (this.cameraIsAtTarget) return;

    if (this.cameraIsMoving || gsap.isTweening(this.contentTransform)) {
      this.logger.debug('Camera is moving');
    }

    this.cameraIsMoving = true;
    this.controls.disable();

    const onComplete = () => {
      this.cameraIsMoving = false;
      this.logger.debug('Animation complete');
      this.setCameraReachedTarget();
    };
    const onInterrupt = (event) => {
      // TODO: Why does this happen so often?
      this.logger.debug('Camera transition interrupted', event);
      this.cameraIsMoving = false;
      this.controls.enable();
      this.engine.render();
    };

    const to = {
      ...Object3DAnimator.fromObject3D(this.cameraTarget),
      onInterrupt,
    };

    this.logger.debug(
      'transitionCameraCarrier',
      to,
      this.getContentLocation(),
      this.contentTransform,
    );

    // TODO: Replace with transition from camera settings
    const transition = 'default';

    this.transitionObject(
      this.contentTransform,
      to,
      transition,
      null,
      onComplete,
    );
  }

  setCameraTarget(location: Location3DType, sync: boolean = false) {
    const { position, rotation, scale } = location;

    this.cameraTarget.position.set(position.x, position.y, position.z);
    this.cameraTarget.rotation.set(rotation.x, rotation.y, rotation.z, 'YXZ');
    this.cameraTarget.scale.set(scale.x, scale.y, scale.z);

    this.cameraTarget.updateMatrix();
    this.cameraIsAtTarget = sync ? true : false;
  }

  getCameraTargetLocation(): Location3DType {
    return Location3D.getObjectLocation(this.cameraTarget);
  }

  setCameraReachedTarget() {
    if (this.cameraIsAtTarget) return;
    this.logger.debug('setCameraReachedTarget');
    this.cameraIsAtTarget = true;
    this.controls.enable();
    this.engine.render();
  }

  createControls = (controlType: string, sceneCamera: ModuleCamera) => {
    switch (controlType) {
      case 'camera.orbit':
        return new OrbitControls(this, sceneCamera);
      case 'camera.panorama':
        return new PanoramaControls(this, sceneCamera);
      case 'camera.auto-zoom':
        return new AutoZoomControls(this, sceneCamera);
      case 'camera.fixed':
        return new FixedControls(this, sceneCamera);
      case 'camera.pan-and-zoom':
        return new PanZoomControls(this, sceneCamera);
    }
  };

  delete() {
    this.cameraTarget.removeFromParent();
    super.delete();
  }
}
