import { MediaEntity } from '../../../backend-lib/src/media/media.entity';
import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';

import {
  FinishedUploadResponse,
  PresignedUploadUrlResponse,
  UploadFetchFailed,
} from '@fillip/api';

export class FillipVueMediaClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('media', root);
  }

  public async uploadMedia(
    file: File,
    bucketName: string = 'fillip',
  ): Promise<FinishedUploadResponse> {
    const fileName = file.name;
    const { uploadUrl, fileId } = await this.preparePresignedUpload(
      fileName,
      bucketName,
    );
    try {
      await this.uploadToPresignedUrl(uploadUrl, file);
      return this.finishUpload(fileId);
    } catch (error) {
      if (fileId) await this.cancelUpload(fileId);
      this.handleException(error);
    }
  }

  async preparePresignedUpload(
    filename: string,
    bucketName: string,
  ): Promise<PresignedUploadUrlResponse> {
    try {
      const { data } = await this.axios.post(
        `${this.config.apiUrl}/media/upload`,
        {
          filename,
          bucketName,
        },
      );
      return data as PresignedUploadUrlResponse;
    } catch (error) {
      this.handleException(error);
    }
  }

  async finishUpload(fileId: number): Promise<FinishedUploadResponse> {
    try {
      const { data } = await this.axios.patch(
        `${this.config.apiUrl}/media/upload/finish/${fileId}`,
      );
      return data as FinishedUploadResponse;
    } catch (error) {
      this.handleException(error);
    }
  }

  async uploadToPresignedUrl(uploadUrl: string, file: File) {
    const fetchResult = await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    // This removes the irritating "Fetch failed to load" log in chrome
    // caused by the fetch result having an empty response body
    await fetchResult.text();

    if (!fetchResult.ok) {
      console.warn('Error while uploading media: ', fetchResult.statusText);
      throw UploadFetchFailed();
    }
  }

  async cancelUpload(id) {
    try {
      await this.axios.patch(`${this.config.apiUrl}/media/upload/cancel/${id}`);
    } catch (error) {
      this.handleException(error);
    }
  }

  public async getAllMyMedia(): Promise<any> {
    try {
      const { data } = await this.axios.get(`${this.config.apiUrl}/media/me`);
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async getAllMediaInBucket(bucketName: string): Promise<MediaEntity[]> {
    try {
      const { data } = await this.axios.get(
        `${this.config.apiUrl}/media/${bucketName}`,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }

  public async getMyMediaInBucket(bucketName: string): Promise<MediaEntity[]> {
    try {
      const { data } = await this.axios.get(
        `${this.config.apiUrl}/media/${bucketName}/me`,
      );
      return data;
    } catch (error) {
      this.handleException(error);
    }
  }
}
