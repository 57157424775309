






















import PrimitiveDataSelector from '@/components/primitive-editor/types/PrimitiveDataSelector.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'InputDocuments',
  components: {
    PrimitiveDataSelector,
  },
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: [Object, Array],
      default: () => {
        return { option: 'exclude', tags: 'template' };
      },
    },
  },
});
