import { Scene, WebGLRenderer, sRGBEncoding } from 'three';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { RenderContext } from './RenderContext.js';

import gsap from 'gsap';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import { useTrackPerformance, useLogger, LogLevels } from '@fillip/api';

const logger = useLogger(LogLevels.INFO, LogLevels.NONE, 'renderManager');
const trackPerformance = useTrackPerformance(false, 'renderManager');

export class RenderManager {
  constructor(canvas, sceneryCanvas, viewportChanged = () => {}) {
    this.canvas = canvas;
    this.sceneryCanvas = sceneryCanvas;
    const { width, height } = canvas.getBoundingClientRect();
    this.width = width;
    this.height = height;
    this.viewportChanged = viewportChanged;
    this.targetFramerate = this.currentFramerate = 30;
    this.delays = 0;
    this.isVisible = false;
    this.manualTickInterval = null;

    this.scene = new Scene();

    this.renderers = new Map();

    this.renderers.set(
      'css3D',
      new RenderContext('css3D', CSS3DRenderer, this.canvas, this.scene, {}),
    );

    this.renderers.set(
      'webGL',
      new RenderContext(
        'webGL',
        WebGLRenderer,
        this.sceneryCanvas,
        this.scene,
        {
          antialias: true,
          powerPreference: 'high-performance',
          alpha: true,
        },
      ),
    );

    for (const [name, renderer] of this.renderers) {
      renderer.setSize(width, height);
      renderer.canvas.appendChild(renderer.renderEngine.domElement);
      if (renderer.name == 'webGL') {
        renderer.renderEngine.setPixelRatio(window.devicePixelRatio);
        renderer.renderEngine.outputEncoding = sRGBEncoding;
        // renderer.renderEngine.setClearColor(0xffffff, 1.0);
      }
    }

    this.viewportChanged({ width, height });

    this.onRenderCallbacks = new Map();
    this.shouldRenderGlobally = false;
    const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
    this.resizeObserver = new ResizeObserver((entries) => {
      const containerSize = entries.find((entry) => entry.target == canvas);
      if (!containerSize) return;
      this.onWindowResize();
    });
    this.resizeObserver.observe(canvas);

    this.onWindowResize = () => {
      const { width, height } = this.canvas.getBoundingClientRect();
      this.width = width;
      this.height = height;
      this.viewportChanged({ width, height });

      for (const [, renderer] of this.renderers) {
        renderer.setSize(width, height);
        this.render('all', true);
      }
    };

    window.addEventListener('resize', this.onWindowResize);

    document.addEventListener(
      'visibilitychange',
      this.onVisibilityChanged.bind(this),
    );

    gsap.ticker.fps(this.targetFramerate);
    gsap.ticker.add(this.onTick.bind(this));
  }

  setCamera(camera) {
    if (!camera) {
      throw new Error('Invalid camera!');
    }
    this.camera = camera;
    for (const [, renderer] of this.renderers) {
      renderer.setCamera(camera);
    }
  }

  render(context, immediate = false) {
    // Use immediate option to triger a rerender outside the regular render loop.
    // This is helpful to not block the rendering loop if more expensive rendering is needed,
    // i.e. to initially render a scene
    if (!context || context == 'all') {
      if (immediate) {
        for (const [, renderer] of this.renderers) {
          renderer.doRender();
        }
      } else {
        this.shouldRenderGlobally = true;
      }
    } else {
      if (!this.renderers.has(context)) {
        throw new Error(`Unknown rendering context ${context}`);
      }
      const renderer = this.renderers.get(context);
      if (immediate) {
        renderer.doRender();
      } else {
        renderer.shouldRender = true;
      }
    }
  }

  setFramerate(fps) {
    this.currentFramerate = fps;
    gsap.ticker.fps(this.currentFramerate);
  }

  onVisibilityChanged() {
    this.isVisible = document.visibilityState === 'visible';
    if (this.isVisible) {
      logger.debug('App is now visible');
      gsap.ticker.lagSmoothing(500, 33);
      if (this.manualTickInterval) {
        clearInterval(this.manualTickInterval);
        this.manualTickInterval = null;
      }
    } else {
      logger.debug('App is now invisible');
      gsap.ticker.lagSmoothing(0);
      this.manualTickInterval = setInterval(this.onTick.bind(this), 100);
    }
  }

  onTick(time, deltaTime, frame) {
    trackPerformance.start('onTick');

    this.handleLoopDelays(deltaTime, frame);

    // Handle GSAP Animations

    trackPerformance.start('onTick::GSAP');

    let child = gsap.globalTimeline._first;

    while (child && !this.shouldRenderGlobally) {
      if (child.isActive()) {
        // TODO: Potentially split by renderer
        // Either by splitting the timeline or checking for a new property like child.renderContext
        this.shouldRenderGlobally = true;
      }
      child = child._next;
    }
    trackPerformance.stop('onTick::GSAP');

    trackPerformance.start('onTick::callbacks');
    if (this.onRenderCallbacks.size > 0) {
      this.onRenderCallbacks.forEach((cb) => {
        const result = cb(time, deltaTime, frame);
        if (result && !this.shouldRenderGlobally) {
          this.shouldRenderGlobally = true;
        }
      });
    }
    trackPerformance.stop('onTick::callbacks');

    trackPerformance.start('onTick::renderers');
    for (const [, renderer] of this.renderers) {
      const shouldRender = renderer.onTick(time, deltaTime, frame);
      if (shouldRender || this.shouldRenderGlobally) {
        renderer.doRender();
      }
    }
    trackPerformance.stop('onTick::renderers');
    trackPerformance.stop('onTick');
    trackPerformance.log();
    trackPerformance.reset();

    this.shouldRenderGlobally = false;
  }

  onRender(handle, callback, context) {
    if (!context || context == 'all') {
      this.onRenderCallbacks.set(handle, callback);
    } else {
      if (!this.renderers.has(context)) {
        throw new Error(`Unknown rendering context ${context}`);
      }
      this.renderers.get(context).onRender(handle, callback);
    }
  }

  removeOnRender(handle, context) {
    if (!context || context == 'all') {
      this.onRenderCallbacks.delete(handle);
      return;
    }
    if (!this.renderers.has(context)) {
      throw new Error(`Unknown rendering context ${context}`);
    }
    this.renderers.get(context).removeOnRender(handle);
  }

  resetOnRender() {
    this.onRendererCallbacks = new Map();
  }

  handleLoopDelays(deltaTime, frame) {
    if (!this.isVisible) return;

    trackPerformance.start('onTick::handleLoopDelay');
    const deltaRatio = gsap.ticker.deltaRatio(this.currentFramerate);
    if (deltaRatio > 1.5) {
      this.delays += 1;
      if (this.delays > 2) {
        logger.debug(
          `Repeated delay in loop. Frame ${frame}, deltaTime: ${deltaTime} ms, deltaRatio: ${deltaRatio}`,
        );
        // this.setFramerate(this.targetFramerate / deltaRatio);
      }
    } else if (
      deltaRatio < 1.2 &&
      this.delays > 0
      // this.currentFramerate < this.targetFramerate
    ) {
      logger.debug('Delay over', deltaRatio);
      this.delays = 0;
      // this.setFramerate(this.targetFramerate);
    }
    trackPerformance.stop('onTick::handleLoopDelay');
  }

  getCameraHTMLElement() {
    return this.renderers.get('css3D').renderEngine.domElement.children[0];
  }

  destroy() {
    for (const [, renderer] of this.renderers) {
      renderer.dispose();
    }
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('visibilitychange', this.onVisibilityChanged);
    gsap.ticker.remove(this.onTick.bind(this));
  }
}
