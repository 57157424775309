import { computed, Ref } from '@vue/composition-api';
import { useData } from '@/composables';
import { useVolatileData } from '@/composables';
export const useWorkspace = (id: Ref<string>) => {
  const { getData, updateProperties, pushData, removeData } = useData();
  const { setVolatileProp } = useVolatileData();

  const doc = computed(() => getData(id.value));
  const properties = computed(() => doc.value?.properties || {});
  const toolsFolder = computed(() =>
    getData((properties.value?.tools?.value as string) || id.value),
  );
  const vcTemplate = computed(() =>
    getData((properties.value?.vcTemplate?.value as string) || id.value),
  );

  const tools = computed(() => {
    return (toolsFolder.value?.list?.items || []).map((item, value) => {
      const data = getData(item.id);
      const text = data.info.title || '';
      return { icon: data.info.icon, title: text, id: item.id };
    });
  });

  const activeToolId = computed(() => properties.value.activeTool?.value || '');

  const activateTool = (toolId, readOnly) => {
    if (readOnly) {
      setVolatileProp(id.value, 'activeToolVolatile', toolId);
    } else {
      updateProperties(id.value, {
        activeTool: {
          type: 'id',
          value:
            toolId === 0 || toolId === undefined || toolId === 'none'
              ? ''
              : toolId,
        },
      });
    }
  };

  const removeTool = (id) => {
    return removeData(id);
  };

  return {
    doc,
    toolsFolder,
    vcTemplate,
    tools,
    activateTool,
    activeToolId,
    removeTool,
  };
};
