












import { defineComponent, computed } from '@vue/composition-api';
import { useI18n } from '@/composables';
import { ActionControl } from '@fillip/api';

export default defineComponent({
  name: 'LayoutAreaHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    layoutArea: {
      type: String,
      required: true,
    },
    glass: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, { emit }) {
    const { tr } = useI18n();

    const actions = computed<ActionControl[]>(() => {
      const result = [];

      // if (!['dialog', 'main'].includes(props.layoutArea)) {
      //   result.push({
      // type: 'action',
      //     name: 'expand',
      //     title: tr('general.expand'),
      //     action: () => emit('expand'),
      //     icon: 'expand',
      //   });
      // }
      // if (['dialog', 'main'].includes(props.layoutArea)) {
      //   result.push({
      // type: 'action',
      //     name: 'collapse',
      //     title: tr('general.collapse'),
      //     action: () => emit('collapse'),
      //     icon: 'compress',
      //   });
      // }

      result.push({
        type: 'action',
        name: 'close',
        title: tr('general.close'),
        action: () => emit('close'),
        icon: 'times',
      });
      return result;
    });

    return {
      actions,
    };
  },
});
