















































































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import { ModuleTagDefinitionMeta } from '@fillip/api';
import clone from 'rfdc/default';

export default Vue.extend({
  name: 'TagDefinitionEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'tagDefinition',
    });

    const init = () => {
      buffer.value = clone(ModuleTagDefinitionMeta.default);
      save();
    };

    const addVariant = () => {
      if (!buffer.value.variants) Vue.set(buffer.value, 'variants', []);
      buffer.value.variants.push({ key: 'key', title: 'Title' });
      save();
    };

    const removeVariant = (index: number) => {
      buffer.value.variants.splice(index, 1);
      save();
    };

    const tagCategories = ModuleTagDefinitionMeta.schema.tagCategories;

    const editors = [
      'h1',
      'h2',
      'h3',
      'h4',
      'text',
      'styledText',
      'quote',
      'markdown',
      'html',
      'reference',
      'embed',
      'image',
      'table',
      'default',
    ];

    return {
      buffer,
      init,
      save,
      addVariant,
      removeVariant,
      tagCategories,
      editors,
    };
  },
});
