import {
  ChildTemplate,
  DataDocument,
  DataId,
  ModuleListItem,
} from '@fillip/api';

import * as _ from 'lodash/fp';

export * from 'fast-equals';

export const lodashFp = _;

export const sleep = (ms: number = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const objectPush = (
  object: Record<string, Array<any>>,
  key: string | number,
  element: any,
) => {
  if (!object[key]) {
    object[key] = [element];
  } else {
    object[key].push(element);
  }
};

export const mapPush = <Key, Value>(
  map: Map<Key, Value[]>,
  key: Key,
  value: Value,
) => {
  if (!map.has(key)) {
    map.set(key, [value]);
  } else {
    map.get(key).push(value);
  }
};

export const mapRemove = <Key, Value>(
  map: Map<Key, Value[]>,
  key: Key,
  value: Value,
) => {
  const array = map.get(key);
  const index = array.findIndex((e) => e === value);
  if (index == -1) throw new Error(`Key ${key} doesn't exist in map ${value}`);
  if (array.length == 1) {
    map.delete(key);
  } else {
    array.splice(index, 1);
  }
};

export function assertUnreachable(x: never): never {
  throw new Error('assertUnreachable: Missing case');
}

export const extractIds = (docs: DataDocument[] | DataId[]): DataId[] => {
  if (!docs) return [];
  return docs.map(
    (d: DataDocument | DataId): DataId => (typeof d === 'string' ? d : d.id),
  );
};

export const extractDirectChildren =
  (parentId: DataId) =>
  (docs: DataDocument[]): DataDocument[] => {
    if (!docs) return [];
    return docs.filter((d: DataDocument) => d.parentId == parentId);
  };

export const docsToListItems = (
  docs: DataId[] | DataDocument[],
): ModuleListItem[] => {
  if (!docs) return [];
  return docs.map(
    (d: DataId | DataDocument): ModuleListItem => ({
      id: typeof d == 'string' ? d : d.id,
    }),
  );
};

export const addListItems =
  (items: ModuleListItem[]) =>
  (doc: DataDocument): DataDocument => {
    if (!items || items.length < 1) return doc;
    return {
      ...doc,
      list: {
        ...doc.list,
        items: [...(doc.list?.items || []), ...items],
      },
    };
  };

export const addChildrenTemplates =
  (templates: ChildTemplate[]) =>
  (doc: DataDocument): DataDocument => {
    if (!templates || templates.length < 1) return doc;
    return {
      ...doc,
      children: {
        ...doc.children,
        default: [...(doc.children?.default || []), ...templates],
      },
    };
  };

export const addTemplateMappings =
  (mappings: Record<string, DataId>) =>
  (doc: DataDocument): DataDocument => {
    if (!mappings || Object.keys(mappings).length < 1) return doc;
    return {
      ...doc,
      templates: {
        ...(doc.templates || {}),
        mapping: {
          ...(doc.templates?.mapping || {}),
          ...mappings,
        },
      },
    };
  };

export const replaceString = (oldValue, newValue) => (doc: DataDocument) => {
  return JSON.parse(JSON.stringify(doc).replace(oldValue, newValue));
};

declare var performance: any;
declare var window: any;

export const useTrackPerformance = (
  shouldMeasure: boolean,
  prefix: string,
  logTreshold: number = 1,
) => {
  if (shouldMeasure && !('performance' in window)) {
    shouldMeasure = false;
    console.warn('Your browser does not support the perfomance API');
  }
  return {
    start: (measure: string) => {
      if (shouldMeasure) performance.mark(`${prefix}::${measure}::start`);
    },
    stop: (measure: string) => {
      if (shouldMeasure) {
        performance.mark(`${prefix}::${measure}::end`);
        performance.measure(
          `${prefix}::${measure}`,
          `${prefix}::${measure}::start`,
          `${prefix}::${measure}::end`,
        );
      }
    },
    log: () => {
      if (shouldMeasure) {
        const measures = performance.getEntriesByType('measure');
        console.groupCollapsed(`Performance Measures for [${prefix}]`);
        measures.forEach((measureItem) => {
          if (measureItem.duration > logTreshold)
            console.log(`${measureItem.name}: ${measureItem.duration}`);
        });
        console.groupEnd();
      }
    },
    reset: () => {
      if (shouldMeasure) {
        performance.clearMeasures();
      }
    },
  };
};

export enum LogLevels {
  NONE = 1,
  ERROR,
  WARN,
  INFO,
  DEBUG,
}

export interface Logger {
  debug: (...args: any) => void;
  info: (...args: any) => void;
  warn: (...args: any) => void;
  error: (...args: any) => void;
}

export const useLogger = (
  localLogLevel: LogLevels = process.env.NODE_ENV == 'production'
    ? LogLevels.NONE
    : LogLevels.DEBUG,
  remoteLogLevel: LogLevels = process.env.NODE_ENV == 'production'
    ? LogLevels.NONE
    : LogLevels.NONE,
  prefix: string = '',
): Logger => ({
  debug: (...args: any) => {
    if (localLogLevel >= LogLevels.DEBUG) {
      console.debug(`[${prefix}]`, ...args);
    }
    if (remoteLogLevel >= LogLevels.DEBUG) {
      // TODO: IMPLEMENT REMOTE LOGGING
    }
  },
  info: (...args: any) => {
    if (localLogLevel >= LogLevels.INFO) {
      console.log(`[${prefix}]`, ...args);
    }
    if (remoteLogLevel >= LogLevels.INFO) {
      // TODO: IMPLEMENT REMOTE LOGGING
    }
  },
  warn: (...args: any) => {
    if (localLogLevel >= LogLevels.WARN) {
      console.warn(`[${prefix}]`, ...args);
    }
    if (remoteLogLevel >= LogLevels.WARN) {
      // TODO: IMPLEMENT REMOTE LOGGING
    }
  },
  error: (...args: any) => {
    if (localLogLevel >= LogLevels.ERROR) {
      console.error(`[${prefix}]`, ...args);
    }
    if (remoteLogLevel >= LogLevels.ERROR) {
      // TODO: IMPLEMENT REMOTE LOGGING
    }
  },
});

// Helps to narrow down a type based on the existence of keys
// See https://fettblog.eu/typescript-hasownproperty/
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}
