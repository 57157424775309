













































import Vue from 'vue';
import { useSave } from '@/composables';
import { BaseModuleEditor } from '../base';
import clone from 'rfdc/default';

import { ModuleElementMeta } from '@fillip/api';

import ButtonEditor from '../elements/ButtonEditor.vue';
import EmbedEditor from '../elements/EmbedEditor.vue';
import HtmlEditor from '../elements/HtmlEditor.vue';
import IconEditor from '../elements/IconEditor.vue';
import ImageEditor from '../elements/ImageEditor.vue';
import MarkdownEditor from '../elements/MarkdownEditor.vue';
import ParticipantEditor from '../elements/ParticipantEditor.vue';
import ScreenshareEditor from '../elements/ScreenshareEditor.vue';
import TextEditor from '../elements/TextEditor.vue';
import ChartEditor from '../elements/ChartEditor.vue';
import TableEditor from '../elements/TableEditor.vue';
import RevealEditor from '../elements/RevealEditor.vue';
import AudioEditor from '../elements/AudioEditor.vue';
import SvgEditor from '../elements/SvgEditor.vue';
import InlineEditingEditor from '../elements/InlineEditingEditor.vue';
import ElementLineEditor from '../elements/ElementLineEditor.vue';

const editors = {
  'element.button': ButtonEditor,
  'element.embed': EmbedEditor,
  'element.html': HtmlEditor,
  'element.icon': IconEditor,
  'element.image': ImageEditor,
  'element.markdown': MarkdownEditor,
  'element.participant': ParticipantEditor,
  'element.screenshare': ScreenshareEditor,
  'element.text': TextEditor,
  'element.chart': ChartEditor,
  'element.table': TableEditor,
  'element.reveal': RevealEditor,
  'element.audio': AudioEditor,
  'element.svg': SvgEditor,
  'element.inlineEditing': InlineEditingEditor,
  'element.styledText': HtmlEditor,
  'element.line': ElementLineEditor,
};

export default Vue.extend({
  name: 'ElementEditor',
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const { buffer, save } = useSave(props, {
      path: 'element',
    });

    return {
      buffer,
      save,
    };
  },
  data() {
    return {
      lastEntries: {},
    };
  },
  computed: {
    editorComponent() {
      return editors[this.buffer.type];
    },
    icon() {
      return ModuleElementMeta.types[this.buffer.type]?.icon;
    },
    items() {
      return Object.values(ModuleElementMeta.types).map((type) => ({
        text: this.$t(`${type.type}.title`),
        value: type.type,
        icon: type.icon,
      }));
    },
  },
  methods: {
    setType(type) {
      this.buffer = {
        ...clone(ModuleElementMeta.types[type].default),
        ...this.lastEntries[type],
      };
      this.save();
    },
    setLastEntry(payload) {
      const { type, ...value } = payload;
      this.lastEntries[type] = value;
    },
  },
});
