
































































import Vue from 'vue';
import { BaseElement } from './../core';
import { computed } from '@vue/composition-api';

export default Vue.extend({
  name: 'ElementLine',
  mixins: [BaseElement],
  setup(props: any, { emit }) {
    const settings = computed(() => props.modules.element);
    // console.log('settings', settings.value);

    const dimensions = computed(() => {
      const width =
        Math.abs(
          Number(settings.value.lineTo.x) - Number(settings.value.lineFrom.x),
        ) + Number(settings.value.lineWidth);
      // console.log('settings.value.lineFrom', settings.value.lineFrom.y);
      // console.log('settings.value.lineTo', settings.value.lineTo.y);
      const height =
        Math.abs(
          Number(settings.value.lineTo.y) - Number(settings.value.lineFrom.y),
        ) + Number(settings.value.lineWidth);

      return { width, height };
    });

    const lineStyle = computed(() => {
      const styles = {
        solid: '1,1',
        'dashes-small': '10,20',
        'dashes-large': '20,20',
        dotted: '1,20',
      };
      return styles[settings.value.lineStyle || 'solid'];
    });

    const positions = computed(() => {
      const x1 =
        settings.value.lineFrom.x > settings.value.lineTo.x
          ? dimensions.value.width - settings.value.lineWidth / 2
          : settings.value.lineWidth / 2;
      const x2 =
        settings.value.lineFrom.x > settings.value.lineTo.x
          ? settings.value.lineWidth / 2
          : dimensions.value.width - settings.value.lineWidth / 2;
      const y1 =
        settings.value.lineFrom.y > settings.value.lineTo.y
          ? settings.value.lineWidth / 2
          : dimensions.value.height - settings.value.lineWidth / 2;
      const y2 =
        settings.value.lineFrom.y > settings.value.lineTo.y
          ? dimensions.value.height - settings.value.lineWidth / 2
          : settings.value.lineWidth / 2;

      return {
        x1,
        x2,
        y1,
        y2,
      };
    });

    const getElementDimension = (dimension: 'width' | 'height') =>
      Math.max(dimensions.value[dimension], settings.value.lineWidth);

    const elementDimensions = computed(() => ({
      height: getElementDimension('height'),
      width: getElementDimension('width'),
    }));

    const handleEvent = (type, $event) => {
      // const data = $event.target.dataset.interactive;
      emit('event', {
        name: type,
        event: {
          // input: data,
          event: $event,
        },
      });
    };

    return {
      settings,
      dimensions,
      positions,
      elementDimensions,
      handleEvent,
      lineStyle,
    };
  },
});
