import { BaseElement } from './../core';

export const ElementImage = {
  name: 'ElementImage',
  mixins: [BaseElement],
  computed: {
    src() {
      return this.modules.element?.imageSrc;
    },
    title() {
      return this.modules.info?.title;
    },
    description() {
      return this.modules.element?.imageDescription;
    },
    copyright() {
      return this.modules.element?.imageCopyright;
    },
  },
  render(h) {
    return this.src
      ? h(
          'img',
          {
            attrs: {
              src: this.src,
              alt: this.description,
              title: this.copyright,
            },
            style: {
              maxWidth: '100%',
              maxHeight: '100%',
            },
            ref: 'el',
          },
          [],
        )
      : h('div', {}, '');
  },
};
