import * as Location3D from '@/utils/location3D';
import { Location3DType, ModuleCamera, Vector3Type } from '@fillip/api';
import { CameraEntity } from './../../core/camera';
import { IBaseControls } from './index';
import { Box3, Object3D, PerspectiveCamera } from 'three';
import { calculateZoomToFitLocation } from './CameraUtils';

export class BaseCameraControls implements IBaseControls {
  public context: CameraEntity;
  public sceneCamera: ModuleCamera;
  public sceneBoundingBox: Box3;

  constructor(context, sceneCamera: ModuleCamera) {
    this.context = context;
    this.sceneCamera = sceneCamera;
    this.initialize();
  }

  initialize() {}

  updateCamera(
    sceneCamera: ModuleCamera,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) {
    this.sceneCamera = sceneCamera;
    this.sceneBoundingBox = sceneBoundingBox;
    this.checkCameraPosition();
  }

  checkCameraPosition() {
    return;
  }

  truck(x: number, y: number): void {
    const currentLocation = this.context.getTargetLocation();
    currentLocation.position.x += x;
    currentLocation.position.y += y;
    this.context.setCameraTarget(currentLocation);
  }

  zoom(z: number): void {
    const currentLocation = this.context.getTargetLocation();
    currentLocation.position.z += z;
    this.context.setCameraTarget(currentLocation);
  }

  moveTo(location: Location3DType | Vector3Type) {
    if ('position' in location) {
      this.context.setCameraTarget(location);
    } else {
      this.context.setCameraTarget(
        Location3D.addDefaults({ position: location }),
      );
    }
  }

  lookAt(position: Vector3Type | number, y?: number, z?: number) {
    // TODO: Implement
    console.warn('lookAt not implemented');
  }

  async zoomToFit(focusedObject: Box3 | Object3D) {
    const targetLocation = calculateZoomToFitLocation(
      focusedObject,
      this.context.engine.viewport,
      {
        limitZoomForScreenSize:
          this.sceneCamera.type == 'camera.auto-zoom' &&
          this.sceneCamera.limitZoomForScreenSize,
        minDistance:
          this.sceneCamera.type == 'camera.auto-zoom'
            ? this.sceneCamera.zoomMinDistance
            : 100,
        maxDistance:
          this.sceneCamera.type == 'camera.auto-zoom'
            ? this.sceneCamera.zoomMaxDistance
            : 15000,
      },
    );
    this.context.setCameraTarget(targetLocation);
  }

  getCamera(): PerspectiveCamera {
    return this.context.content as unknown as THREE.PerspectiveCamera;
  }

  enable() {
    // IMPLEMENT
  }

  disable() {
    // IMPLEMENT
  }

  async dispose() {
    this.context.engine.render();
  }
}
