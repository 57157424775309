

















import { useFillip, useStore } from '@/composables';
import MediasoupVideoConsumer from '@/features/video-conferencing/MediasoupVideoConsumer.vue';
import VideoTrackConsumer from '@/features/video-conferencing/VideoTrackConsumer.vue';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ParticipantVideo',
  components: {
    MediasoupVideoConsumer,
    VideoTrackConsumer,
  },
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { $me } = useFillip();
    const { store } = useStore();

    const isMe = computed(() => props.participant.id === $me.value.id);

    const myCameraTrack = computed(() => {
      const stream =
        store.getters.communityStore?.mediasoup?.producers?.camera?.mediaStream;
      if (!stream || !$me.value.participantVc.camera) return null;
      return stream.getVideoTracks()[0];
    });

    const cameraProducer = computed(() => {
      if (
        !props.participant.streams?.camera ||
        !props.participant?.participantVc?.camera
      ) {
        return null;
      }
      return props.participant.streams.camera;
    });

    return {
      isMe,
      myCameraTrack,
      cameraProducer,
    };
  },
});
