var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$communitySlug)?_c('transition',{on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.state == 'MINIMAL' || _vm.state == 'FULL')?_c('div',{staticClass:"fixed top-0 left-0 max-w-screen",staticStyle:{"z-index":"6"},style:({
        width: (_vm.effectiveWidth + "px"),
        height: ("calc(100vh - " + _vm.appBarHeight + ")"),
      })},[_c('div',{staticClass:"fixed top-0",style:({
          width: ((_vm.state == 'FULL' ? _vm.width - _vm.iconBarWidth : 0) + "px"),
          height: ("calc(100vh - " + _vm.appBarHeight + ")"),
          left: _vm.iconBarWidth + 'px',
        })},[_c('transition',{on:{"enter":_vm.expand,"leave":_vm.collapse}},[(_vm.state == 'FULL')?_c('div',{staticClass:"h-full overflow-y-auto max-w-screen relative",staticStyle:{"z-index":"5"},style:({
              backgroundColor: _vm.themeManager.mainDrawerBackground,
              'border-right': _vm.themeManager.mainDrawerBorder
                ? '1px solid rgba(0, 0, 0, 0.1)'
                : 'none',
              'border-bottom': _vm.themeManager.appBarShadow
                ? 'none'
                : '1px solid rgba(0, 0, 0, 0.1)',
            })},[_c('BaseTransition',{attrs:{"name":"fade"}},[_c(_vm.component,_vm._b({tag:"component"},'component',_vm.componentProps,false))],1),_c('div',{staticStyle:{"cursor":"col-resize","width":"4px","position":"absolute","right":"0px","top":"0","bottom":"0"},on:{"mousedown":_vm.startResize}})],1):_vm._e()])],1),_c('div',{staticClass:"h-full flex",staticStyle:{"z-index":"7"}},[_c('NavigationBar')],1)]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }