import {
  ModelPositionalAudio,
  ModelPositionalAudioMeta,
  ModelVideo360,
  ModelVideo360Meta,
} from '../vm/modules/model.module';
import {
  ElementAudio,
  ElementAudioMeta,
  ElementEmbed,
  ElementEmbedMeta,
  ElementImage,
  ElementImageMeta,
} from '../vm/modules/element.module';
import { TypeMeta } from './../common/index';
import { Route, RootRoute } from './../route/index';
import { Vector3 } from '../vm';

export interface AnyTypeMeta<T> extends TypeMeta<T> {
  type: TypeName;
}

export type TypeString = string;

export const TypeStringMeta: AnyTypeMeta<TypeString> = {
  type: 'string',
  name: 'String',
  default: '',
};

export type TypeTextarea = string;

export const TypeTextareaMeta: AnyTypeMeta<TypeTextarea> = {
  type: 'textarea',
  name: 'Textarea',
  default: '',
};

export type TypeStringArray = string[];

export const TypeStringArrayMeta: AnyTypeMeta<TypeStringArray> = {
  type: 'stringArray',
  name: 'String Array',
  default: [],
};

export type TypeNumber = number;

export const TypeNumberMeta: AnyTypeMeta<TypeNumber> = {
  type: 'number',
  name: 'Number',
  default: 0,
};

export type TypeBoolean = boolean;

export const TypeBooleanMeta: AnyTypeMeta<TypeBoolean> = {
  type: 'boolean',
  name: 'Boolean',
  default: false,
};

export type TypeRoute = Route;

export const TypeRouteMeta: AnyTypeMeta<TypeRoute> = {
  type: 'route',
  name: 'Route',
  default: RootRoute,
};

export type TypeId = string;

export const TypeIdMeta: AnyTypeMeta<TypeId> = {
  type: 'id',
  name: 'Id',
  default: '',
};

export type TypeDocuments = string[];

export const TypeDocumentsMeta: AnyTypeMeta<TypeDocuments> = {
  type: 'documents',
  name: 'Documents',
  default: [],
};

export type TypeParticipants = string[];

export const TypeParticipantsMeta: AnyTypeMeta<TypeParticipants> = {
  type: 'participants',
  name: 'Participants',
  default: [],
};

export type TypeColor = string;

export const TypeColorMeta: AnyTypeMeta<TypeColor> = {
  type: 'color',
  name: 'Color',
  default: 'rgb(255,255,255,1)',
};
export type TypeScript = string;

export const TypeScriptMeta: AnyTypeMeta<TypeScript> = {
  type: 'script',
  name: 'Script',
  default: '',
};

export type TypeDatetime = number;

export const TypeDatetimeMeta: AnyTypeMeta<TypeDatetime> = {
  type: 'datetime',
  name: 'Datetime',
  default: Date.now(),
};

export interface TypeEmbed extends Omit<ElementEmbed, 'type'> {}

export const TypeEmbedMeta: AnyTypeMeta<TypeEmbed> = {
  type: 'embed',
  name: 'Embed',
  default: ElementEmbedMeta.default,
};

export interface TypeImage extends Omit<ElementImage, 'type'> {}

export const TypeImageMeta: AnyTypeMeta<TypeImage> = {
  type: 'image',
  name: 'Image',
  default: ElementImageMeta.default,
};

export interface TypeAudio extends Omit<ElementAudio, 'type'> {}

export const TypeAudioMeta: AnyTypeMeta<TypeAudio> = {
  type: 'audio',
  name: 'Audio',
  default: ElementAudioMeta.default,
};
export interface TypePositionalAudio
  extends Omit<ModelPositionalAudio, 'type'> {}

export const TypePositionalAudioMeta: AnyTypeMeta<TypePositionalAudio> = {
  type: 'positionalAudio',
  name: 'Positional Audio',
  default: ModelPositionalAudioMeta.default,
};

export interface TypeVideo360 extends Omit<ModelVideo360, 'type'> {}

export const TypeVideo360Meta: AnyTypeMeta<TypeVideo360> = {
  type: 'video360',
  name: 'Video360',
  default: ModelVideo360Meta.default,
};

export type TypeHtml = string;

export const TypeHtmlMeta: AnyTypeMeta<TypeHtml> = {
  type: 'html',
  name: 'Html',
  default: '',
};

export type TypeVector3 = Vector3;

export const TypeVector3Meta: AnyTypeMeta<TypeVector3> = {
  type: 'vector3',
  name: 'Vector3',
  default: { x: 0, y: 0, z: 0 },
};

export type TypeSelect = string;

export const TypeSelectMeta: AnyTypeMeta<TypeSelect> = {
  type: 'select',
  name: 'Select',
  default: '',
};

export type TypeStyledText = string;

export const TypeStyledTextMeta: AnyTypeMeta<TypeStyledText> = {
  type: 'styledText',
  name: 'Styled Text',
  default: '',
};

export type TypeIcon = string;

export const TypeIconMeta: AnyTypeMeta<TypeIcon> = {
  type: 'icon',
  name: 'Icon',
  default: 'heart',
};

export interface TypesDictionary {
  string: TypeString;
  stringArray: TypeStringArray;
  textarea: TypeTextarea;
  number: TypeNumber;
  boolean: TypeBoolean;
  id: TypeId;
  color: TypeColor;
  script: TypeScript;
  route: TypeRoute;
  documents: TypeDocuments;
  participants: TypeParticipants;
  datetime: TypeDatetime;
  embed: TypeEmbed;
  image: TypeImage;
  audio: TypeAudio;
  positionalAudio: TypePositionalAudio;
  video360: TypeVideo360;
  html: TypeHtml;
  vector3: TypeVector3;
  select: TypeSelect;
  styledText: TypeStyledText;
  icon: TypeIcon;
}

export type TypeName = keyof TypesDictionary;

export const BuiltinTypesMeta: Partial<Record<TypeName, AnyTypeMeta<any>>> = {
  string: TypeStringMeta,
  stringArray: TypeStringArrayMeta,
  textarea: TypeTextareaMeta,
  number: TypeNumberMeta,
  boolean: TypeBooleanMeta,
  id: TypeIdMeta,
  color: TypeColorMeta,
  script: TypeScriptMeta,
  route: TypeRouteMeta,
  documents: TypeDocumentsMeta,
  participants: TypeParticipantsMeta,
  datetime: TypeDatetimeMeta,
  embed: TypeEmbedMeta,
  image: TypeImageMeta,
  audio: TypeAudioMeta,
  positionalAudio: TypePositionalAudioMeta,
  video360: TypeVideo360Meta,
  html: TypeHtmlMeta,
  vector3: TypeVector3Meta,
  select: TypeSelectMeta,
  styledText: TypeStyledTextMeta,
  icon: TypeIconMeta,
} as const;
