import { Engine } from './../../features/main/engine/core/engine';
import {
  Tag,
  documentFilters,
  documentUtils,
  dateTimeUtils,
  routeUtils,
  checkProp,
  where,
  DataDocument,
  evaluate,
  execute,
  extractId,
  intersectionBy,
  frontendPrefixMarker,
  useStateBus,
  Vector3Type,
} from '@fillip/api';
import { getCurrentInstance, inject } from '@vue/composition-api';
import {
  useData,
  useLocations,
  useParticipants,
  useVolatileData,
} from '@/composables';
import { FillipCommands } from '../../features/main/core';
import { Size3D } from '@/features/main/engine/systems/size';
import { pipe, matchesProperty, map as _map } from 'lodash/fp';
import store from '@/store';
import { useProgress } from './useProgress';
import { useFilter } from './useFilter';
import { nanoid } from 'nanoid';

export function useEnvironment(externalVm?: Required<FillipCommands>) {
  const vm = (externalVm ||
    (getCurrentInstance() as any).proxy) as Required<FillipCommands>;
  if (!vm) throw new Error('Vue instance not found!');

  const {
    getData,
    getDataByTag,
    getDataByIds,
    pushData,
    updateProperties,
    getLocal,
    maybeId,
    maybeDoc,
  } = useData();
  const { atView, atPath, atDocument, atStation, atScene, hasNoFocus } =
    useLocations();
  const { getAvatarUrl } = useParticipants();
  const {
    getVolatile,
    setVolatile,
    clearVolatile,
    setVolatileProp,
    getVolatileProp,
    clearVolatileProp,
    pushToVolatile,
    removeFromVolatile,
  } = useVolatileData();

  const stateBus = useStateBus('environment');

  const requestInterval = (intervalInMs: number = 5000) => {
    setTimeout(() => {
      stateBus.requestUpdate('query::interval');
    }, intervalInMs);
  };
  const requestUpdate = () => stateBus.requestUpdate('query::request');

  const immediateUpdate = () => stateBus.immediateUpdate('query::immediate');

  const router = inject('router');

  const randomId = (length: number = 10) => {
    return nanoid(length);
  };

  const shell = () => {
    console.log('shell', store.getters.shell);
    return store.getters.shell;
  };

  const select = (table: string) => () => {
    return Object.values(store.getters.communityState?.[table] || {});
  };

  const get = (id: string) => () => {
    const doc = getData(id);
    if (!doc) return [];
    return [doc];
  };

  const getDoc = (id: string): DataDocument | {} => {
    const doc = getData(id);
    if (!doc) return {};
    return doc;
  };

  const getList = (id: string) => () => {
    const document = getData(id);
    if (!document) throw new Error(`Document doesn't exist: ${id}`);
    const items = document.list?.items || [];
    return items.map(({ id }) => getData(id));
  };

  const hasList = (id: string) => {
    const document = getData(id);
    if (!document) throw new Error(`Document doesn't exist: ${id}`);
    return document.list?.items?.length > 0;
  };

  const getParticipantList =
    (id: string, participantId: string = vm.$me.id) =>
    () => {
      return pipe(
        getList(id),
        where(matchesProperty('info.createdBy', participantId)),
      )();
    };

  const getListWithChildren =
    (id: string, result: DataDocument[] = []) =>
    () => {
      const document = getData(id);
      if (!document) throw new Error(`Document doesn't exist: ${id}`);
      const items = document.list?.items || [];
      items.flatMap(({ id }) => {
        result.push(getData(id));
        return getListWithChildren(id, result)();
      });
      return result;
    };

  const getParticipantListWithChildren =
    (id: string, participantId: string = vm.$me.id) =>
    () => {
      const documents = getListWithChildren(id)();
      return documents.filter((d) => d.info.createdBy == participantId);
    };

  const getPropFrom = (propKey: string) => (id: string) => {
    const document = getData(id);
    if (!document) throw new Error(`Document doesn't exist: ${id}`);
    return documentUtils.property(propKey)(document);
  };

  const getRoles = (id: string) => () => {
    const document = getData(id);
    if (!document) throw new Error(`Document doesn't exist: ${id}`);
    return document.roles?.roles || {};
  };

  const getParticipantRoles = (participantId: string) => (id: string) => () => {
    const roles = getRoles(id)();
    if (!roles) return [];
    const participantRoles = [];
    for (const role of Object.values(roles)) {
      if (role.documents?.includes(participantId)) {
        participantRoles.push(role.key);
      }
    }
    return participantRoles;
  };

  const getAll = (tag?: Tag) => () => {
    return getDataByTag(tag);
  };

  const getListFilteredBy =
    (collectionId: string, property: string) => (filterValue) => {
      return getList(collectionId)().filter((e) =>
        checkProp(property, filterValue)(e),
      );
    };

  const getFilteredList =
    (property: string, filterValue: any) =>
    (docOrId: string | DataDocument) => {
      const id = maybeId(docOrId);
      return getList(id)().filter((e) => checkProp(property, filterValue)(e));
    };

  const getFilteredParticipantList =
    (property: string, filterValue: any, participantId: string = vm.$me.id) =>
    (docOrId: string | DataDocument) => {
      if (docOrId === null || docOrId === undefined) return [];
      const id = maybeId(docOrId);
      return getParticipantList(id, participantId)().filter((e) =>
        checkProp(property, filterValue)(e),
      );
    };

  const docs = (ids: any[] | string) => () => {
    if (!ids) return [];
    if (typeof ids == 'string') ids = [ids];
    return getDataByIds(ids);
  };

  const participants = () => {
    return store.getters.participants;
  };

  const getParticipants = () => {
    return participants();
  };

  const getOnlineParticipants = () => {
    return store.getters.onlineParticipants;
  };

  const templates = () => {
    return getDataByTag('template');
  };

  const collections = () => {
    return getDataByTag('collection');
  };

  const fake =
    (titlePrefix: string, template: any = {}, activeIndex?: number) =>
    (amount: number) => {
      return Array.from({ length: amount }, (v, i) => i).map((index) => {
        return {
          ...template,
          id: titlePrefix + index,
          info: {
            title: `${titlePrefix} ${index}`,
          },
          properties: {
            index: {
              type: 'number',
              value: index,
            },
            isActive: {
              type: 'boolean',
              value: activeIndex === index,
            },
            ...(template.properties || {}),
          },
        };
      });
    };

  const log = (arg: any, id: string = '') => {
    console.log(`Query${id ? ' (Id: ' + id + ')' : ''}: `, arg);
    return arg;
  };

  const emitEvent = (name: string, event: Record<string, any>, $id: string) => {
    const engine: Engine = (vm as any).engine || (vm as any).scene.value.engine;
    engine.getEntity($id).handleEvent(name, event);
  };

  const getEntityLocation = ($id: string): Vector3Type => {
    const engine: Engine = (vm as any).engine || (vm as any).scene.value.engine;
    const entity = engine.getEntity($id);
    // TODO: Switch to deferred tasks step in engine
    requestInterval(3000);
    if (!entity) {
      return { x: 0, y: 0, z: 0 };
    }
    return entity.getTargetWorldPosition();
  };

  const getEntitySize = ($id: string): Size3D => {
    const engine: Engine = (vm as any).engine || (vm as any).scene.value.engine;
    const entity = engine.getEntity($id);
    // TODO: Switch to deferred tasks step in engine
    // requestInterval(2000);
    if (!entity) {
      return { width: 0, height: 0, depth: 0 };
    }
    return entity.getSize();
  };

  const eventPositionToCanvasPosition = (
    $event: MouseEvent,
  ): { x: number; y: number } => {
    const engine: Engine = (vm as any).engine || (vm as any).scene.value.engine;
    return engine.eventPositionToCanvasPosition($event);
  };

  const evaluateCondition = (context, condition, fallback: boolean = false) => {
    if (typeof condition === 'boolean') return condition;
    if (
      typeof condition === 'string' &&
      condition.startsWith(frontendPrefixMarker)
    ) {
      const result = evaluate(context, condition, frontendPrefixMarker);
      if (result !== undefined) {
        return result;
      }
    }
    return fallback;
  };

  const allChildrenDone = (id: string, context: any): boolean => {
    return getList(id)().every((doc) => {
      return evaluateCondition(context, doc?.properties?.isDone?.value, true);
    });
  };

  const answeredAllQuestions = (
    questionId: string,
    collectionId: string,
    meId: string = vm.$me.id,
    path: string = 'properties.question.value',
  ): boolean => {
    if (!questionId || !collectionId) return true;
    const questionDoc = getDoc(questionId);
    if (!questionDoc || !('id' in questionDoc)) return true;
    if (questionDoc.tag?.tag === 'plebs-reflection-question') {
      return (
        getFilteredParticipantList(path, questionDoc.id, meId)(collectionId)
          .length > 0
      );
    }
    const propDocs =
      questionDoc?.properties?.documents?.type === 'documents' &&
      questionDoc.properties.documents.value;
    const documents = propDocs ? docs(propDocs)() : getList(questionId)();
    if (!documents?.length) return true;
    const intersection = intersectionBy(
      documents,
      extractId,
    )(_map(path)(getParticipantList(collectionId, meId)()));
    return intersection.length >= documents.length;
  };

  const { dayjs } = dateTimeUtils;

  const fromNow = (
    date: any,
    locale: string = 'en',
    withoutSuffix?: boolean,
    updateInterval: number = 60000,
  ) => {
    requestInterval(updateInterval);
    return dayjs(date).locale(locale).fromNow(withoutSuffix);
  };

  const fromDate = (
    date: any,
    locale: string = 'en',
    compared: any,
    withoutSuffix?: boolean,
    updateInterval: number = 60000,
  ) => {
    requestInterval(updateInterval);
    return dayjs(date).locale(locale).from(compared, withoutSuffix);
  };

  const toNow = (
    date: any,
    locale: string = 'en',
    withoutSuffix?: boolean,
    updateInterval: number = 60000,
  ) => {
    requestInterval(updateInterval);
    return dayjs(date).locale(locale).toNow(withoutSuffix);
  };

  const toDate = (
    date: any,
    locale: string = 'en',
    compared: any,
    withoutSuffix?: boolean,
    updateInterval: number = 60000,
  ) => {
    requestInterval(updateInterval);
    return dayjs(date).locale(locale).to(compared, withoutSuffix);
  };

  const isCreatedByMe = (doc: DataDocument) => {
    if (!doc) return false;
    return doc.info.createdBy === vm.$me.id;
  };

  const environment = {
    shell,
    randomId,
    select,
    get,
    getDoc,
    getLocal,
    getList,
    hasList,
    getAll,
    docs,
    maybeId,
    maybeDoc,
    getDocs: docs,
    participants,
    getParticipants,
    getOnlineParticipants,
    templates,
    collections,
    getRoles,
    getParticipantRoles,
    getParticipantList,
    getListWithChildren,
    getParticipantListWithChildren,
    getFilteredParticipantList,
    state: {
      requestInterval,
      requestUpdate,
      immediateUpdate,
    },
    //...vm.actions.value.globalStatusDictionary,
    ...documentUtils,
    ...documentFilters,
    ...dateTimeUtils,
    time: {
      fromNow,
      toNow,
      fromDate,
      toDate,
    },
    isCreatedByMe,
    ...routeUtils,
    atView,
    atPath,
    atDocument,
    atStation,
    atScene,
    hasNoFocus,
    JSON,
    Math,
    Array,
    Object,
    console,
    log,
    getVolatile,
    setVolatile,
    getVolatileProp,
    setVolatileProp,
    clearVolatileProp,
    clearVolatile,
    pushToVolatile,
    removeFromVolatile,
    getPropFrom,
    getListFilteredBy,
    getFilteredList,
    getAvatarUrl,
    fake,
    emitEvent,
    evaluateCondition,
    allChildrenDone,
    answeredAllQuestions,
    pushData,
    updateProperties,
    getEntityLocation,
    getEntitySize,
    eventPositionToCanvasPosition,

    getData,
    router,
    useProgress: null,
    useFilter: null,
    evaluate: null,
    execute: null,
  };

  environment.evaluate = (context, expression: string) => {
    return evaluate(context, expression, frontendPrefixMarker);
  };

  environment.execute = (context, expression: string) => {
    return execute(context, expression);
  };

  environment.useProgress = (context, options) =>
    useProgress(environment, vm.$me, context, options);

  environment.useFilter = (context, options) =>
    useFilter(environment, context, options);

  return {
    environment,
  };
}
