import {
  ChildTemplate,
  ConsolidatedPropMappings,
  ModuleChildren,
  PropMappings,
} from './../vm/modules/children.module';
import * as acorn from 'acorn';
import { assignWith, isObject } from 'lodash';
import {
  EvaluableModule,
  ModuleData,
  DataDocument,
  Modules,
  ComputedDefinition,
  PropsDefinition,
} from '@fillip/api';

import { EvaluationContext, EvaluatedTemplate } from './types';

import { evaluateExpression } from './expressions';

export * from './types';

function parseCustomPipeOperator(expression: string): string {
  if (expression.indexOf('|>') < 0) return expression;

  const parts = expression
    .split('|>')
    .map((e) => e.replace('/[\n\r]/g', '').trim());
  // .map(maybeWrapInFunction);
  return `pipe(${parts.join(',')})`;
}

export function evaluate(
  context: EvaluationContext,
  expression: string,
  prefixMarker: string,
) {
  // console.log('Evaluate', expression, typeof expression, context);
  if (typeof expression !== 'string') {
    return expression;
  }
  expression = expression.trim();
  if (!expression.startsWith(prefixMarker)) {
    return expression;
  }
  if (expression.length < 2) return null;
  if (!context.scope) context.scope = {};
  const expressionBody = parseCustomPipeOperator(expression.slice(1));

  let result = parseExpression(context, expressionBody);

  if (typeof result == 'function') {
    result = result();
  }
  if (typeof result == 'string' && result.startsWith(':')) {
    result = evaluate(context, result, prefixMarker);
  }
  return result;
}

export function execute(context: EvaluationContext, expression: string) {
  return parseExpression(context, expression);
}

function parseExpression(context, expression): any {
  try {
    const ast = acorn.parse(expression, {
      ecmaVersion: 2022,
    }) as any;

    if (ast.type !== 'Program') return 'Error: Expression is not a Program';

    const body = ast.body;
    // console.log('Execute', ast, context, expression);
    if (!context.scope) context.scope = {};

    for (let index = 0; index < body.length; index++) {
      if (index == body.length - 1) {
        return evaluateExpression(context, body[index]);
      }
      evaluateExpression(context, body[index]);
    }
  } catch (error) {
    console.error(
      `Expression Error in ${context.local.$id}: ${error.message}`,
      expression,
      error,
    );
    return '';
  }
}

function evaluateVariableExpression(
  context: EvaluationContext,
  expression: string,
  evaluateResult: boolean = false,
  prefixMarker: string = ':',
): any {
  const result = evaluate(context, expression, prefixMarker);

  if (evaluateResult) {
    if (Array.isArray(result)) {
      return assignObjects(context, prefixMarker, {}, { result }).result;
    }
    if (isObject(result)) {
      return assignObjects(context, prefixMarker, {}, result);
    }
  }

  return result;
}

export function evaluateVariables(
  context: EvaluationContext,
  prefixMarker: string,
  templateVariables: Record<string, any>[] = [],
  dataVariables: Record<string, any>[] = [],
): void {
  const mergedVariables = [...templateVariables, ...dataVariables];
  mergedVariables.forEach((variable) => {
    context.variables['$$' + variable.identifier] = evaluateVariableExpression(
      context,
      variable.query,
      variable.evaluate,
      prefixMarker,
    );
  });
}

export function evaluatePropMappings(
  context: EvaluationContext,
  prefixMarker: string,
  propMappings: ConsolidatedPropMappings,
): void {
  const addedKeys = [];
  propMappings.forEach((prop) => {
    context.props['$' + prop.key] = evaluateVariableExpression(
      context,
      prop.expression,
      true,
      prefixMarker,
    );
    addedKeys.push(prop.key);
  });
  for (const key of Object.keys(propMappings)) {
    if (!addedKeys.includes(key)) delete context.props['$' + key];
  }
  context.local.$props = context.props;
}

export function evaluateComputeds(
  context: EvaluationContext,
  prefixMarker: string,
  computeds: ComputedDefinition[] = [],
): void {
  context.computeds = {};
  computeds.forEach((computed: ComputedDefinition) => {
    context.computeds['$' + computed.key] = evaluateVariableExpression(
      context,
      computed.expression,
      computed.evaluate,
      prefixMarker,
    );
  });
  context.local.$computeds = context.computeds;
}

function evaluateChildren(
  children: ChildTemplate[],
  context: EvaluationContext,
  prefixMarker: string,
  customizer: any,
) {
  return children
    .map((child) => {
      if (child.condition) {
        const conditionMet = evaluate(
          context,
          child.condition as string,
          prefixMarker,
        );
        if (!conditionMet) return;
      }
      return assignWith({}, child, customizer);
    })
    .filter((c) => Boolean(c));
}

function assignObjects(
  context,
  prefixMarker,
  object: Record<string, any> = {},
  source: Record<string, any> = {},
  ignoreKeys: string[] = [],
): Record<string, any> {
  const customizer = (_, value, key?) => {
    if (typeof value == 'string') {
      return evaluate(context, value, prefixMarker);
    }
    if (Array.isArray(value)) {
      return value.map((element) => customizer(null, element));
    }
    // Avoid re-evaluating keys that should be ignored (i.e. because they were handled separately)
    if (ignoreKeys.includes(key)) return value;
    // Avoid evaluating child query when condition is falsy
    if (key === 'children') {
      const defaultChildren = evaluateChildren(
        (value as ModuleChildren).default,
        context,
        prefixMarker,
        customizer,
      );

      return {
        default: defaultChildren,
      };
    }
    if (isObject(value)) {
      if ((value as EvaluableModule<any>).type == 'conditional') {
        const conditionalModule = value as EvaluableModule<any>;
        for (let i = 0; i < conditionalModule.variants.length; i++) {
          const variant = conditionalModule.variants[i];
          const conditionsMet = evaluate(
            context,
            variant.condition,
            prefixMarker,
          );
          if (conditionsMet) {
            return assignWith({}, variant.value, customizer);
          } else {
            if (i == conditionalModule.variants.length - 1) {
              return assignWith({}, variant.value, customizer);
            }
          }
        }
      } else {
        return assignWith({}, value, customizer);
      }
    }
    return value;
  };
  return assignWith(object, source, customizer);
}

export function evaluateObject(
  template: DataDocument,
  data: ModuleData,
  prefixMarker: string,
  env: any, // RenderEnvironment
  vm?: any,
  environment: Record<string, any> = {},
  local: Record<string, any> = {},
  propMappings: ConsolidatedPropMappings = [],
): EvaluatedTemplate {
  const context: EvaluationContext = {
    environment,
    data: data || {},
    vm,
    local,
    props: { ...(env.$props || {}) },
    computeds: { ...(env.$computeds || {}) },
    variables: { ...(env.$vars || {}) },
  };

  if (template.interfaceDefinition?.props) {
    evaluatePropMappings(context, prefixMarker, propMappings);
  }
  if (template.computeds?.scoped) {
    evaluateComputeds(context, prefixMarker, template.computeds.scoped);
  }

  evaluateVariables(
    context,
    prefixMarker,
    template.variables?.variables,
    data?.variables?.variables,
  );

  const evaluatedTemplate = assignObjects(
    context,
    prefixMarker,
    { ...((data as any)?.id ? { data } : {}) },
    template,
    ['variables', 'interfaceDefinition', 'computeds', 'propMappings'],
  ) as Modules;
  // console.log('Interpreter result', evaluatedTemplate);
  return {
    evaluatedTemplate,
    variables: context.variables,
    computeds: context.computeds,
    props: context.props,
  };
}
