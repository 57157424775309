import type { Shell } from './../types';
import { internalPrivacy, generateExternalPrivacyPolicy } from '../utils';

export const askdamaris: Shell = {
  name: 'askdamaris',
  title: 'AskDamaris Bot',
  language: 'en',
  image: '',
  logo: '',
  // logo: 'https://media.collabl.world/image/zMPGu09fuRWU--ask-damaris-big-2.jpg',
  allowSignup: true,
  allowLogin: false,
  allowGoogleLogin: false,
  allowDiscordLogin: false,
  allowAnonymousLogin: true,
  hideUsernameInput: true,
  restrictCommunities: ['askdamaris'],
  communityDesignation: {
    singular: 'Community',
    plural: 'Communities',
  },
  isDark: true,
  script:
    'https://ta2435c9c.emailsys1a.net/form/23/6549/147d35bab7/popup.js?_g=1680004407',
  defaultAvatarUrl:
    'https://media.collabl.world/media/customers/askdamaris/shell/user-avatar.jpg',

  classes: {
    siteContainer: 'min-h-full flex flex-col',
    siteBackground:
      "#000000 url('https://media.collabl.world/image/zMPGu09fuRWU--ask-damaris-big-2.jpg') bottom left / cover no-repeat scroll",
    // siteBackground:
    //   'radial-gradient(circle, rgba(17,24,39,1) 0%, rgba(17,24,39,1) 32%, rgba(55,65,81,0.4598214285714286) 100%)',
    // siteContent: 'flex flex-col md:min-h-3/4',
    headerContainer:
      'p-10 pb-0 min-h-3/4 md:min-h-1/4 flex flex-col justify-start md:justify-center items-center bg-gradient-to-b from-gray-900',
    // headerBackground:
    //   'radial-gradient(circle, rgba(17,24,39,1) 20%, rgba(75,85,99,0.8715861344537815) 45%, rgba(75,85,99,0) 70%)',
    // headerBackground:
    //   'radial-gradient(circle, rgba(17,24,39,1) 10%, rgba(13,70,95,1) 47%, rgba(0,202,255,0.4598214285714286) 100%)',

    headerContentContainer: 'flex flex-col justify-center items-center my-4',
    contentCardContainer:
      'h-full w-full flex flex-col justify-items-start md:justify-center pl-0 md:pl-24',
    contentCard:
      'relative rounded-lg bg-gray-900 bg-opacity-90 border-gray-700 border-2 border-solid border-opacity-50 shadow-md text-white p-6',
    contentBox:
      'rounded-lg bg-gray-900 bg-opacity-90 border-gray-700 border-2 border-solid border-opacity-50 shadow-md text-white p-6 flex flex-col mx-4 lg:max-w-6xl lg:mx-auto',
    title:
      'text-5xl md:text-8xl xl:text-10xl mb-8 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 via-cyan-300 to-orange-400 filter drop-shadow-dark text-center md:text-left',
    subtitle:
      'text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-center text-cyan-100 filter drop-shadow-dark',
    contentContainer: 'p-10 text-white bg-gray-900 bg-opacity-80',
    contentWrapper:
      'p-10 min-h-1/4 md:min-h-1/2 flex flex-col justify-center max-w-2xl mx-auto w-full flex justify-center',
    sectionWrapper: 'mb-16',
    content: 'text-center max-w-4xl mx-auto',
    contentTitle:
      'max-w-4xl mx-auto text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-center text-cyan-100',
    leadText:
      'text-xl md:text-2xl lg:text-2xl font-bold mb-4 text-white text-center',
    logoContainer: 'max-w-xl',
    logo: 'w-full rounded-lg -rotate-6',
    loginTitle: 'text-xl md:text-2xl lg:text-3xl mb-4 text-cyan-600 hidden',
    loginHeader: 'hidden',
    loginContent: '',
    loginFooter: '',
    loginInfo: '',
    imageContainer: 'flex flex-col justify-center',
    image: 'rounded-lg rotate-6 shadow-md w-96',
    primaryButtonClasses: 'bg-cyan-600 text-white',
    secondaryButtonClasses: 'text-white border-white',
    legalContainer: 'self-center',
    legal: 'text-gray-200 hover:text-underline',
    footerContainer:
      'bg-gray-900 bg-opacity-90 flex flex-col justify-center text-center p-4',
    logobarContainer:
      'bg-white rounded-lg shadow-sm p-2 md:flex md:flex-row justify-center gap-4 max-w-full mb-6 text-center',
    logobarLogo: 'inline-flex',
    link: 'text-cyan-600 hover:text-cyan-400',
  },

  content: {
    title: 'AskDamaris Bot',
    subtitle: 'Explore the Frontier of Knowledge Interactions',

    content: [
      {
        title: 'About the AskDamaris Bot',
        type: 'full-width',
        text: `<p>The AskDamaris Bot is an exploration of the possibilities of connected knowledge using new AI tools that have recently become more widely accessible. It effortlessly merges two separate passions: Damaris’ love for finding the insights that drive our chaotic world and writing about it in fragmented pieces (though in her head they all add up to one gigantic mindmap), and Sappho and Josef’s passion for generating unheard of, enhanced user experiences using technology.</p>
      <div class="mt-4 flex flex-col md:grid md:grid-cols-3 gap-4">
        <div class="flex flex-col justify-center text-center">
          <img src="https://media.collabl.world/media/customers/askdamaris/shell/ask-me-anything.jpeg" class="rounded-lg shadow-md" />
          <h4 class="text-lg lg:text-xl text-bold text-white mt-4">Ask Me Anything</h4>
          <p class="text-base font-normal mt-2">Ask any question, and you shall receive an individual, AI-generated answer based on Damaris' writings.</p>
        </div>
        <div class="flex flex-col justify-center text-center">
          <img src="https://media.collabl.world/media/customers/askdamaris/shell/shots-of-insights.jpeg" class="rounded-lg shadow-md" />
          <h4 class="text-lg lg:text-xl text-bold text-white mt-4">Daily Shots of Insights</h4>
          <p class="text-base font-normal  mt-2">Discover over 500 "Daily Shots" with thoughts and learnings on personal growth, self-actualization and cats.</p>
        </div>
        <div class="flex flex-col justify-center text-center">
          <img src="https://media.collabl.world/media/customers/askdamaris/shell/3d-virtual-brain.jpeg" class="rounded-lg shadow-md" />
          <h4 class="text-lg lg:text-xl text-bold text-white mt-4">A 3D virtual brain</h4>
          <p class="text-base font-normal mt-2">Explore Damaris' ever-expanding copy of her very hungry brain in a beautiful 3D graph.</p>
        </div>
      </div>`,
      },
      {
        title: 'Sign up for our Newsletter',
        type: 'box',
        text: `<div class="mx-auto><p class="mb-4">We'll expand the AskDamaris Bot over time. Sign up for our newsletter if you want to follow along. We'll send (infrequent) updates and insights about the AskDamaris Bot and the frontier of knowledge interactions!</p>
        <div class="text-center mb-4">
        <a
          href="#"
          class="rm-open-popup v-btn v-btn--has-bg theme--light elevation-0 v-size--default bg-cyan-600 text-white mt-4"
        >
          Sign up
        </a></div>
        <p class="mb-4"><a href="https://twitter.com/ask_damaris" target="_blank" class="text-cyan-600 hover:text-cyan-400">Follow us on Twitter</a></p></div>`,
      },
    ],
    loginHeader: 'Explore the AskDamaris Bot',
    anonymousLoginTitle: ' ',
    anonymousLoginAction: 'Start Exploring',
    anonymousLoginAlternative: 'Explore without account',
    createAccount: 'Want to keep using the AskDamaris Bot? ',
    createAccountAction: 'Create an account',
    imprint:
      '<h3>Anbieter</h3><h4>Angaben gemäß § 5 TMG:</h4><p><strong>Der AskDamaris Bot wird angeboten von:</strong></p><p>Damaris Kröber Innovationsberatung<br>c/o Block Services<br>Stuttgarter Str. 106<br>70736 Fellbach<br>Germany<br>E-Mail: hello@damariskroeber.com</p></p><h4>Inhaltlich Verantwortliche gem. § 55 II RStV:</h4><p>Damaris Kröber<br>Anschrift wie oben</p><h3>Technische Umsetzung und Betrieb</h3><p><strong>Der AskDamaris ist auf der Plattform collabl.world umgesetzt. Diese wird betrieben von:</strong></p><p><strong>be-beta.io GbR</strong><br>Sappho Beck &amp; Josef Blank<br>Könnigder Dorfstr. 28<br>39629 Bismark (Altmark)<br>Telefon: +49 39089 319306<br>E-Mail: <a href="hello@bebeta.io">hello@bebeta.io</a> | <a href="mailto:kontakt@beteiligungsagentur.de">kontakt@beteiligungsagentur.de</a><br><a href="http://www.bebeta.io/">www.bebeta.io</a> | <a href="http://www.beteiligungsagentur.de/">www.beteiligungsagentur.de</a></p><p><strong>Vertretungsberechtigte Gesellschafter:innen:</strong><br>Sappho Beck, Josef Blank</p><p><strong>Umsatzsteuer-Identifikationsnummer gem. § 27a UStG:</strong><br>DE 263056854</p><p>',
    privacy: internalPrivacy,
  },
};
