import { useGlobalProps, useI18n } from '@/composables';
import {
  DataId,
  DataDocument,
  LocationDocument,
  Route,
  VmState,
  isSameView,
  isSamePath,
  isSameDocument,
  isSameScene,
  isSameBaseRoute,
  isSceneInPath,
  getScene,
  getFocus,
  CurriedFilterFunction,
  ObjectId,
  SceneDefinition,
  FilterFunction,
  resolveSceneDefinition,
} from '@fillip/api';

import { computed } from '@vue/composition-api';
import { useStore } from '../globals/useStore';
import { useFillip } from '../globals/useFillip';
import { useData } from '../data/useData';

export function useLocations() {
  const { store } = useStore();
  const { $me } = useFillip();
  const { getData } = useData();
  const { getGlobalProp } = useGlobalProps();
  const { tr } = useI18n();

  // Computeds

  const locations = computed(() => {
    return getAllLocations();
  });

  // Getters

  const getAllLocations = () => {
    return (store.getters.communityState?.locations || {}) as Record<
      DataId,
      LocationDocument
    >;
  };

  const getLocation = (id: DataId): Route => {
    if (!store.getters.communityState?.locations?.[id]) return null;
    return store.getters.communityState.locations[id].route;
  };

  const getMyLocation = (): Route => {
    if (!$me.value) return null;
    return getLocation($me.value.id);
  };

  const getLocationTitle = (route: Route) => {
    const focusedObject = getFocus(route);
    if (focusedObject) {
      return getGlobalProp('stations').find(
        (object) => object.id == focusedObject,
      ).title;
    } else {
      const scene = getScene(route);
      const { dataId, templateId } = resolveSceneDefinition(
        scene,
        getGlobalProp('sceneDefinitions'),
      );

      return dataId
        ? getData(dataId).info.title
        : getData(templateId).info.title;
    }
  };

  const getParticipantLocationTitle = (participantId: DataId) => {
    const location = getLocation(participantId);
    if (!location) return tr('participants.noLocation');
    return getLocationTitle(location);
  };

  // Route Filters

  // Example use case: All 'spectators' focusing the same object
  const atView: CurriedFilterFunction = (route: Route) => (doc: DataDocument) =>
    isSameView(route, getLocation(doc.id));

  // Example use case: All participants looking at the same user story in a sprint board,
  // excluding users who look at the same story on the "all stories" board
  const atPath: CurriedFilterFunction =
    (route: Route) => (doc: DataDocument) => {
      const docPath = getLocation(doc.id);
      return isSamePath(route, docPath);
    };

  // Example use case: "Also reading: ..."
  const atDocument: CurriedFilterFunction =
    (route: Route) => (doc: DataDocument) =>
      isSameDocument(getScene(route), getScene(getLocation(doc.id)));

  // Example use case: Wonder-like experience: Everybody in same room, but focussing different "circles"
  const atStation: CurriedFilterFunction =
    (ObjectId: ObjectId) => (doc: DataDocument) =>
      ObjectId == getFocus(getLocation(doc.id));

  const hasNoFocus: FilterFunction = (doc: DataDocument) => {
    const location = getLocation(doc.id);
    if (!location) return true;
    return !getFocus(location);
  };

  // Example use case: All participants in a same room that might be referenced from different paths,
  // e.g. a stage that's referenced from the room and the directors view
  // TODO: Doesn't work when explicitSceneDefinition and namedSceneDefinition point to same scene
  const atScene: CurriedFilterFunction =
    (route: Route) => (doc: DataDocument) =>
      isSameScene(getScene(route), getScene(getLocation(doc.id)));

  const atRouteOrChildren: CurriedFilterFunction =
    (baseRoute: Route) => (doc: DataDocument) =>
      isSameBaseRoute(baseRoute, getLocation(doc.id));

  const hasSceneInPath: CurriedFilterFunction =
    (scene: SceneDefinition) => (doc: DataDocument) =>
      isSceneInPath(scene, getLocation(doc.id));

  // Setters

  return {
    // Computeds
    locations,

    // Getters
    getParticipantLocationTitle,
    getAllLocations,
    getLocation,
    getMyLocation,
    atView,
    atPath,
    atDocument,
    atStation,
    atScene,
    atRouteOrChildren,
    hasSceneInPath,
    hasNoFocus,
    getLocationTitle,
  };
}
