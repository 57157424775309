import { getCurrentInstance } from '@vue/composition-api';

export function useVuetify() {
  const vm = (getCurrentInstance() as any).proxy;

  const vuetify = vm.$vuetify;

  return {
    vuetify,
  };
}
