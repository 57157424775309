














import { BaseElement } from './../core';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default {
  name: 'ElementButton',
  components: { BaseIcon },
  mixins: [BaseElement],
  computed: {
    icon() {
      return this.modules.element.buttonIcon;
    },
    text() {
      return this.modules.element.buttonText;
    },
    event() {
      return this.modules.element.buttonEvent;
    },
    arguments() {
      return this.modules.element?.buttonEventArgs || {};
    },
    href() {
      return this.modules.element.buttonHref || undefined;
    },
  },
  methods: {
    handleClick(event) {
      if (this.event) {
        this.$emit('event', {
          name: this.event,
          event: { ...event, ...this.arguments },
        });
      }
    },
  },
};
