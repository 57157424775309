import * as colors from 'twind/colors';

export default {
  // TODO: Set font stacks via theme / theme manager
  // fontFamily: {
  //   sans: [
  //     'Roboto',
  //     'ui-sans-serif',
  //     'system-ui',
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     'Segoe UI',
  //     'Helvetica Neue',
  //     'Arial',
  //     'Noto Sans',
  //     'sans-serif',
  //     'Apple Color Emoji',
  //     'Segoe UI Emoji',
  //     'Segoe UI Symbol',
  //     'Noto Color Emoji',
  //   ],
  //   serif: [
  //     'ui-serif',
  //     'Georgia',
  //     'Cambria',
  //     'Times New Roman',
  //     'Times',
  //     'serif',
  //   ],
  //   mono: [
  //     'ui-monospace',
  //     'SFMono-Regular',
  //     'Menlo',
  //     'Monaco',
  //     'Consolas',
  //     'Liberation Mono',
  //     'Courier New',
  //     'monospace',
  //   ],
  // },
  // Match breakpoints with vuetify breakpoints
  // TODO: Decide on future breakpoints
  screens: {
    xs: { max: '599px' },
    sm: '600px',
    md: '960px',
    lg: '1264px',
    xl: '1904px',
  },
  extend: {
    transitionProperty: {
      width: 'width',
      height: 'height',
      minWidth: 'min-width',
      maxWidth: 'max-width',
      minHeight: 'min-height',
      maxHeight: 'max-height',
      spacing: 'margin, padding',
    },
    columns: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
    },
    gridTemplateColumns: {
      '1-2': '1fr 2fr',
      '2-1': '2fr 1fr',
      '1-3': '1fr 3fr',
      '3-1': '3fr 1fr',
      '1-4': '1fr 4fr',
      '4-1': '4fr 1fr',
      '1-5': '1fr 5fr',
      '5-1': '5fr 1fr',
      'auto-1fr': 'auto minmax(0, 1fr)',
      '1fr-auto': '1fr auto',
      'auto-1fr-auto': 'auto 1fr auto',
    },
    gridTemplateRows: {
      '1-2': '1fr 2fr',
      '2-1': '2fr 1fr',
      '1-3': '1fr 3fr',
      '3-1': '3fr 1fr',
      '1-4': '1fr 4fr',
      '4-1': '4fr 1fr',
      '1-5': '1fr 5fr',
      '5-1': '5fr 1fr',
      'auto-1fr': 'auto minmax(0, 1fr)',
      '1fr-auto': '1fr auto',
      'auto-1fr-auto': 'auto 1fr auto',
    },
    scale: {
      101: '1.01',
      102: '1.02',
      103: '1.03',
      flip: '-1',
    },
    minHeight: {
      '1/4': '25vh',
      '1/2': '50vh',
      '3/4': '75vh',
    },
    maxHeight: {
      '1/4': '25vh',
      '1/2': '50vh',
      '3/4': '75vh',
    },
    height: {
      '1/4-screen': '25vh',
      '1/2-screen': '50vh',
      '3/4-screen': '75vh',
    },
    zIndex: {
      '-10': '-10',
    },
    dropShadow: {
      white: '0 2px 1px rgba(255, 255, 255, 0.2)',
      'white-lg': '0 4px 3px rgba(255, 255, 255, 0.2)',
      dark: '0 0 4px rgba(0, 0, 0, 0.5)',
    },
    boxShadow: {
      'lg-od': '0 0 15px -3px rgb(0 0 0 / 0.1), 0 0 6px -4px rgb(0 0 0 / 0.1)',
    },
    // Add color helpers based in vuetify theme components
    colors: {
      ...colors,
      primary: {
        lightest: 'var(--v-primary-lighten3)',
        light: 'var(--v-primary-lighten1)',
        DEFAULT: 'var(--v-primary-base)',
        dark: 'var(--v-primary-darken1)',
        darkest: 'var(--v-primary-darken3)',
      },
      primaryContrast: {
        lightest: 'var(--v-primaryContrast-lighten3)',
        light: 'var(--v-primaryContrast-lighten1)',
        DEFAULT: 'var(--v-primaryContrast-base)',
        dark: 'var(--v-primaryContrast-darken1)',
        darkest: 'var(--v-primaryContrast-darken3)',
      },
      secondary: {
        lightest: 'var(--v-secondary-lighten3)',
        light: 'var(--v-secondary-lighten1)',
        DEFAULT: 'var(--v-secondary-base)',
        dark: 'var(--v-secondary-darken1)',
        darkest: 'var(--v-secondary-darken3)',
      },
      accent: {
        lightest: 'var(--v-accent-lighten3)',
        light: 'var(--v-accent-lighten1)',
        DEFAULT: 'var(--v-accent-base)',
        dark: 'var(--v-accent-darken1)',
        darkest: 'var(--v-accent-darken3)',
      },
      layout: {
        lightest: 'var(--v-layout-lighten3)',
        light: 'var(--v-layout-lighten1)',
        DEFAULT: 'var(--v-layout-base)',
        dark: 'var(--v-layout-darken1)',
        darkest: 'var(--v-layout-darken3)',
      },
      error: {
        lightest: 'var(--v-error-lighten3)',
        light: 'var(--v-error-lighten1)',
        DEFAULT: 'var(--v-error-base)',
        dark: 'var(--v-error-darken1)',
        darkest: 'var(--v-error-darken3)',
      },
      info: {
        lightest: 'var(--v-info-lighten3)',
        light: 'var(--v-info-lighten1)',
        DEFAULT: 'var(--v-info-base)',
        dark: 'var(--v-info-darken1)',
        darkest: 'var(--v-info-darken3)',
      },
      success: {
        lightest: 'var(--v-success-lighten3)',
        light: 'var(--v-success-lighten1)',
        DEFAULT: 'var(--v-success-base)',
        dark: 'var(--v-success-darken1)',
        darkest: 'var(--v-success-darken3)',
      },
      warning: {
        lightest: 'var(--v-warning-lighten3)',
        light: 'var(--v-warning-lighten1)',
        DEFAULT: 'var(--v-warning-base)',
        dark: 'var(--v-warning-darken1)',
        darkest: 'var(--v-warning-darken3)',
      },
      background: {
        lightest: 'var(--v-background-lighten3)',
        light: 'var(--v-background-lighten1)',
        DEFAULT: 'var(--v-background-base)',
        dark: 'var(--v-background-darken1)',
        darkest: 'var(--v-background-darken3)',
      },
    },
  },
};
