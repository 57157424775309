/* eslint-disable no-unused-vars */

import { DataDocument, maybeProperty, check } from '@fillip/api';
import { default as dayjsLib, OpUnitType, UnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjsIsToday from 'dayjs/plugin/isToday';
import dayjsWeekday from 'dayjs/plugin/weekday';
import dayjsBusinessDays from 'dayjs-business-days';
import dayjsIsoWeek from 'dayjs/plugin/isoWeek';

require('dayjs/locale/en');
require('dayjs/locale/de');
import { lodashFp as _ } from '..';

interface ExtendedDayJs extends dayjsLib.Dayjs {
  isBusinessDay: () => boolean;
  businessDaysAdd: (value: number) => ExtendedDayJs;
  businessDaysSubtract: (value: number) => ExtendedDayJs;
  businessDiff: (value: Date) => Number;
  nextBusinessDay: () => ExtendedDayJs;
  prevBusinessDay: () => ExtendedDayJs;
  businessDaysInMonth: () => [ExtendedDayJs];
  businessWeeksInMonth: () => [[ExtendedDayJs]];
  isHoliday: () => boolean;
  isToday: () => boolean;
}

dayjsLib.extend(relativeTime);
dayjsLib.extend(dayjsIsBetween);
dayjsLib.extend(customParseFormat);
dayjsLib.extend(updateLocale);
dayjsLib.extend(dayjsIsToday);
dayjsLib.extend(dayjsBusinessDays);
dayjsLib.extend(dayjsWeekday);
dayjsLib.extend(dayjsIsoWeek);

// TODO: Set locale based on community locale
// dayjsLib.locale(locale_de);
dayjsLib.locale('en');
dayjsLib.updateLocale('en', {
  weekStart: 1,
});

export const dayjs = (dateTime?: any, format?: string): ExtendedDayJs =>
  dayjsLib(dateTime, format) as ExtendedDayJs;

export const timeSinceCreated =
  (compareTime: number | string, unit: UnitType = 'ms') =>
  (doc: DataDocument): number => {
    return dayjs(doc.info.createdAt).diff(compareTime, unit);
  };

export const isOlderThan =
  (limit: number | string) =>
  (doc: DataDocument): boolean => {
    return timeSinceCreated(Date.now())(doc) < -limit;
  };

export const isNewerThan =
  (limit: number | string) =>
  (doc: DataDocument): boolean => {
    return timeSinceCreated(Date.now())(doc) > -limit;
  };

export const getTime = (timestamp: number, format: string = 'HH:mm'): string =>
  dayjs(timestamp).format(format);
export const getDate = (
  timestamp: number,
  format: string = 'DD.MM.YYYY',
): string => dayjs(timestamp).format(format);

export const getDateTime = (
  timestamp: number,
  format: string = 'DD.MM.YYYY HH:mm',
): string => dayjs(timestamp).format(format);

export const isSameDateTime =
  (pathOrTimestamp: string | number, unit?: UnitType, date?: any) =>
  (doc: DataDocument): Boolean => {
    const timestamp: number =
      typeof pathOrTimestamp == 'number'
        ? pathOrTimestamp
        : _.get(maybeProperty(pathOrTimestamp))(doc);
    return dayjs(date).isSame(timestamp, unit);
  };

export const isBetweenDateTime =
  (start: number | string, end: number | string | number, unit?: OpUnitType) =>
  (doc: DataDocument) => {
    const timestamp1: number =
      typeof start == 'number' ? start : _.get(maybeProperty(start))(doc);
    const timestamp2: number =
      typeof end == 'number' ? end : _.get(maybeProperty(end))(doc);

    dayjs().isBetween(timestamp1, timestamp2, unit);
  };

export const isNow =
  (start: any, end: any, unit?: OpUnitType, today?: any) =>
  (doc: DataDocument | string) => {
    const timestamp1: number =
      typeof start == 'number' ? start : _.get(maybeProperty(start))(doc);
    const timestamp2: number =
      typeof end == 'number' ? end : _.get(maybeProperty(end))(doc);
    const date = dayjs(today);
    return date.isBetween(timestamp1, timestamp2, unit);
  };

export const isToday = (date?: any): boolean => {
  return dayjs(date).isToday();
};

export const isBefore = (dateToCompare: any, date?: any): boolean => {
  return dayjs(dateToCompare).isBefore(dayjs(date));
};

export const isAfter = (dateToCompare: any, date?: any): boolean => {
  return dayjs(dateToCompare).isBefore(dayjs(date));
};

export const nextDay = (amount: number = 1, date?: any): ExtendedDayJs => {
  if (date === null) date = undefined;
  return dayjs().add(amount, 'days') as ExtendedDayJs;
};

export const prevDay = (amount: number = 1, date?: any): ExtendedDayJs => {
  if (date === null) date = undefined;
  return dayjs().subtract(amount, 'days') as ExtendedDayJs;
};

export const isBusinessDay = (date?: any) => dayjs(date).isBusinessDay();

export const isHoliday = (date?: any) => dayjs(date).isHoliday();

export const nextBusinessDay = (
  amount: number = 1,
  date?: any,
): ExtendedDayJs => {
  let result = dayjs(date);
  [...Array(amount)].forEach(() => (result = result.nextBusinessDay()));
  return result;
};

export const prevBusinessDay = (
  amount: number = 1,
  date?: any,
): ExtendedDayJs => {
  let result = dayjs(date);
  [...Array(amount)].forEach(() => (result = result.prevBusinessDay()));
  return result;
};

export const todayOrNextBusinessDay = (date?: any): ExtendedDayJs => {
  const today = dayjs(date);
  return today.isBusinessDay() ? today : today.nextBusinessDay();
};

export const weekday = (fromNow: number = 0, date?: any): ExtendedDayJs => {
  return dayjs(date).weekday(fromNow) as ExtendedDayJs;
};

export const dateTimeUtils = {
  dayjs,
  parseDateTime: dayjs,
  timeSinceCreated,
  isOlderThan,
  isNewerThan,
  getTime,
  getDate,
  getDateTime,
  isSameDateTime,
  isBetweenDateTime,
  isNow,
  isToday,
  isBefore,
  isAfter,
  isAtDate: isToday,
  nextDay,
  prevDay,
  isBusinessDay,
  nextBusinessDay,
  prevBusinessDay,
  todayOrNextBusinessDay,
  check,
};
