import { ParticipantDocument } from '@/features/main/core';
import { DataDocument, EvaluationContext, Id } from '@fillip/api';

interface FilterOptions {
  $id: Id;
  filterType: 'and' | 'or';
  filterBy: string;
  filterCollectionId: Id;
}

export function useFilter(
  env: any,
  context: EvaluationContext,
  options: FilterOptions,
) {
  const {
    $id,
    filterType = 'and',
    filterBy = '',
    filterCollectionId = '',
  } = options;

  if (!$id) throw new Error('Missing $id');

  const {
    getList,
    getVolatileProp,
    setVolatileProp,
    pushToVolatile,
    removeFromVolatile,
    clearVolatileProp,
    matchQuery,
    maybeProperty,
    property,
  } = env;

  const filterOptions = getList(filterCollectionId)();

  const FILTER_PROP = `filter-${filterBy}`;

  let current: string[] = [];

  const updateCurrent = () => {
    current = getVolatileProp($id, FILTER_PROP, []);
  };

  updateCurrent();

  const set = (value: string | string[]) => {
    setVolatileProp($id, FILTER_PROP, Array.isArray(value) ? value : [value]);
    updateCurrent();
  };

  const reset = () => {
    clearVolatileProp($id, FILTER_PROP);
    updateCurrent();
  };

  const addItem = (value: string) => {
    pushToVolatile($id, FILTER_PROP, value);
    updateCurrent();
  };

  const removeItem = (value: string) => {
    removeFromVolatile($id, FILTER_PROP, value);
    updateCurrent();
  };

  const toggleItem = (value: string) => {
    if (current.includes(value)) removeItem(value);
    else addItem(value);
  };

  const isActive = (value: string) => current.includes(value);

  const isEmpty = current.length === 0;

  const apply = (doc: DataDocument) => {
    if (!current.length) return true;

    if (filterType === 'or') {
      return matchQuery({
        [maybeProperty(filterBy)]: { $in: current },
      })(doc);
    }

    if (filterType === 'and') {
      const tags = property(filterBy)(doc);
      return current.every((f) => tags.includes(f));
    }
  };

  const result = {
    current,
    options: filterOptions,
    toggleItem,
    reset,
    set,
    isActive,
    isEmpty,
    apply,
  };

  return result;
}
