import type { Shell } from './../types';
import { internalPrivacy, generateExternalPrivacyPolicy } from '../utils';

export const local: Shell = {
  name: 'local',
  title: 'Collabl LOCAL',
  language: 'en',
  logo: '',
  image: '',
  communityDesignation: {
    singular: 'Community',
    plural: 'Communities',
  },
  allowSignup: true,
  allowLogin: true,
  allowGoogleLogin: false,
  allowDiscordLogin: false,
  allowAnonymousLogin: true,
  hideTerms: true,
  classes: {
    siteContainer: 'bg-[#ecf0f5] min-h-full flex flex-col',
    headerContainer:
      'p-8 pb-20 md:pt-12 md:px-24 md:pb-32 p-10 flex flex-col justify-center items-center bg-[rgba(63,127,130,0.25)]',
    headerContentContainer: 'flex flex-col justify-center my-4',
    headerRow: 'flex flex-col md:flex-row justify-center items-center gap-8',
    siteContent: '-mt-20 md:-mt-24',
    contentCardContainer:
      'h-full w-full flex flex-col justify-items-start md:justify-center pl-0 md:pl-24',
    contentCard: 'relative bg-white rounded-lg p-6 shadow-lg-od',
    contentBox: 'bg-white rounded-lg p-6 shadow-lg',
    title:
      'font-extrabold text-[rgba(63,127,130,1)] text-5xl md:text-6xl xl:text-8xl mb-2',
    subtitle: 'text-xl md:text-2xl lg:text-3xl mb-4 text-gray-600',
    contentContainer: 'p-10 mb-4',
    contentWrapper:
      'p-10 flex flex-col justify-center max-w-4xl mx-auto w-full flex justify-center',
    sectionWrapper: 'mb-16',
    sectionContent: 'mx-auto max-w-full lg:max-w-4xl xl:max-w-6xl',
    content: 'prose',
    contentTitle: 'text-xl md:text-2xl lg:text-3xl font-bold text-gray-600',
    logoContainer: '',
    logo: 'w-auto h-48 lg:h-96',
    imageContainer: '',
    image: 'h-48 lg:h-96',
    primaryButtonClasses: 'bg-[rgba(47,185,188,1)] text-white',
    legalContainer: 'self-center',
    legal: 'text-gray-600 hover:text-underline',
    footerContainer: 'flex flex-col justify-center text-center p-4',
    logobarContainer:
      'bg-white rounded-lg shadow-sm p-2 md:flex md:flex-row justify-center gap-4 max-w-full mb-6 text-center',
    logobarLogo: 'inline-flex max-w-full',
    loginTitle: 'text-2xl font-semibold mb-4',
    link: 'text-underline text-[rgba(47,185,188,1)] hover:text-[rgba(63,127,130,1)]',
  },
  content: {
    title: 'Collabl.ai',
    subtitle: 'Empowering Collaboration between Humans and AI',
    leadText:
      'Collabl.ai is a platform for collaboration between humans and AI. It is a place where people can work together with AI to solve problems and create new things.',
    // moreText: 'Public Access',
    // moreLink: 'https://collabl.ai',
    imprintLink: 'https://fillip.ai/de/impressum/',
    privacy: internalPrivacy,
  },
};
