import { Tag } from '@fillip/api';
import BlockEditorDefault from './BlockEditorDefault.vue';
import BlockEditorHeading1 from './BlockEditorHeading1.vue';
import BlockEditorHeading2 from './BlockEditorHeading2.vue';
import BlockEditorHeading3 from './BlockEditorHeading3.vue';
import BlockEditorHeading4 from './BlockEditorHeading4.vue';
import BlockEditorText from './BlockEditorText.vue';
import BlockEditorStyledText from './BlockEditorStyledText.vue';
import BlockEditorMarkdown from './BlockEditorMarkdown.vue';
import BlockEditorHtml from './BlockEditorHtml.vue';
import BlockEditorReference from './BlockEditorReference.vue';
import BlockEditorEmbed from './BlockEditorEmbed.vue';
import BlockEditorImage from './BlockEditorImage.vue';
import BlockEditorTable from './BlockEditorTable.vue';
import BlockEditorQuote from './BlockEditorQuote.vue';

export const BlockEditorByTag = {
  h1: BlockEditorHeading1,
  h2: BlockEditorHeading2,
  h3: BlockEditorHeading3,
  h4: BlockEditorHeading4,
  text: BlockEditorText,
  styledText: BlockEditorStyledText,
  quote: BlockEditorQuote,
  markdown: BlockEditorMarkdown,
  html: BlockEditorHtml,
  reference: BlockEditorReference,
  embed: BlockEditorEmbed,
  image: BlockEditorImage,
  table: BlockEditorTable,
  default: BlockEditorDefault,
};

export const BlockEditorComponent = (tag: Tag) => {
  return BlockEditorByTag[tag] || BlockEditorByTag['default'];
};
