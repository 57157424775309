export * from './Object3DAnimator';
export * from './Transform3D';

import { isObject } from 'lodash/fp';
import { TransitionOptions, transitions } from '@fillip/api';

export function resolveTransition(transition: TransitionOptions) {
  if (transition === true) return transitions['default'];
  if (typeof transition == 'string' && transition in transitions)
    return transitions[transition];
  if (isObject(transition) && 'to' in transition) return transition;

  return null;
}
