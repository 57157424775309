import { FillipVueClient } from '../client';
import { IFillipVueClientModule } from '../base.client';

export class FillipVueUtilitiesClient extends IFillipVueClientModule {
  constructor(public root: FillipVueClient) {
    super('utilities', root);
  }

  public async getFromExternal(url: string): Promise<any> {
    try {
      return await this.axios.post(
        `${this.config.apiUrl}/utilities/get-from-external`,
        {
          url,
        },
      );
    } catch (error) {
      this.handleException(error);
    }
  }
}
