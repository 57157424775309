import { FillipStoreMethods } from '@fillip/api';
import { computed, getCurrentInstance } from '@vue/composition-api';
import type { FillipVueClient } from '../../../../../libs/vue-client/src/client';

export function useFillip() {
  const vm = (getCurrentInstance() as any).proxy;

  const fillip = vm.$fillip as FillipVueClient;
  const fiStore = vm.$fiStore as FillipStoreMethods;
  const fiStoreDispatcher = vm.$fiStoreDispatcher as FillipStoreMethods;
  const $invoke = vm.$invoke;
  const can = vm.$can;
  const $ability = vm.$ability;

  const $me = computed(() => vm.$me);
  const $user = computed(() => vm.$user);
  const $community = computed(() => vm.$community);
  const $communitySlug = computed(() => vm.$communitySlug);

  const baseUrl = (window?.location?.origin || 'https://collabl.world') + '/';

  return {
    fillip,
    fiStore,
    fiStoreDispatcher,
    $invoke,
    $me,
    $user,
    $community,
    $communitySlug,
    can,
    $ability,
    baseUrl,
  };
}
