import {
  DataId,
  DataDocument,
  rootCircleId,
  CircleDocument,
  getFocus,
} from '@fillip/api';

import { useData, useFillip, useLocations } from '@/composables';

import { computed } from '@vue/composition-api';
import { deepEqual } from 'fast-equals';

export function useCircles() {
  const { getData, getDataByTag } = useData();
  const { getLocation } = useLocations();
  const { $me } = useFillip();

  // Computeds

  const circles = computed(() => {
    return getAllCircles();
  });

  // Getters

  const getAllCircles = (): DataDocument[] => {
    return getDataByTag('circle');
  };

  const getCircle = (id: DataId): CircleDocument => {
    const circle = getData(id);
    if (!circle || circle.tag?.tag != 'circle') return null;
    const leader = circle.circleSyncLocation?.leader
      ? getData(circle.circleSyncLocation.leader)
      : null;
    const route = getLocation(id);
    const locationId = getFocus(route);
    const location = null; //getObject[locationId];
    const amILeader = leader && leader.id == $me.value.id;
    const amIAttached = deepEqual(getLocation($me.value.id), route);
    const isLeaderOnline = leader?.participantStatus?.online;
    const amIFollowing = amIAttached && !amILeader && isLeaderOnline;
    // const amIFollowing =
    //   amIAttached &&
    //   !amILeader &&
    //   isLeaderOnline &&
    //   Boolean(circle.circleSyncLocation?.cameraLocation);
    return {
      ...circle,
      tag: { tag: 'circle' },
      leader,
      route,
      locationId,
      location,
      amILeader,
      amIAttached,
      amIFollowing,
      isLeaderOnline,
    };
  };

  const getMyCircle = (): CircleDocument => {
    if (!$me.value) return null;
    const myCircle = $me.value.participantCircle?.circle || rootCircleId;
    return getCircle(myCircle);
  };

  // Setters

  return {
    // Computeds
    circles,

    // Getters
    getAllCircles,
    getCircle,
    getMyCircle,
  };
}
