























import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'InputTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onEnter($event) {
      $event.preventDefault();
      $event.target.blur();
      this.$emit('enter', $event);
      return false;
    },
  },
});
