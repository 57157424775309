







































import { defineComponent } from '@vue/composition-api';
import AvatarEditor from '@/features/editor/modules/AvatarEditor.vue';
import PropertiesEditorLarge from '@/features/editor/modules/PropertiesEditorLarge.vue';
import TitleEditor from '@/features/editor/modules/TitleEditor.vue';
import { useAuth, useData } from '@/composables';
import { SchemaField } from '@fillip/api';

export default defineComponent({
  name: 'ParticipantProfile',
  components: {
    AvatarEditor,
    PropertiesEditorLarge,
    TitleEditor,
  },
  setup() {
    const { getData } = useData();
    const { logout, logoutGlobally, isAnonymous, editAccount } = useAuth();

    return {
      getData,
      logout,
      logoutGlobally,
      isAnonymous,
      editAccount,
    };
  },
  computed: {
    profileSchema(): SchemaField[] {
      const circleSchema = (this.getData('main') || {}).schema?.fields;
      if (circleSchema) {
        return circleSchema;
      }
      return [
        {
          title: this.$t('profile.description'),
          key: 'description',
          type: 'string',
        },
        {
          title: this.$t('profile.personalColor'),
          key: 'personalColor',
          type: 'color',
          options: {
            colorMode: 'rgb',
          },
        },
      ];
    },
  },
});
