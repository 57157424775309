


















































import { useData } from '@/composables';
import {
  defineComponent,
  computed,
  nextTick,
  Ref,
  ref,
  watch,
  PropType,
} from '@vue/composition-api';

export default defineComponent({
  name: 'DataSelector',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: '',
    },
    tags: {
      type: [Object, Array],
      default: () => ({
        option: 'exclude',
        tags: ['template'],
      }),
    },
    displayType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: function () {
        return this.$t('children.data');
      },
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array as PropType<string[] | undefined>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { getAll } = useData();
    // const { getDataIcon } = useTags();

    const data = computed(() => getAll());

    const buffer: Ref<string | string[]> = ref(null);
    const searchString = ref('');

    const items = computed(() => {
      let input =
        props.tags?.option !== 'all'
          ? data.value.filter((item) => {
              return props.tags.option === 'exclude'
                ? !props.tags.tags.includes(item.tag.tag)
                : Array.isArray(props.tags)
                ? props.tags.includes(item.tag.tag)
                : props.tags.tags.includes(item.tag.tag);
            })
          : data.value;
      if (props.filter?.length) {
        input = input.filter((item) => props.filter.includes(item.parentId));
      }
      return input.map((item) => ({
        value: item.id,
        text: item.info?.title || item.id,
        // icon: getDataIcon(item),
      }));
    });

    const onChange = () => {
      emit('input', buffer.value);
      emit('change', buffer.value);
      nextTick(() => {
        searchString.value = '';
      });
    };

    const removeDocument = (documentId) => {
      if (props.multiple) {
        const filtered = props.value.filter((d) => d !== documentId);
        buffer.value = filtered;
      } else {
        buffer.value = '';
      }
      onChange();
    };

    watch(
      () => props.value,
      (newValue) => (buffer.value = newValue),
      { immediate: true },
    );

    return {
      // getDataIcon,
      data,
      buffer,
      items,
      searchString,
      onChange,
      removeDocument,
    };
  },
});
