










import { RenderManager } from './renderer/RenderManager.js';
import ActionsLayer from '../actions/ActionsLayer.vue';
import { useFullscreen } from '@vueuse/core';
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  shallowRef,
  watch,
} from '@vue/composition-api';
import * as Location3D from '@/utils/location3D';
import { defineComponent } from '@vue/composition-api';
import { useStore } from '@/composables';
import type { Scene as ThreeScene } from 'three';
import type { StyleValue } from '@vue/runtime-dom';

export default defineComponent({
  name: 'Canvas',
  components: {
    ActionsLayer,
  },
  setup(props, { emit }) {
    const fullscreenElement = ref(null);
    const {
      isFullscreen,
      isSupported: isFullScreenSupported,
      enter: enterFullscreen,
    } = useFullscreen(fullscreenElement);

    const { store } = useStore();

    const canvas = ref(null);
    const sceneryCanvas = ref(null);

    const viewport = reactive({
      width: 0,
      height: 0,
    });

    const windowSize = reactive({
      width: 0,
      height: 0,
    });

    const themeManager = inject('themeManager') as any;
    const camera = inject('camera') as any;

    const worldLocation = Location3D.origin;
    const canvasLeft = computed<string | number>(() => {
      if (isFullscreen.value) return 0;
      if (store.state.appDrawer.width == 0) return 0;
      return windowSize.width - store.state.appDrawer.width < 300
        ? '45px'
        : store.state.appDrawer.width + 'px';
    });

    const canvasBottom = computed<string | number>(() => {
      if (isFullscreen.value) return 0;
      return themeManager.value.appBarHeight || '80px';
    });

    const canvasStyle = computed<StyleValue>(() => {
      return {
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        left: canvasLeft.value,
        width: `calc(100vw - ${canvasLeft.value || '0px'})`,
        bottom: canvasBottom.value,
        height: `calc(100dvh - ${canvasBottom.value || '0px'})`,
      };
    });

    const worldStyle = computed<StyleValue>(() => {
      return {
        'user-select': 'none',
        background: themeManager.value.canvasBackground,
      };
    });

    const getCanvasDomElement = () => {
      return canvas.value;
    };

    const render = (context, immediate = false) => {
      if (!camera.value) {
        return;
      }
      renderManager.value.render(context, immediate);
    };

    const renderManager = shallowRef<RenderManager>(null);
    const $object3D = shallowRef<ThreeScene>(null);

    onMounted(() => {
      renderManager.value = new RenderManager(
        canvas.value,
        sceneryCanvas.value,
        // @ts-ignore
        ({ width, height }) => {
          viewport.width = width;
          viewport.height = height;
          windowSize.width = window.innerWidth;
          windowSize.height = window.innerHeight;
          emit('resize', { width, height });
        },
      );

      $object3D.value = renderManager.value.scene;
    });

    onBeforeUnmount(() => {
      renderManager.value.destroy();
    });

    watch(
      () => camera.value,
      (newValue) => {
        if (newValue) {
          renderManager.value.setCamera(newValue.get());
        }
      },
    );

    return {
      $object3D,
      renderManager,
      render,
      fullscreenElement,
      isFullScreenSupported,
      isFullscreen,
      enterFullscreen,
      canvas,
      sceneryCanvas,
      viewport,
      windowSize,
      worldLocation,
      canvasLeft,
      canvasBottom,
      canvasStyle,
      worldStyle,
      getCanvasDomElement,
    };
  },
});
