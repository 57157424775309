import { FillipVueClient } from './client';

class FillipPlugin {
  public instance: FillipVueClient;

  install(Vue, config) {
    if (this.instance)
      throw new Error('Client is already initialized in this app');
    if (!config.store)
      throw new Error(
        'Missing vuex store instance in config for Fillip Vue Plugin',
      );
    this.instance = new FillipVueClient(config.store, config);

    Vue.prototype.$fillip = this.instance;
  }
}

export const Fillip = new FillipPlugin();
