import { getCurrentInstance } from '@vue/composition-api';

export function useRouter() {
  const vm = (getCurrentInstance() as any).proxy;

  const router = vm.$router;
  const $route = vm.$route;

  const extractCommunitySlugFromTarget = () => {
    let target: string = router.currentRoute.query?.target;
    if (!target) return null;

    target = target.slice(1);
    const parts = target.split('/');

    if (parts[0].startsWith('communities') || parts[0].startsWith('auth')) {
      return null;
    }
    return parts[0];
  };

  const gotoTargetOrCommunities = () => {
    if (router.currentRoute.query?.target) {
      router.push({ path: router.currentRoute.query.target });
    } else {
      router.push({ name: 'Communities' });
    }
  };

  return {
    router,
    $route,
    gotoTargetOrCommunities,
    extractCommunitySlugFromTarget,
  };
}
