



























































































































import Vue from 'vue';
import {
  DataDocument,
  ElementTable,
  Id,
  TableColumn,
  TableRow,
} from '@fillip/api';
import { BaseElement } from './../core';
import { get } from 'lodash/fp';
import DOMPurify from 'dompurify';
import { useData } from '@/composables';
import InputStyledText from '@/components/input/styled-text/InputStyledText.vue';

export default Vue.extend({
  name: 'ElementTable',
  components: {
    InputStyledText,
  },
  mixins: [BaseElement],
  props: {
    allowEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const { getData, updateProperties, updatePath } = useData();

    return {
      getData,
      updateProperties,
      updatePath,
    };
  },
  data() {
    return {
      editingCell: null,
      editingColumn: null,
      cellContainerClasses:
        'w-full min-h-full flex flex-col whitespace-pre-wrap',
    };
  },
  computed: {
    elementTable(): ElementTable {
      return this.modules?.element;
    },
    useQuery() {
      return this.elementTable?.tableUseQuery;
    },
    allowContentEditing() {
      return Boolean(this.elementTable?.tableAllowContentEditing);
    },
    query(): Array<DataDocument> {
      return this.elementTable?.tableQuery || [];
    },
    rowData(): TableRow[] {
      return this.elementTable?.tableRowData || [];
    },
    columns(): TableColumn[] {
      return this.elementTable?.tableColumns || [];
    },
    rows(): TableRow[] {
      return (this.useQuery ? this.query : this.rowData).map(
        (record: Record<string, any>) => {
          return this.columns.map((column: TableColumn) => {
            return get(column.path, record) || '';
          });
        },
      );
    },
    documentId(): Id {
      const [templateId, dataId] = this.id.split(':');
      if (templateId == dataId) return templateId;
      return dataId;
    },
    classes() {
      return {
        cells:
          'px-5 py-3 border-solid border-0 border-b border-b-2 border-gray-200 text-gray-800 text-left text-sm',
      };
    },
  },
  methods: {
    toggleCellEditing(rowIndex, columnIndex) {
      if (!this.canColumnBeEdited(columnIndex)) return false;
      if (this.isCellEditing(rowIndex, columnIndex)) return false;
      if (this.editingColumn) this.toggleColumnEditing();
      this.editingCell = [rowIndex, columnIndex];
    },
    canColumnBeEdited(columnIndex) {
      if (!this.allowEditing && !this.allowContentEditing) return;
      if (this.columns[columnIndex].locked) return;
      return true;
    },
    updateCellContent({ html }, rowIndex, columnIndex) {
      if (this.useQuery) {
        const id = this.query[rowIndex].id;
        if (!id) return;
        this.updatePath(id, this.columns[columnIndex].path, html, false);
      } else {
        this.$emit('updateCellContent', { html, rowIndex, columnIndex });
      }
      this.editingCell = null;
    },
    toggleColumnEditing(columnIndex) {
      if (!this.allowEditing) return false;
      if (columnIndex === undefined) {
        this.editingColumn = null;
        return;
      }
      if (this.isColumnEditing(columnIndex)) return;
      if (this.editingCell) this.editingCell = null;

      this.editingColumn = columnIndex;
    },
    updateColumnHeader({ html }, index) {
      if (!this.allowEditing) return;
      this.$emit('updateColumn', { content: html, index });
      this.editingColumn = null;
    },
    getCellClasses(columnIndex) {
      return [this.classes.cells, this.columns[columnIndex]?.classes || ''];
    },
    isCellEditing(rowIndex, cellIndex) {
      if (!this.editingCell) return false;
      return (
        this.editingCell[0] == rowIndex && this.editingCell[1] == cellIndex
      );
    },
    isColumnEditing(columnIndex) {
      return (
        this.editingColumn != undefined && this.editingColumn == columnIndex
      );
    },
    addRow() {
      this.$emit('addRow');
    },
    removeRow(index) {
      this.$emit('removeRow', index);
    },
    addColumn() {
      this.$emit('addColumn');
    },
    removeColumn(index) {
      this.$emit('removeColumn', index);
    },
    sanitizeContent(content: string) {
      return DOMPurify.sanitize(content);
    },
  },
});
