













import { useAuth, useNav, usePermissions, useRouter } from '@/composables';
import { ActionControl } from '@fillip/api';
import { defineComponent, computed, inject, Ref } from '@vue/composition-api';

export default defineComponent({
  name: 'MainMenu',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { canEditCommunity, canSwitchCommunity } = usePermissions();
    const { router } = useRouter();
    const { $dialog } = useNav();

    const canvas = inject('canvas') as Ref<any>;
    const themeManager = inject('themeManager') as Ref<any>;
    const { logout } = useAuth();

    const isFullscreenSupported = computed(
      () => canvas.value.isFullScreenSupported,
    );
    const enterFullscreen = () => canvas.value.enterFullscreen();

    const appBarHeight = computed(() => themeManager.value.appBarHeight);

    const close = () => emit('input', false);

    const actions: ActionControl[] = [
      {
        type: 'action',
        icon: 'sign-out',
        color: 'error',
        title: 'logout.logout',
        action: () => logout,
      },
      {
        type: 'action',
        icon: 'repeat',
        title: 'communities.switchCommunity',
        if: canSwitchCommunity.value,
        action: () => router.push({ name: 'Communities' }),
      },
      // {
      //   type: 'action',
      //   icon: 'tv-music',
      //   title: 'videoConference.selectYourDevices',
      //   action: () => $dialog.push({ name: 'SelectDevices' }),
      // },
      {
        type: 'action',
        icon: 'expand',
        title: 'videoConference.enterFullscreen',
        if: isFullscreenSupported.value,
        action: () => enterFullscreen,
      },
      {
        type: 'action',
        icon: 'debug',
        title: 'debug.debug',
        if: canEditCommunity.value,
        action: () => $dialog.push({ name: 'WorldDebug' }),
      },
    ];
    return {
      canEditCommunity,
      canSwitchCommunity,
      isFullscreenSupported,
      enterFullscreen,
      logout,
      appBarHeight,
      close,
      actions,
    };
  },
});
