









































import { useFillip, useI18n, useRouter, useShell } from '@/composables';
import { computed, defineComponent, ref, unref } from '@vue/composition-api';
import { Role } from '@fillip/api';

import UsernameInput from '@/components/form-inputs/UsernameInput.vue';
import PasswordInput from '@/components/form-inputs/PasswordInput.vue';
import EmailInput from '@/components/form-inputs/EmailInput.vue';

export default defineComponent({
  name: 'AuthMethodSignup',
  components: {
    UsernameInput,
    EmailInput,
    PasswordInput,
  },
  props: {
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { shell, toggleLoading } = useShell();
    const { fillip } = useFillip();
    const { router } = useRouter();

    const form = ref(null);

    const user = ref({
      username: '',
      email: '',
      password: '',
      shell: shell.value.name,
      domain: window.location.hostname,
      role: shell.value.restrictCommunities ? Role.RESTRICTED : Role.HOST,
    });

    const submit = async () => {
      toggleLoading(true);
      try {
        await fillip.auth.registerWithUsernameEmailAndPassword(unref(user));
        router.push({ name: 'ConfirmationPending' });
      } catch (e) {
        // TODO: Handle error
        console.error(e);
        toggleLoading(false);
      }
    };

    const submitDisabled = computed(() => {
      return (
        !unref(user).username || !unref(user).email || !unref(user).password
      );
    });

    return {
      user,
      submit,
      form,
      shell,
      submitDisabled,
    };
  },
});
