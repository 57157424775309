




































































































import Vue from 'vue';
import { useSave, useVolatileData } from '@/composables';
import { BaseModuleEditor } from '../base';
import { useData } from '@/composables/data/useData';
import {
  frontendPrefixMarker,
  ModuleChildrenMeta,
  rootDataId,
  PropsDefinition,
} from '@fillip/api';
import clone from 'rfdc/default';
import PrimitiveTemplateSelector from '@/components/primitive-editor/types/PrimitiveTemplateSelector.vue';
import { FillipCommands } from '@/features/main/core';
import store from '@/store';
import { getCurrentInstance } from '@vue/composition-api';

export default Vue.extend({
  name: 'ChildrenEditor',
  components: {
    PrimitiveTemplateSelector,
  },
  mixins: [BaseModuleEditor],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const vm = (getCurrentInstance() as any).proxy as Required<FillipCommands>;
    const { buffer, save } = useSave(props, {
      path: 'children',
    });
    const { getData } = useData();
    const { setVolatileProp } = useVolatileData();

    const init = () => {
      buffer.value = clone(ModuleChildrenMeta.default);
      save();
    };

    const defaultItem = ModuleChildrenMeta.childDefault;

    // TODO: Move in DraggableArrayInput again
    const addItem = () => {
      buffer.value.default.push(clone(defaultItem));
      save();
    };
    const removeItem = (index) => {
      buffer.value.default.splice(index, 1);
      save();
    };

    const openTemplate = (id) => {
      if (!id || id.startsWith('function:')) return;
      if (!getData(id)) {
        store.dispatch('notify', {
          type: 'error',
          text: "Find document failed, document doesn't exist",
        });
        console.error(
          "Document doesn't exist, check for falty 'parentId' in your template",
        );
        return;
      }
      setVolatileProp(rootDataId, 'selection', id);
      if (vm.$drawer.currentRoute.fullPath != '/editor') {
        vm.$drawer.push({ name: 'Editor' });
      }
    };

    const getInterfaceProps = (id: string) => {
      if (!id || id.startsWith(frontendPrefixMarker)) return;
      const interfaceDefinition = getData(id)?.interfaceDefinition;
      const result = [];
      if (interfaceDefinition?.inheritFrom) {
        const interfaceSchema = getData(interfaceDefinition.inheritFrom)
          ?.interfaceDefinition?.props;
        if (interfaceSchema) result.push(...interfaceSchema);
      }
      if (interfaceDefinition?.props) result.push(...interfaceDefinition.props);
      return result;
    };

    const setCustomMapping = (index: number, prop: PropsDefinition): void => {
      const item = buffer.value.default[index];
      if (!item.propMappings) Vue.set(item, 'propMappings', {});
      Vue.set(item.propMappings, prop.key, prop.defaultValue);
      save();
    };

    const removeCustomMapping = (
      index: number,
      prop: PropsDefinition,
    ): void => {
      const item = buffer.value.default[index];
      Vue.delete(item.propMappings, prop.key);
      if (!Object.keys(item.propMappings).length)
        Vue.delete(item, 'propMappings');
      save();
    };

    return {
      buffer,
      save,
      init,
      openTemplate,
      getInterfaceProps,
      setCustomMapping,
      removeCustomMapping,
      addItem,
      removeItem,
    };
  },
});
