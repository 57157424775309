import { unref } from '@vue/composition-api';
import { useVuetify } from '../globals/useVuetify';
import {
  MaybeRef,
  useElementSize as originalUseElementSize,
} from '@vueuse/core';

// TODO: Fix element type
export function useElementSize(element: any) {
  const { vuetify } = useVuetify();

  const calculateWidthBreakpoint = (width: MaybeRef<number>) => {
    const widthInPixel = unref(width);
    const thresholds: any = vuetify.breakpoint.thresholds;
    const scrollBarWidth: number = vuetify.breakpoint.scrollBarWidth;
    if (thresholds) {
      if (widthInPixel < thresholds.xs) return 'xs';
      if (widthInPixel < thresholds.sm) return 'sm';
      if (widthInPixel < thresholds.md - scrollBarWidth) return 'md';
      if (widthInPixel < thresholds.lg - scrollBarWidth) return 'lg';
      if (widthInPixel >= thresholds.lg - scrollBarWidth) return 'xl';
    }
    return 'xs';
  };

  const { width, height } = originalUseElementSize(element);
  const breakpoint = calculateWidthBreakpoint(width);

  return {
    width,
    height,
    breakpoint,
  };
}
