import Vue from 'vue';
import { useData } from '@/composables/data';
import { rootDataId } from '@fillip/api';

export default Vue.extend({
  setup() {
    const { getData } = useData();
    return { getData };
  },
  computed: {
    appBarBackground() {
      return this.properties.appBarBackground?.value || 'white';
    },
    appBarShadow() {
      return this.properties.appBarShadow?.value == false ? false : true;
    },
    mainDrawerBorder() {
      return this.properties.mainDrawerBorder?.value == false ? false : true;
    },
    mainDrawerBarBackground() {
      return this.properties.mainDrawerBarBackground?.value || 'white';
    },
    mainDrawerBackground() {
      return this.properties.mainDrawerBackground?.value || 'white';
    },
    canvasBackground() {
      return this.properties.canvasBackground?.value || 'white';
    },
    denseAppBar() {
      return this.properties.denseAppBar?.value == true;
    },
    properties() {
      return this.getData(rootDataId)?.properties || {};
    },
    appBarHeight() {
      return (this.denseAppBar ? 60 : 80) + (this.appBarShadow ? 1 : 0) + 'px';
    },
  },
  render() {
    return null;
  },
});
