

































import { debounce, get } from 'lodash/fp';
import { useData, useVolatileData } from '@/composables';
import { nanoid } from 'nanoid/non-secure';
import InputStyledText from '@/components/input/styled-text/InputStyledText.vue';
import {
  defineComponent,
  computed,
  onBeforeUnmount,
  watch,
  ref,
} from '@vue/composition-api';
import InputTextarea from '@/components/input/string/InputTextarea.vue';

export default defineComponent({
  name: 'ElementInlineEditing',
  components: {
    InputStyledText,
    InputTextarea,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modules: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, { emit }) {
    const { getData, updatePath } = useData();
    const { getVolatileProp, setVolatileProp } = useVolatileData();

    const randomId = nanoid();
    const htmlBuffer = ref('');
    const textBuffer = ref('');
    const lastSavedValue = ref('');

    const options = computed(() => props.modules.element);

    const document = computed(() => {
      if (options.value?.saveAsVolatile) return null;
      if (!options.value?.documentId) return null;
      return getData(options.value.documentId);
    });
    const currentValue = computed(() => {
      if (!options.value?.documentId) return null;
      if (options.value?.saveAsVolatile) {
        return getVolatileProp(
          options.value.documentId,
          options.value.fieldPath,
          '',
        );
      }
      if (!document.value) return null;
      return get(options.value.fieldPath, document.value) || '';
    });

    const updateBuffer = ({ html, text }) => {
      htmlBuffer.value = html;
      textBuffer.value = text;
      if (options.value.autoSave) {
        // debouncedSave();
        save();
      }
    };

    const updateText = (text: string) => {
      textBuffer.value = text;
      if (options.value.autoSave) {
        // debouncedSave();
        save();
      }
    };

    const save = () => {
      const value =
        options.value.targetFormat == 'text'
          ? textBuffer.value
          : htmlBuffer.value;
      lastSavedValue.value = value;
      if (options.value.saveAsVolatile) {
        setVolatileProp(
          options.value.documentId,
          options.value.fieldPath,
          value,
        );
      } else {
        updatePath(
          options.value.documentId,
          options.value.fieldPath,
          value,
          false,
        );
      }
    };

    const onEnter = async ($event) => {
      save();
      emit('event', {
        name: 'enter',
        event: {
          ...$event,
          input:
            options.value.targetFormat == 'text'
              ? textBuffer.value
              : htmlBuffer.value,
        },
      });
    };

    const debouncedSave = debounce(300, () => {
      save();
    });

    onBeforeUnmount(async () => {
      await save();
    });

    watch(currentValue, (newValue, oldValue) => {
      if (newValue == lastSavedValue.value) return;
      htmlBuffer.value = newValue;
      textBuffer.value = newValue;
    });

    return {
      getData,
      updatePath,
      getVolatileProp,
      setVolatileProp,
      randomId,
      htmlBuffer,
      textBuffer,
      lastSavedValue,
      save,
      updateBuffer,
      debouncedSave,
      options,
      document,
      currentValue,
      updateText,
      onEnter,
    };
  },
});
