import { DataDocument, toolsTemplateDirectoryId } from '@fillip/api';

export const TemplateToolScreenshare: DataDocument = {
  id: 'tool-screenshare',
  info: {
    title: 'Tool: Screenshare',
    icon: 'arrow-alt-square-up',
  },
  tag: {
    tag: 'template',
  },
  parentId: toolsTemplateDirectoryId,
  list: {
    items: [
      {
        id: 'screen-share-container',
      },
    ],
  },
  children: {
    default: [
      {
        templateId: 'empty-state-cover',
        query: ':[data]',
        condition: ':!$$activeScreenshares.length',
        identity: '',
      },
      {
        templateId: 'screen-share-container',
        query: ':[data]',
        identity: '',
        condition: ':$$activeScreenshares.length',
      },
    ],
  },
  sheet: {
    orientTowardsCamera: false,
  },
  variables: {
    variables: [
      {
        identifier: 'activeScreenshares',
        query:
          ": pipe(getOnlineParticipants, where(atScene($route), has('streams.screen.producerId')))()",
      },
      {
        identifier: 'hasOwnScreenshare',
        query:
          ': wrapInFunction(activeScreenshares) |> where( is($me.id)) |> length |> gt(0)',
      },
    ],
  },
  actions: {
    actions: [
      {
        name: ": $$hasOwnScreenshare ? 'Teilen beenden' : 'Bildschirm teilen'",
        type: 'action.button',
        icon: 'arrow-alt-square-up',
        color: '',
        script: 'toggleScreenshare()',
        slot: 'bottom',
        focused: 'always',
        roles: ":$$onlyForHosts ? ['host'] : []",
      },
    ],
  },
  class: {
    elementClass: '',
    class: 'h-full w-full grid grid-cols-1 items-stretch justify-stretch',
  },
  arrangement: {
    type: 'arrangement.absolute',
  },
};

export const TemplateToolScreenshareChildren: DataDocument[] = [
  {
    id: 'screen-share-container',
    info: {
      title: 'Tool Screenshare: Container',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'tool-screenshare',
    list: {
      items: [
        {
          id: 'ToolScreenshareScreen',
        },
      ],
    },
    children: {
      default: [
        {
          templateId: 'ToolScreenshareScreen',
          query: ':$$activeScreenshares',
          condition: '',
        },
      ],
    },
    arrangement: {
      type: 'arrangement.divide',
      divideAspectRatio: '16/9',
      divideStretch: true,
    },
    class: {
      class: 'p-6',
      elementClass: '',
    },
  },
  {
    id: 'ToolScreenshareScreen',
    info: {
      title: 'Tool Screenshare: Screen',
    },
    tag: {
      tag: 'template',
    },
    parentId: 'screen-share-container',
    element: {
      type: 'element.screenshare',
      participantId: ':id',
    },
    sheet: {
      orientTowardsCamera: false,
    },
    class: {
      class: '',
      elementClass: 'p-6',
    },
  },
];
