import { ActionControl } from '@fillip/api';
import { computed, unref } from '@vue/composition-api';
import { useFillip } from '../globals/useFillip';
import { useLayoutArea } from '../globals/useLayoutArea';
import { useRouter } from '../globals/useRouter';
import { useI18n } from '../i18n/useI18n';

export function useAuth() {
  const { router } = useRouter();
  const { navigate, close: closeDialog } = useLayoutArea('dialog');
  const { fillip } = useFillip();
  const { tr } = useI18n();

  const $user = computed(() => fillip.state.user);
  const authUser = computed(() => fillip.auth.user);

  const isAnonymous = computed(() => {
    return unref($user).userType === 'anonymous';
  });

  const logout = () => {
    if (isAnonymous.value) {
      navigate({ name: 'UpgradeUser' });
    } else {
      performLogout();
    }
  };

  const performLogout = () => {
    router.push({ name: 'Logout' });
    closeDialog();
  };

  const logoutGlobally = async () => {
    try {
      await fillip.auth.logoutGlobally();
      router.push({ name: 'Login' });
      closeDialog();
    } catch (error) {
      console.error('Error logging out globally: ', error);
    }
  };

  const editAccount = () => {
    navigate({ name: 'UserAccount' });
  };

  const logoutActions = computed(() => {
    const actions: ActionControl[] = [
      {
        type: 'action',
        icon: 'sign-out',
        title: tr('logout.logoutLocally'),
        action: () => logout(),
      },
    ];

    if ($user && $user.value.userType != 'anonymous') {
      actions.push({
        type: 'action',
        icon: 'phone-laptop',
        title: tr('logout.logoutGlobally'),
        action: () => logoutGlobally(),
        color: 'error',
      });
    }

    return actions;
  });

  return {
    logout,
    logoutGlobally,
    isAnonymous,
    $user,
    authUser,
    editAccount,
    logoutActions,
    performLogout,
  };
}
