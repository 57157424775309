import { Box3, MathUtils } from 'three';
import { CameraPanorama, CameraPanoramaMeta } from '@fillip/api';
import { Base3dControls } from './Base3dControls';
import { IBaseControls } from './index';
import CameraControls from 'camera-controls';

const DEFAULT_OPTIONS = CameraPanoramaMeta.default;
export class PanoramaControls extends Base3dControls implements IBaseControls {
  constructor(context, options) {
    super(context, options);
  }

  initialize(options?: CameraPanorama) {
    super.initialize();

    // All settings that can NOT be manipulated in the editors nor the world can be set in initialize

    this.controls.setPosition(0, 0, 1e-5);
    this.controls.minDistance = this.controls.maxDistance = 1;
    this.controls.azimuthRotateSpeed = -0.3; // negative value to invert rotation direction
    this.controls.polarRotateSpeed = -0.3; // negative value to invert rotation direction
    this.controls.truckSpeed = 10;
    this.controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
    this.controls.mouseButtons.shiftLeft = CameraControls.ACTION.TRUCK;
    // this.updateCamera(options);
    this.controls.addEventListener('control', () => this.renderInLoop());
  }

  updateCamera(
    sceneCamera: CameraPanorama,
    sceneBoundingBox: Box3,
    hasParentChanged: boolean,
  ) {
    this.sceneCamera = sceneCamera;

    // All settings that CAN be manipulated in the editors or the world need to be set in updateCamera

    this.controls.minZoom =
      sceneCamera.panoramaZoomMin || DEFAULT_OPTIONS.panoramaZoomMin;
    this.controls.maxZoom =
      sceneCamera.panoramaZoomMax || DEFAULT_OPTIONS.panoramaZoomMax;

    if (
      typeof sceneCamera.azimuthMin == 'number' &&
      isFinite(sceneCamera.azimuthMin)
    ) {
      this.controls.minAzimuthAngle = MathUtils.degToRad(
        sceneCamera.azimuthMin,
      );
    } else {
      this.controls.minAzimuthAngle = -Infinity;
    }

    if (
      typeof sceneCamera.azimuthMax == 'number' &&
      isFinite(sceneCamera.azimuthMax)
    ) {
      this.controls.maxAzimuthAngle = MathUtils.degToRad(
        sceneCamera.azimuthMax,
      );
    } else {
      this.controls.maxAzimuthAngle = Infinity;
    }

    this.controls.minPolarAngle = MathUtils.degToRad(
      sceneCamera.polarMin || DEFAULT_OPTIONS.polarMin,
    );
    this.controls.maxPolarAngle = MathUtils.degToRad(
      sceneCamera.polarMax || DEFAULT_OPTIONS.polarMax,
    );

    const azimuth = MathUtils.degToRad(
      sceneCamera.azimuthDefault || DEFAULT_OPTIONS.azimuthDefault,
    );
    const polar = MathUtils.degToRad(
      sceneCamera.polarDefault || DEFAULT_OPTIONS.polarDefault,
    );

    // TODO: Why doesn't the transition work properly?
    this.controls.rotateTo(azimuth, polar, true);

    this.renderInLoop();
  }
}
