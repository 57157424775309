import { DataDocument, PresetDefinition } from '@fillip/api';

const DefaultCollection: DataDocument = {
  id: 'defaultCollection',
  info: {
    title: 'New Collection',
  },
  tag: {
    tag: 'collection',
  },
};

export const PresetCollection: PresetDefinition = {
  id: 'presetCollection',
  title: 'Collection',
  icon: 'list-alt',
  group: 'data',
  version: '0.1',
  documents: [DefaultCollection],
};
