













































































import {
  defineComponent,
  computed,
  onMounted,
  ref,
} from '@vue/composition-api';
import {
  useCircles,
  useFillip,
  useGlobalProps,
  useRouter,
} from '@/composables';

export default defineComponent({
  name: 'FiCircle',
  setup() {
    const { getMyCircle } = useCircles();
    const { $invoke, $me } = useFillip();
    const { bindGlobalProp } = useGlobalProps();
    const { router } = useRouter();

    const circle = computed(() => getMyCircle());
    // TODO: Fix type
    const circleLocation = computed(
      () =>
        // TODO: Replace with getLocationTitle helper?
        circle.value?.location?.data?.info?.title ||
        circle.value?.location?.info?.title,
    );

    const shouldBroadcastCameraLocation = ref(false);

    // TODO: Fix type
    const isBroadcastingCameraLocation = computed(
      () =>
        circle.value?.amILeader &&
        circle.value?.circleSyncLocation?.cameraLocation,
    );

    const takeLead = async () => {
      // TODO: Handle errors
      const result = await $invoke('takeLead', circle.value.id, $me.value.id);
      return result;
    };

    const stopLead = async () => {
      // TODO: Handle errors
      const result = await $invoke('stopLead', circle.value.id, $me.value.id);
      return result;
    };

    const gather = async () => {
      // TODO: Handle errors
      const result = await $invoke('gather', circle.value.id);
      return result;
    };

    const focus = async (id: string) => {
      return router.value.focus(id);
    };

    onMounted(() => {
      bindGlobalProp('shouldBroadcastCameraLocation', () => ({
        value: shouldBroadcastCameraLocation.value,
      }));
    });

    return {
      circle,
      circleLocation,
      isBroadcastingCameraLocation,
      shouldBroadcastCameraLocation,
      takeLead,
      stopLead,
      gather,
      focus,
    };
  },
});
