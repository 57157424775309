





























import UpgradeForm from './UpgradeForm.vue';
import UsernameForm from './UsernameForm.vue';
import PasswordForm from './PasswordForm.vue';
import EmailForm from './EmailForm.vue';
import DeleteAccount from './DeleteAccount.vue';

import { defineComponent, computed, ref } from '@vue/composition-api';
import { useFillip } from '@/composables';

export default defineComponent({
  name: 'UserAccount',
  components: {
    UsernameForm,
    EmailForm,
    PasswordForm,
    DeleteAccount,
    UpgradeForm,
  },
  setup() {
    const { $user } = useFillip();
    const currentForm = ref('');

    const setCurrentForm = (form: string) => {
      currentForm.value = form;
    };

    const isLocalUser = computed(() => {
      return $user.value?.userType == 'local';
    });

    return {
      currentForm,
      isLocalUser,
      setCurrentForm,
    };
  },
});
