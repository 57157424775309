











































import { BaseElement } from './../core';
import DOMPurify from 'dompurify';
import { sleep } from '@fillip/api';

export default {
  name: 'ElementStyledText',
  mixins: [BaseElement],
  data() {
    return {
      annotationNodes: [],
      selectedAnnotation: null,
    };
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    element() {
      return this.$refs.el;
    },
    content() {
      return DOMPurify.sanitize(this.modules.element.htmlContent || '');
    },
    annotations() {
      return this.annotationNodes.map((annotation: HTMLSpanElement) => {
        return {
          id: annotation.dataset.id,
          comment: annotation.dataset.comment,
          top: annotation.offsetTop,
          left: annotation.offsetLeft,
          // node: annotation,
        };
      });
    },
  },
  watch: {
    content: {
      immediate: true,
      async handler(newValue) {
        await sleep(500);
        this.annotationNodes.forEach((annotation) => {
          annotation.removeEventListener('click', this.showAnnotation);
        });
        this.annotationNodes.splice(0);
        Array.from(
          this.element.getElementsByClassName('annotation') || [],
        ).forEach((annotation: HTMLSpanElement) => {
          annotation.addEventListener('click', this.showAnnotation);
          this.annotationNodes.push(annotation);
        });
      },
    },
  },
  beforeDestroy() {
    this.annotationNodes.forEach((node) => {
      node.removeEventListener('click', this.showAnnotation);
    });
  },
  methods: {
    showAnnotation($event) {
      if (!this.isDesktop) {
        const id = $event.target.dataset.id;
        this.selectedAnnotation = this.findAnnotation(id);
      }
    },
    hideAnnotation() {
      this.selectedAnnotation = null;
    },
    findAnnotation(id) {
      return this.annotations.find((a) => a.id == id);
    },
  },
};
