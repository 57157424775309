import { useFillip } from '../globals';
import type {
  CreateCompletionRequest,
  CreateImageRequest,
  CreateEditRequest,
  CreateEmbeddingRequest,
} from 'openai';
import { AibotRequest, DocSearchRequest, PositionRequest } from '@fillip/api';

export function useAi() {
  const { fillip } = useFillip();

  const createCompletion = async (
    input: string,
    options: Omit<CreateCompletionRequest, 'prompt'>,
  ) => {
    console.log('createCompletion in useAi', input);
    if (!input) {
      throw new Error('Prompt is required');
    }
    const request: CreateCompletionRequest = {
      model: 'text-davinci-003',
      temperature: 0.7,
      max_tokens: 512,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      n: 1,
      // stop: ['\n'],

      prompt: input,
      ...options,
    };

    return fillip.ai.createCompletion(request);
  };

  const createEdit = async (
    input: string,
    instruction: string,
    options: Omit<Omit<CreateEditRequest, 'input'>, 'instruction'>,
  ) => {
    console.log('createEdit in useAi', input);
    if (!input) {
      throw new Error('Prompt is required');
    }
    const request: CreateEditRequest = {
      model: 'text-davinci-edit-001',
      n: 1,
      top_p: 1,

      input,
      instruction,
      ...options,
    };

    return fillip.ai.createEdit(request);
  };

  const createImage = async (
    input: string,
    options: Omit<CreateImageRequest, 'prompt'>,
  ) => {
    console.log('createImage in useAi', input);
    if (!input) {
      throw new Error('Prompt is required');
    }
    const request: CreateImageRequest = {
      n: 1,
      size: '1024x1024',

      prompt: input,
      ...options,
      // Force response_format to be b64_json
      response_format: 'b64_json',
    };

    return fillip.ai.createImage(request);
  };

  const createEmbedding = async (
    input: string,
    options: Omit<CreateEmbeddingRequest, 'input'>,
  ) => {
    console.log('createEmbedding in useAi', prompt);
    if (!prompt) {
      throw new Error('Prompt is required');
    }
    const request: CreateEmbeddingRequest = {
      model: 'text-embedding-ada-002',
      input,
      ...options,
    };

    return fillip.ai.createEmbedding(request);
  };

  const createAiBotAnswer = async (
    question: string,
    options?: Omit<AibotRequest, 'question'>,
  ) => {
    console.log('createAiBotAnswer in useAi', question);
    if (!question) {
      throw new Error('Question is required');
    }
    const request: AibotRequest = {
      question,
      ...options,
    };

    try {
      const result = await fillip.ai.createAiBotAnswer(request);
      console.log('result from createAiBotAnswer', result);
      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const findDocuments = async (
    search_term: string,
    options: Omit<DocSearchRequest, 'search_term'> = { number_of_docs: 8 },
  ) => {
    console.log('findDocuments in useAi', search_term);
    if (!search_term) {
      throw new Error('Search Term is required');
    }
    const request: DocSearchRequest = {
      search_term,
      ...options,
    };

    try {
      return await fillip.ai.findDocuments(request);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getPosition = async (
    search_term: string,
    options: Omit<PositionRequest, 'search_term'> = { number_of_docs: 8 },
  ) => {
    console.log('getPosition in useAi', search_term);
    if (!search_term) {
      throw new Error('Search Term is required');
    }
    const request: PositionRequest = {
      search_term,
      ...options,
    };

    try {
      const result = await fillip.ai.getPosition(request);
      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return {
    createCompletion,
    createEdit,
    createImage,
    createEmbedding,
    createAiBotAnswer,
    findDocuments,
    getPosition,
  };
}
