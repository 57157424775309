import { BaseElement } from './../core';
import DOMPurify from 'dompurify';

export const ElementText = {
  name: 'ElementText',
  mixins: [BaseElement],
  computed: {
    text() {
      const textContent = this.modules.element.textContent;
      return typeof textContent == 'object'
        ? JSON.stringify(textContent)
        : DOMPurify.sanitize(String(textContent));
    },
  },
  render(h) {
    return h(this.modules.element.textFormat || 'div', {
      class: 'element-text', // Needed to remove default vuetify margin on html tags
      ref: 'el',
      domProps: { innerHTML: this.text },
    });
  },
};
