import type { Shell } from './types';

export { Shell } from './types';

import { askdamaris } from './shells/askdamaris.shell';
// import { develop } from './shells/develop.shell';
import { plebs } from './shells/plebs.shell';
import { collabl } from './shells/collabl.shell';
import { local } from './shells/local.shell';

export const shells = {
  local,
  askdamaris,
  // develop,
  plebs,
  collabl,
} as const;

export type ShellName = keyof typeof shells;

type Hosts = Record<string, ShellName>;

const hosts: Hosts = {
  'collab.world': 'collabl',
  'collabl.ai': 'collabl',
  'plebs.schule': 'plebs',
  'askdamaris.collabl.ai': 'askdamaris',
  'askdamaris.collabl.world': 'askdamaris',
  'ask.damariskroeber.com': 'askdamaris',
  // 'develop.collabl.world': 'develop',
  // 'collabl.space': 'develop',
  // 'secondary.collabl.world': 'develop',
  '127.0.0.1:18000': 'local',
  'localhost:18000': 'local',
  'plebs:18000': 'plebs',
  'askdamaris:18000': 'askdamaris',
  '192.168.2.136:18000': 'askdamaris',
};

const getShellNameByHost = (host: string): ShellName => {
  return hosts[host] || 'collabl';
};

export const getShell = (shellName: string): Shell => {
  if (shells[shellName]) return shells[shellName];

  return shells[getShellNameByHost(shellName)] || shells.plebs;
};
