import { useEventBus, UseEventBusReturn } from '@vueuse/core';

export type StateBus = UseEventBusReturn<string, any> & {
  requestUpdate?: (operation: string) => void;
  immediateUpdate?: (operation: string) => void;
};
export interface StateBusUpdate {
  source: string;
  operation: string;
}

export function useStateBus(source: string) {
  const stateBus: StateBus = useEventBus<string>('state');

  stateBus.requestUpdate = (operation: string): void =>
    stateBus.emit('requestUpdate', { source, operation });

  stateBus.immediateUpdate = (operation: string): void =>
    stateBus.emit('immediateUpdate', { source, operation });

  return stateBus;
}
